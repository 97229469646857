import React from 'react'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact, { BugsnagErrorBoundary } from '@bugsnag/plugin-react'
import { ReactFCC } from '@/types/react'

Bugsnag.start({
  apiKey: process.env.NEXT_PUBLIC_BUGSNAG_API_KEY as string,
  plugins: [new BugsnagPluginReact()],
})

export const ErrorBoundary = Bugsnag?.getPlugin?.('react')?.createErrorBoundary(React) as BugsnagErrorBoundary &
  ReactFCC
