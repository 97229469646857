import React from 'react'
import { getHexColor, OneColorIconProps } from '../utils'

export const BellIconNew: React.FC<OneColorIconProps> = ({ size = 24, color = 'white', className }) => {
  const colorHex = getHexColor(color)

  return (
    <svg
      width={size}
      height={size}
      className={className}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22 5C22 6.65685 20.6569 8 19 8C17.3431 8 16 6.65685 16 5C16 3.34315 17.3431 2 19 2C20.6569 2 22 3.34315 22 5Z"
        fill="#F45A3B"
      />
      <path
        d="M14.0406 4.36016C13.8652 4.29964 13.685 4.24625 13.5 4.2V3.5C13.5 3.08333 13.354 2.72933 13.062 2.438C12.7707 2.146 12.4167 2 12 2C11.5833 2 11.2293 2.146 10.938 2.438C10.646 2.72933 10.5 3.08333 10.5 3.5V4.2C9.16667 4.53333 8.08333 5.23733 7.25 6.312C6.41667 7.38733 6 8.61667 6 10V17H5C4.71667 17 4.479 17.096 4.287 17.288C4.09567 17.4793 4 17.7167 4 18C4 18.2833 4.09567 18.5207 4.287 18.712C4.479 18.904 4.71667 19 5 19H19C19.2833 19 19.5207 18.904 19.712 18.712C19.904 18.5207 20 18.2833 20 18C20 17.7167 19.904 17.4793 19.712 17.288C19.5207 17.096 19.2833 17 19 17H18V10C18 9.96652 17.9998 9.93313 17.9993 9.89983C15.7174 9.43628 14 7.4187 14 5C14 4.78322 14.0138 4.56967 14.0406 4.36016Z"
        fill={colorHex}
      />
      <path
        d="M10.588 21.413C10.9793 21.8043 11.45 22 12 22C12.55 22 13.021 21.8043 13.413 21.413C13.8043 21.021 14 20.55 14 20H10C10 20.55 10.196 21.021 10.588 21.413Z"
        fill={colorHex}
      />
    </svg>
  )
}
