import React from 'react'
import { getHexColor, OneColorIconProps } from '../utils'

export const ArrowLeftIcon: React.FC<OneColorIconProps> = ({ size = 24, color = 'white', className, onClick }) => {
  return (
    <svg
      data-testid="arrow-left-icon"
      className={className}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        d="M20.25 12H3.75"
        stroke={getHexColor(color)}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.5 5.25L3.75 12L10.5 18.75"
        stroke={getHexColor(color)}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
