import NextImage, { ImageProps as NextImageProps, ImageLoaderProps } from 'next/image'

type CustomNextImageProps = Omit<NextImageProps, 'alt' | 'key'> & {
  key?: string | number | null | undefined
  alt?: string
}

export function cloudinaryLoader({ src, width, quality }: ImageLoaderProps): string {
  const params = ['c_limit', 'w_' + width, 'q_' + (quality || 'auto'), 'f_webp']
  return `https://images.angelstudios.com/image/upload/${params.join(',')}${src}`
}

const remoteImageLoader = ({ src }: ImageLoaderProps): string => {
  return src
}

export const Image = ({ src, alt = '', ...props }: CustomNextImageProps): JSX.Element | null => {
  if (!src) return null

  const isSrcString = typeof src === 'string'
  if (!isSrcString) {
    return <NextImage src={src} alt={alt} {...props} />
  }
  const isFullyQualified = src.startsWith('http://') || src.startsWith('https://')
  const hasForwardSlash = src.startsWith('/')
  const shouldAddForwardSlash = !isFullyQualified && !hasForwardSlash
  const internalSrc = shouldAddForwardSlash ? `/${src}` : src
  const loader = isFullyQualified ? remoteImageLoader : cloudinaryLoader

  return <NextImage unoptimized={isFullyQualified} src={internalSrc} loader={loader} alt={alt} {...props} />
}
