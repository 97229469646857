import React, { ReactElement } from 'react'
import { When } from 'react-if'
import { CloseIcon } from '@/atoms/Icons/CloseIcon'
import { CaptionMD } from '@/atoms/Text'
import { BaseIconProps } from '@/atoms/utils'
import { Toast } from './ToastProvider'

export type ToastMessage = {
  shouldHideClose?: boolean
  icon?: ReactElement<BaseIconProps>
  button?: ReactElement
  message: string
  onClose?: () => void
  showInMillis?: number
} & Toast

export const ToastMessage = ({ shouldHideClose, icon, message, button, onClose }: ToastMessage) => {
  return (
    <div
      className={'shadow-core-2 flex w-full cursor-pointer rounded-lg bg-core-gray-900 px-6 py-3 duration-100 md:py-4'}
    >
      <div className="flex items-center gap-2 lg:min-w-[300px]">
        {icon}
        <CaptionMD color="white">{message}</CaptionMD>
        <When condition={Boolean(button)}>
          <div>{button}</div>
        </When>
        <When condition={!shouldHideClose}>
          <div className="ml-auto" onClick={onClose}>
            <CloseIcon color="gray-200" size={24} />
          </div>
        </When>
      </div>
    </div>
  )
}
