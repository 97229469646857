import React, { useContext, useMemo } from 'react'
import {
  useUserNotificationsChannels,
  useUserNotificationsListTable,
  NotificationChannel,
} from '@/services/NotificationsService'
import { UserNotification, UserNotificationFilter } from '@/types/codegen-federation'

interface NotificationsContextProps {
  cursor: string | null | undefined
  notifications?: UserNotification[]
  isNotificationsLoading: boolean
  canFetchMore?: boolean | null
  notificationChannels: NotificationChannel[]
  totalNotificationsCount: number
  channelList: string[]
  refetchNotifications?: (refetchInput: UserNotificationFilter) => void
}

interface NotificationsProviderProps {
  children: React.ReactNode
}

const NotificationsContext = React.createContext<NotificationsContextProps>({
  cursor: '1',
  isNotificationsLoading: false,
  canFetchMore: false,
  notificationChannels: [],
  channelList: [],
  totalNotificationsCount: 0,
})

export const NotificationsProvider = ({ children }: NotificationsProviderProps) => {
  const { notificationChannels } = useUserNotificationsChannels()
  const { notifications, nextCursor, loading, canFetchMore, refetchNotifications, ...data } =
    useUserNotificationsListTable({
      cursor: '',
      take: 20,
    })

  const channelList = useMemo(() => {
    const channelArray = ['All']
    const notificationsArray = notificationChannels?.map((channel) => channel?.displayName)
    return notificationChannels ? channelArray.concat(notificationsArray as []) : []
  }, [notificationChannels])

  const updatedNotifications = notifications as UserNotification[]
  const totalNotificationsCount = data?.userNotificationList?.total ?? 0
  const updatedNotificationChannels = notificationChannels as NotificationChannel[]

  const contextValue = useMemo(() => {
    return {
      notifications: updatedNotifications,
      cursor: nextCursor,
      isNotificationsLoading: loading,
      canFetchMore: canFetchMore,
      refetchNotifications: refetchNotifications,
      notificationChannels: updatedNotificationChannels,
      channelList: channelList,
      totalNotificationsCount: totalNotificationsCount,
    }
  }, [
    updatedNotifications,
    nextCursor,
    loading,
    canFetchMore,
    refetchNotifications,
    updatedNotificationChannels,
    channelList,
    totalNotificationsCount,
  ])

  return <NotificationsContext.Provider value={contextValue}>{children}</NotificationsContext.Provider>
}

export const useNotifications = () => {
  const notificationsContext = useContext(NotificationsContext)

  return {
    ...notificationsContext,
  }
}
