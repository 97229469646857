import { Context, GrowthBook } from '@growthbook/growthbook-react'
import { TypedTrackFn } from '@/utils/analytics'
import { AppFeatures } from './app-features'
import { TrackingData } from './types'

export const getGrowthbook = (context: Partial<Context> = {}, trackFn: TypedTrackFn) => {
  const trackingCallback = async (experiment: TrackingData['experiment'], result: TrackingData['result']) => {
    const resultValue = shouldStringifyValue(result.value) ? JSON.stringify(result.value) : result.value
    await trackFn('Experiment Impression', { name: experiment.key, variation: resultValue })
  }

  return new GrowthBook<AppFeatures>({
    apiHost: 'https://web.growthbook-proxy.angelstudios.com',
    clientKey: process.env.NEXT_PUBLIC_GROWTHBOOK_CLIENT_KEY,
    subscribeToChanges: true,
    backgroundSync: true,
    enableDevMode: true,
    trackingCallback,
    ...context,
  })
}

export const shouldStringifyValue = (value: unknown) => {
  return typeof value === 'object' || Array.isArray(value)
}
