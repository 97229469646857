import React, { MouseEvent } from 'react'
import classNames from 'classnames'
import { useRouter } from 'next/router'
import { ExternalLink } from '@/atoms/ExternalLink'
import { useBeforeYouLeave } from '@/contexts/BeforeYouLeaveContext'
import { buildAnalyticsLink } from '@/utils/AnalyticsLinkBuilder'
import { useSafeTrack } from '@/utils/analytics'

export interface InvestLinkProps extends React.PropsWithChildren<React.AnchorHTMLAttributes<HTMLAnchorElement>> {
  href: string
  leavingMessage: string
  leavingContinueLabel: string
  leavingStayLabel: string
  linkContext: string
  projectSlug?: string
}

export function InvestLink({
  className,
  children,
  href,
  onClick,
  leavingMessage,
  leavingContinueLabel,
  leavingStayLabel,
  linkContext,
  projectSlug,
  ...props
}: InvestLinkProps) {
  const router = useRouter()
  const track = useSafeTrack()
  const beforeYouLeave = useBeforeYouLeave()

  const investLink = buildAnalyticsLink({ href, path: router.asPath, linkContext, campaign: projectSlug })

  const analyticsPayload = {
    href,
    linkContext,
    leavingMessage,
    leavingContinueLabel,
    leavingStayLabel,
    projectSlug,
    investLink,
  }

  const handleContinue = () => {
    beforeYouLeave.closeModal()
    track('Invest Link Continue Clicked', analyticsPayload)
    window.open(investLink, '_blank')
  }

  const handleStay = () => {
    beforeYouLeave.closeModal()
    track('Invest Link Stay Clicked', analyticsPayload)
  }

  const handleMenuClick = (event: MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault()

    beforeYouLeave.openModal({
      body: leavingMessage,
      continueLabel: leavingContinueLabel,
      stayLabel: leavingStayLabel,
      onContinue: handleContinue,
      onStay: handleStay,
    })

    onClick?.(event)
    track('Invest Link Disclaimer Viewed', analyticsPayload)
  }

  return (
    <ExternalLink
      {...props}
      href={investLink}
      onClick={handleMenuClick}
      className={classNames('w-full block cursor-pointer', className)}
    >
      {children}
    </ExternalLink>
  )
}
