import React from 'react'
import { AsH3, TitleLG } from '@/atoms/Text'
import { ParagraphLG } from '@/atoms/Typography'
import { useBeforeYouLeave } from '@/contexts/BeforeYouLeaveContext'
import { Submit } from '@/molecules/Action'
import { AngelDialog } from '@/molecules/AngelDialog'

export const BeforeYouLeaveModal = () => {
  const { body, header, showModal, onContinue, onStay, continueLabel, stayLabel } = useBeforeYouLeave()

  return showModal ? (
    <AngelDialog isDarkMode={false} className="w-3/4 text-black" onClose={onStay} open={showModal}>
      {header && (
        <TitleLG as={AsH3} weight="bold">
          {header}
        </TitleLG>
      )}
      <ParagraphLG fontSize="md:text-xl" color="text-black" className="mb-12 text-center md:m-8">
        {body}
      </ParagraphLG>
      <Submit labelSubmit={continueLabel} labelCancel={stayLabel} onCancel={onStay} onSubmit={onContinue} />
    </AngelDialog>
  ) : null
}
