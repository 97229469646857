import React from 'react'
import { getHexColor, TwoColorIconProps } from '../utils'

export const CircleCheckIcon: React.FC<TwoColorIconProps> = ({
  size = 16,
  color1 = 'success',
  color2 = 'white',
  className,
}) => {
  const c1 = getHexColor(color1)
  const c2 = getHexColor(color2)

  return (
    <svg
      data-testid="circle-check-icon"
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <rect width="16" height="16" rx="8" fill={c1} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.6443 5.21955C11.3515 4.92665 10.8766 4.92665 10.5837 5.21955L6.5177 9.28553L5.28033 8.04816C4.98744 7.75527 4.51256 7.75527 4.21967 8.04816C3.92678 8.34105 3.92678 8.81593 4.21967 9.10882L5.98744 10.8766C6.28033 11.1695 6.7552 11.1695 7.0481 10.8766C7.05095 10.8737 7.05377 10.8709 7.05657 10.868L11.6443 6.28021C11.9372 5.98731 11.9372 5.51244 11.6443 5.21955Z"
        fill={c2}
      />
    </svg>
  )
}
