import { reportErrorToBugsnag } from '../bugsnag'

export function getBooleanFromLocalStorage(key: string): boolean | undefined
export function getBooleanFromLocalStorage(key: string, defaultValue: boolean): boolean
export function getBooleanFromLocalStorage(key: string, defaultValue?: boolean): boolean | undefined {
  const value = localStorage.getItem(key)
  if (!value) return defaultValue

  if (/true/i.test(value)) return true
  if (/false/i.test(value)) return false

  reportParseError('boolean', key, value)
  return defaultValue
}

export function getIntegerFromLocalStorage(key: string): number | undefined
export function getIntegerFromLocalStorage(key: string, defaultValue: number): number
export function getIntegerFromLocalStorage(key: string, defaultValue?: number): number | undefined {
  const value = localStorage.getItem(key)
  if (!value) return defaultValue

  const parsed = Number.parseInt(value)
  if (isNaN(parsed)) {
    reportParseError('integer', key, value)
    return defaultValue
  }

  return parsed
}

export function getFloatFromLocalStorage(key: string): number | undefined
export function getFloatFromLocalStorage(key: string, defaultValue: number): number
export function getFloatFromLocalStorage(key: string, defaultValue?: number): number | undefined {
  const value = localStorage.getItem(key)
  if (!value) return defaultValue

  const parsed = Number.parseFloat(value)
  if (isNaN(parsed)) {
    reportParseError('float', key, value)
    return defaultValue
  }

  return parsed
}

export function getObjectFromLocalStorage<T extends object>(key: string): T | undefined
export function getObjectFromLocalStorage<T extends object>(key: string, defaultValue: T): T
export function getObjectFromLocalStorage<T extends object>(key: string, defaultValue?: T): T | undefined {
  const value = localStorage.getItem(key)
  if (!value) return defaultValue

  try {
    const parsed = JSON.parse(value)
    if (parsed && typeof parsed === 'object') return parsed
    else throw new Error()
  } catch (err) {
    reportParseError('object', key, value)
    return defaultValue
  }
}

export function getStringFromLocalStorage(key: string): string | undefined
export function getStringFromLocalStorage(key: string, defaultValue: string): string
export function getStringFromLocalStorage(key: string, defaultValue?: string): string | undefined {
  const value = localStorage.getItem(key)
  return value ? value : defaultValue
}

export function writeToLocalStorage<T extends boolean | number | object | string>(key: string, value: T): void {
  if (value === undefined) {
    reportErrorToBugsnag(`Attempted to write a value of "undefined" to localStorage. Operation failed. Key="${key}"`)
  } else if (value === null) {
    reportErrorToBugsnag(`Attempted to write a value of "null" to localStorage. Operation failed. Key="${key}"`)
  } else {
    const strVal = typeof value === 'object' ? JSON.stringify(value) : value.toString()
    localStorage.setItem(key, strVal)
  }
}

export function removeFromLocalStorage(key: string): void {
  localStorage.removeItem(key)
}

function reportParseError(type: string, key: string, value: string): void {
  const msg = `Failed to retrieve a value of type "${type}" from localStorage. Key="${key}", Value="${value}"`
  reportErrorToBugsnag(msg)
}
