export function scaleDimensions(
  size: number,
  defaultWidth: number,
  defaultHeight: number,
): { width: number; height: number } {
  if (defaultWidth < defaultHeight) {
    return {
      width: size,
      height: (size / defaultWidth) * defaultHeight,
    }
  }
  return {
    width: (size / defaultHeight) * defaultWidth,
    height: size,
  }
}
