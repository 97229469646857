import { useEffect } from 'react'
import { useRouter } from 'next/router'
import NProgress from 'nprogress'

export const useLoadProgressBar = () => {
  const router = useRouter()

  useEffect(() => {
    if (router) {
      router.events.on('routeChangeStart', NProgress.start)
      router.events.on('routeChangeComplete', NProgress.done)
      router.events.on('routeChangeError', NProgress.done)
    }
  }, [router])
}
