import { gql as contentfulGQL } from '@apollo/client'

export const GET_SALES_QUERY = contentfulGQL/* GraphQL */ `
  query getSales($locale: String, $preview: Boolean!) {
    saleCollection(locale: $locale, preview: $preview) {
      items {
        sys {
          id
        }
        name
        abbreviatedPitch
        pitch
        startDate
        endDate
        category
        promotionsCollection {
          items {
            sys {
              id
            }
            name
            bimId
            promoCode
          }
        }
        showSaleBanner
        saleBannerIcon
        saleBannerText
        showSaleBannerCountdown
        saleBannerUrl
      }
    }
  }
`
