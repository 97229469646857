import { NextApiRequest, NextApiResponse } from 'next'
import qs from 'query-string'
import { SessionCache } from '@/auth'
import { getItemOrFirstEntry } from '@/utils/array-utils'
import ClientConfig from '../ClientConfig'
import { getProtocol } from './util'

export const handler =
  (config: ClientConfig, sessionCache: SessionCache) => async (req: NextApiRequest, res: NextApiResponse) => {
    if (req.method === 'GET') {
      const protocol = getProtocol(req)

      let returnURL = `${protocol}://${req.headers.host}/`

      const returnToQuery = getItemOrFirstEntry(req.query.returnTo)

      if (returnToQuery) {
        const parsed = Buffer.from(returnToQuery, 'base64').toString('utf-8')
        returnURL = parsed?.startsWith('http') ? parsed : `${protocol}://${req.headers.host}${parsed}`
      }

      const isAuthenticated = await sessionCache.isAuthenticated(req, res)
      if (!isAuthenticated) {
        const q = { client_id: config.clientId, return_to: returnURL || '' }
        const query = qs.stringify(q)
        res.redirect(`${config.logoutUri}?${query}`)
        return
      }

      await sessionCache.delete(req, res)

      const q = { client_id: config.clientId, return_to: returnURL }
      const query = qs.stringify(q)

      res.redirect(`${config.logoutUri}?${query}`)
    } else {
      res.status(405)
      res.end()
    }
  }

export default handler
