import React, { useMemo } from 'react'
import { ReactFCC } from '@/types/react'

const ThemeContext = React.createContext({ isDarkMode: true })

interface ThemeContextProps {
  isDarkMode: boolean
  children: React.ReactNode
}

export const ThemeContextProvider: ReactFCC<ThemeContextProps> = ({ children, isDarkMode = true }) => {
  const value = useMemo(() => {
    return { isDarkMode }
  }, [isDarkMode])

  return <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>
}

export function useThemeContext() {
  return React.useContext(ThemeContext)
}

export function useIsDarkMode() {
  return useThemeContext().isDarkMode
}
