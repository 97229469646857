import { cdnPath } from '@/constants/cdn'
import { ContentfulCloudinaryImageType } from '@/services/EmailCapturePageService/EmailCapturePageService'

interface ImagePathProps {
  path: string | undefined
  height?: number
  width?: number
  transforms?: string
  format?: string
  color?: string
  quality?: string
}

export type Gravity =
  | 'g_north'
  | 'g_south'
  | 'g_east'
  | 'g_west'
  | 'g_north_west'
  | 'g_north_east'
  | 'g_south_west'
  | 'g_south_east'
  | 'g_center'
  | 'g_auto'

// See transforms documentation here: https://cloudinary.com/console/c-694d3e967aa1c898b5e184ba33ac2a/transformations/cookbook
export function getCloudinaryImageUrl({
  path,
  height,
  width,
  format = 'webp',
  transforms = 'c_scale',
  quality = 'q_auto',
  color,
}: ImagePathProps) {
  // TODO `path` can be undefined! And yet the return type of this function is implicitly `string`!
  // This is causing bugs, because when the input path is undefined, the resulting "url" is invalid.
  // Either change the return type of this function to `string | undefined` and update all usages
  // or change the type for path to `string` and update all usages. I would probably change path to `string`.
  // There are over 100 usages, so either way will take a while, but this needs to be done!

  // Some images from API are still cloudront urls, but we're passing them through this function.
  // Just return string if it appears to be a fully qualified URI
  if (path?.startsWith('http')) return path

  const widthTransform = width ? `w_${width},` : ''
  const heightTransform = height ? `h_${height},` : ''
  const formatTransform = `f_${format},`
  const colorTransform = color ? `e_colorize,co_rgb:${color},` : ''
  const qualityTransform = quality && !transforms.includes('q_auto') ? `${quality},` : ''

  const allTransforms = `${colorTransform}${qualityTransform}${widthTransform}${heightTransform}${formatTransform}${transforms}`
  const hasTrailingComma = allTransforms.length > 0 && allTransforms.slice(-1) === ','
  const finalTransforms = hasTrailingComma ? allTransforms.slice(0, allTransforms.length - 1) : allTransforms

  const imagePathWithTransforms = `/${finalTransforms}/${path}`
  const doubleSlashRemoved = imagePathWithTransforms.replace(/\/{2,}/g, '/')
  return `${cdnPath}${doubleSlashRemoved}`
}

interface GetLogoTransformationArgs {
  logoCloudinaryPath?: string | null
}

export const buildCloudinaryImagePathFromContentfulObject = (
  image: ContentfulCloudinaryImageType | ContentfulCloudinaryImageType[],
  transforms?: string,
) => {
  if (image) {
    const img = Array.isArray(image) ? image[0] : image
    return `${transforms ?? ''}/v${img?.version}/${img?.public_id}.webp`
  } else {
    return ''
  }
}

export function buildCloudinaryImageUrlFromContentfulObject(
  image: ContentfulCloudinaryImageType | ContentfulCloudinaryImageType[],
  transforms?: string,
) {
  return cdnPath + '/' + buildCloudinaryImagePathFromContentfulObject(image, transforms)
}

export const getLogoTransformation = ({ logoCloudinaryPath }: GetLogoTransformationArgs): string => {
  if (!logoCloudinaryPath?.trim()) return ''

  return typeof logoCloudinaryPath?.replace === 'function' ? `l_${logoCloudinaryPath.replace(/\//g, ':')},` : ''
}

export const getOpenGraphFromCloudinaryPath = ({
  path,
  transforms = 'c_pad,b_rgb:262626,w_1200,h_630',
}: {
  path: string
  transforms?: string
}) => {
  return getCloudinaryImageUrl({ path, height: 630, width: 1200, transforms })
}

export const getStoryThumbnailTransform = ({
  path,
  transforms = 'ar_9:16,c_fill,h_320,g_face:center',
}: {
  path: string
  transforms?: string
}) => {
  return getCloudinaryImageUrl({ path, transforms, format: 'webp' })
}

/**
 * Will extract contentful fields from a cloudinary URL. Presupposes
 * a cloudinary URL, without validation. This matches the current usecase:
 * a manually-selected, sure fallback.
 */
export function formatContentfulObjectFromCloudinaryUrl(url: string): ContentfulCloudinaryImageType {
  const CLOUDINARY_URL_REGEX = /^.*\/?v([0-9]+)\/(.*)(\..+)$/
  return {
    version: url.replace(CLOUDINARY_URL_REGEX, '$1'),
    public_id: url.replace(CLOUDINARY_URL_REGEX, '$2'),
  }
}

/**
 * Drops the host, leaving just the cloudinary imgae path
 */
export function formatCloudinaryPath(url?: string): string | undefined {
  const CLOUDINARY_URL_REGEX = /^.*\/?(v[0-9]+\/.*)$/
  return url?.replace(CLOUDINARY_URL_REGEX, '$1')
}
