import React from 'react'
import { MouseEventHandler } from 'react'
import classNames from 'classnames'
import { Case, Default, Switch, When } from 'react-if'
import { AccountCircleIcon } from '@/atoms/Icons/AccountCircleIcon'
import { LegacyImage as Image } from '@/atoms/LegacyImage'
import { Placeholder } from '@/atoms/Placeholder'
import { useUser } from '@/services/UserService'
import { isValidUrl } from '@/services/validations/urls'

export interface AvatarProps {
  className?: string
  onClick?: MouseEventHandler
  size?: number
  hideDefaultImage?: boolean
  loggedInUser?: boolean

  /**
   * This component will fetch the image src via api calls. However, the `src` prop can be used to override the api calls.
   */
  src?: string | null
}

export const Avatar: React.FC<AvatarProps> = ({
  className,
  onClick,
  size = 32,
  src,
  hideDefaultImage = false,
  loggedInUser = true,
}) => {
  const { user, loading } = useUser()
  let imageSrc = ''
  if (src) imageSrc = src
  if (loggedInUser && !src) imageSrc = user?.profile?.image || ''

  return (
    <>
      <Switch>
        <Case condition={loading && !hideDefaultImage}>
          <Placeholder aria-label="profile-loading" className="rounded-full" style={{ width: size, height: size }} />
        </Case>
        <Case condition={!loading && isValidUrl(imageSrc)}>
          <div className="overflow-hidden rounded-full" style={{ width: size, height: size }}>
            <Image
              alt="User Profile Picture"
              aria-label="user-profile-picture"
              objectFit="cover"
              height={size}
              width={size}
              className={classNames({ 'cursor-pointer': Boolean(onClick) }, 'object-cover', className)}
              onClick={onClick}
              src={imageSrc}
              unoptimized
            />
          </div>
        </Case>
        <Default>
          <When condition={hideDefaultImage}>
            <></>
          </When>
          <When condition={!hideDefaultImage}>
            <AccountCircleIcon
              aria-label="generic-profile-picture"
              className={classNames('rounded-full', { 'cursor-pointer': Boolean(onClick) }, className)}
              onClick={onClick}
              size={size}
            />
          </When>
        </Default>
      </Switch>
    </>
  )
}
