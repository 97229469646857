export function isProductionEnvironment(): boolean {
  return process.env.NEXT_PUBLIC_ANGEL_ENV === 'production'
}

export function isStagingEnvironment(): boolean {
  return process.env.NEXT_PUBLIC_ANGEL_ENV === 'staging'
}

export function isDevelopmentEnvironment(): boolean {
  return process.env.NEXT_PUBLIC_ANGEL_ENV === 'development'
}

export function isTestEnvironment(): boolean {
  return Boolean(process.env.TS_JEST)
}
