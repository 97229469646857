import { slugs } from '@/constants/slugs'
import { OPTIONAL_LOCALE_REGEX_PATTERN } from '@/utils/middleware/RegexHelper'
import locales from './locales'

export const theatricalBasePaths = [
  'tickets',
  'pay-it-forward',
  'express-checkout',
  '_theatrical-pif',
  'buyout',
  'movies',
]
export const theatricalProjects = [slugs.cabrini, slugs.sight]

const theatricalPathMatcher = new RegExp(
  `^${OPTIONAL_LOCALE_REGEX_PATTERN}/(${theatricalBasePaths.join('|')})/([\\w-]+)(/[\\w-]+)*`,
)

type isTranslatedTheatricalPath = (path: string, locale: string) => boolean

export const isTranslatedTheatricalPath: isTranslatedTheatricalPath = (path, locale) => {
  const match = path.match(theatricalPathMatcher)

  if (!match) return false
  if (!theatricalProjects.find((project) => project === match[4])) return false

  return locales.theatricalLocales.some((theatricalLocale) => theatricalLocale === locale)
}
