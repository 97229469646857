/* eslint-disable @typescript-eslint/no-explicit-any */

/* eslint-disable no-console */
import { datadogLogs, StatusType } from '@datadog/browser-logs'
import { getCookie } from 'cookies-next'
import { SEGMENT_ANONYMOUS_ID_COOKIE, SEGMENT_USER_ID_COOKIE } from '@/constants/cookies'
import { isDevelopmentEnvironment, isProductionEnvironment, isTestEnvironment } from '../environment-utils'
import { getConsoleLogger, getNoopLogger, isClient, isContextAnError } from './shared'
import { Logger } from './types'

let logger: Logger

/**
 * Short for "client logger".
 * @deprecated use logger() instead.
 */
export function cLogger() {
  if (!logger) {
    logger = initLogger()
  }
  return logger
}

function initLogger(): Logger {
  const fallbackLogger = isProductionEnvironment() ? getNoopLogger() : getConsoleLogger(getDefaultContext)

  if (isTestEnvironment()) {
    return getNoopLogger()
  }

  if (isDevelopmentEnvironment()) {
    return fallbackLogger
  }

  if (!isClient()) {
    fallbackLogger.warn('Tried to initialize the client side logger on the server. Falling back to a console logger.')
    return fallbackLogger
  }

  try {
    return initializeDatadogJsonLogger()
  } catch (err) {
    fallbackLogger.error('Failed to initialized the datadog logger. Falling back to a console logger.', err)
    return fallbackLogger
  }
}

function initializeDatadogJsonLogger(): Logger {
  const CLIENT_TOKEN = process.env.NEXT_PUBLIC_DATADOG_LOGGING_CLIENT_TOKEN
  if (!CLIENT_TOKEN) {
    throw new Error('Missing CLIENT_TOKEN required for datadog logging.')
  }

  // See https://docs.datadoghq.com/logs/log_collection/javascript/
  datadogLogs.init({
    clientToken: CLIENT_TOKEN,
    site: 'datadoghq.com',
    service: 'angel-web',
    env: process.env.NEXT_PUBLIC_ANGEL_ENV,
    version: process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA,
    forwardErrorsToLogs: true,
    sessionSampleRate: 100,
  })

  const logLevel = process.env.NEXT_PUBLIC_LOG_LEVEL
    ? (process.env.NEXT_PUBLIC_LOG_LEVEL as StatusType)
    : StatusType.info

  const datadogLogger = datadogLogs.createLogger('client', {
    level: logLevel,
    handler: 'http',
  })

  const sendLogMessage = (
    logStatus: 'debug' | 'info' | 'warn' | 'error',
    message: string,
    context?: any,
    error?: Error,
  ) => {
    const c = { ...getDefaultContext(), ...context }
    if (error) datadogLogger[logStatus](message, c, error)
    else if (isContextAnError(context)) datadogLogger[logStatus](message, getDefaultContext(), context)
    else datadogLogger[logStatus](message, c)
  }

  return {
    debug(msg, context, error) {
      sendLogMessage('debug', msg, context, error)
    },
    info(msg, context, error) {
      sendLogMessage('info', msg, context, error)
    },
    warn(msg, context, error) {
      sendLogMessage('warn', msg, context, error)
    },
    error(msg, context, error) {
      sendLogMessage('error', msg, context, error)
    },
    type: 'datadog',
  }
}

function getDefaultContext() {
  return {
    anonymousId: getCookie(SEGMENT_ANONYMOUS_ID_COOKIE),
    userId: getCookie(SEGMENT_USER_ID_COOKIE),
  }
}
