import React, { FC } from 'react'
import classNames from 'classnames'
import { CheckIcon } from '@/atoms/Icons/CheckIcon'
import { CaptionMD } from '@/atoms/Text'
import { TextProps } from '@/atoms/Text/Text'
import { getDisplayableName } from '@/molecules/LanguageSelector/index'

interface LanguageOptionProps {
  isDarkMode?: boolean
  language: string
  selected: boolean
}

interface TextColorProps {
  selected: boolean
  isDarkMode: boolean
}

const setTextColor = ({ selected, isDarkMode }: TextColorProps): TextProps['color'] => {
  let textColor: TextProps['color']

  if (isDarkMode) {
    textColor = selected ? 'white' : 'gray-500'
  } else {
    textColor = selected ? 'black' : 'gray-600'
  }
  return textColor
}

export const LanguageOption: FC<LanguageOptionProps> = ({ language, selected, isDarkMode }) => {
  const checkColor = isDarkMode ? 'oxide-bright' : 'oxide'
  const hoverClasses = isDarkMode
    ? 'hover:bg-white/10 active:bg-white/10'
    : 'hover:bg-gray-800/[.06] active:bg-gray-800/[.06]'

  const textColor: TextProps['color'] = setTextColor({ selected, isDarkMode: !!isDarkMode })

  return (
    <div className={classNames('flex items-center gap-2.5 group py-2 w-full rounded', hoverClasses)}>
      {selected ? (
        <CheckIcon color={checkColor} size={20} className="ml-1 group-hover:cursor-pointer" />
      ) : (
        <span className="w-[25px]" />
      )}
      <CaptionMD color={textColor} className={classNames('group-hover:cursor-pointer whitespace-nowrap')}>
        {getDisplayableName(language)}
      </CaptionMD>
    </div>
  )
}
