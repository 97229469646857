interface UnknownObject {
  [key: string]: unknown
}

type KnownKeys<T> = {
  [K in keyof T]: string extends K ? never : number extends K ? never : K
} extends { [_ in keyof T]: infer U }
  ? Record<string, unknown> extends U
    ? never
    : U
  : never
type Override<T1, T2> = Omit<T1, keyof Omit<T2, keyof KnownKeys<T2>>> & T2

export type Address<ExtendedAddress extends Record<string, unknown> = UnknownObject> = Override<
  {
    formatted?: string
    street_address?: string
    locality?: string
    region?: string
    postal_code?: string
    country?: string
  },
  ExtendedAddress
>

export type UserinfoResponse<
  UserInfo extends Record<string, unknown> = UnknownObject,
  ExtendedAddress extends Record<string, unknown> = UnknownObject,
> = Override<
  {
    sub: string
    name?: string
    given_name?: string
    family_name?: string
    middle_name?: string
    nickname?: string | null
    preferred_username?: string
    profile?: string
    picture?: string
    website?: string
    email?: string
    email_verified?: boolean
    gender?: string
    birthdate?: string
    zoneinfo?: string
    locale?: string
    phone_number?: string
    updated_at?: number
    address?: Address<ExtendedAddress>
  },
  UserInfo
>

export interface IdTokenClaims extends UserinfoResponse {
  acr?: string
  amr?: string[]
  at_hash?: string
  aud: string | string[]
  auth_time?: number
  azp?: string
  c_hash?: string
  exp: number
  iat: number
  iss: string
  nonce?: string
  s_hash?: string
  sub: string

  [key: string]: unknown
}

export interface TokenSetParameters {
  /**
   * The raw access token in JWT format
   */
  access_token?: string
  /**
   * Usually "Bearer"
   */
  token_type?: string
  /**
   * The raw id token in JWT format
   */
  id_token?: string
  /**
   * Refresh token, opaque string value
   */
  refresh_token?: string
  /**
   * space-separated scope(s) used for the authentication request
   */
  scope?: string

  /**
   * When the token set was received the expires_at field was calculated based on current timestamp
   * and the expires_in. When recalling a TokenSet instance just the expires_at should be passed in.
   */
  expires_at?: number
  /**
   * State value passed in the authentication request
   */
  session_state?: string

  /** If errors occurred, a user-facing error message will be provided on this property. */
  errors?: string

  [key: string]: unknown
}

/**
 * Creates a new TokenSet from the provided response. E.g. parsed token endpoint response, parsed callback
 * parameters. You only need to instantiate a TokenSet yourself if you recall it from e.g. distributed cache
 * storage or a database. Note: manually constructed TokenSet instances do not undergo any validations.
 */
export class TokenSet implements TokenSetParameters {
  access_token?: string
  token_type?: string
  id_token?: string
  refresh_token?: string
  exp?: number
  expires_in?: number
  expires_at?: number
  session_state?: string
  scope?: string

  constructor(input?: TokenSetParameters) {
    Object.assign(this, input)
  }

  setExpiresIn(value: number) {
    this.expires_at = Date.now() + Number(value)
  }

  /**
   * Given that the instance has expires_at / expires_in this function returns true / false when the
   * access token (which expires properties are for) is beyond its lifetime.
   */
  isExpired() {
    if (this.expires_at) {
      return new Date(this.expires_at).getTime() < Date.now()
    } else {
      return this.expires_in === 0
    }
  }

  /**
   * Given that the instance has an id_token this function returns its parsed payload object.
   * Does not perform any validations as these were done prior to openid-client returning the
   * tokenset in the first place.
   */
  claims() {
    if (!this.id_token) {
      throw new TypeError('id_token not present in TokenSet')
    }
    return JSON.parse(Buffer.from(this.id_token.split('.')[1], 'base64url').toString())
  }

  [key: string]: unknown
}
