import { Organization, PublicationEvent } from '@/layout/Seo/JsonLd/types'
import { paths } from './paths'

export const publisher: Organization = {
  type: 'Organization',
  name: 'Angel Studios',
  url: paths.base,
}

export const fullPublisher: Organization = {
  type: 'Organization',
  founder: [
    { type: 'Person', givenName: 'Neal', familyName: 'Harmon' },
    { type: 'Person', givenName: 'Jeff', familyName: 'Harmon' },
    { type: 'Person', givenName: 'Jordan', familyName: 'Harmon' },
    { type: 'Person', givenName: 'Daniel', familyName: 'Harmon' },
    { type: 'Person', givenName: 'Benton', familyName: 'Crane' },
  ],
  foundingDate: '2021',
  id: 'https://www.angel.com/#organization',
  keywords: [
    'stream',
    'streaming',
    'video',
    'original',
    'originals',
    'studio',
    'series',
    'media',
    'crowdfunding',
    'dry bar',
    'dry bar comedy',
    'tuttle',
    'tuttle twins',
    'wingfeather',
    'wingfeather saga',
  ],
  legalName: 'Angel Studios, Inc.',
  location: {
    type: 'Place',
    address: '295 W Center St Suite A, Provo, UT 84601',
  },
  logo: 'https://images.angelstudios.com/image/upload/v1637340036/angel-studios/logos/Angel-logo-black_4x_2.jpg',
  name: 'Angel Studios',
  sameAs: [
    'https://wikipedia.org/wiki/Angel_Studios',
    'https://www.facebook.com/AngelStudios.inc',
    'https://twitter.com/AngelStudiosInc',
    'https://www.instagram.com/angelstudios_inc/',
    'https://www.youtube.com/channel/UCQIjTyWmLEdsfqMQay0yGMA',
    'https://www.linkedin.com/angel-studios-page',
    'https://www.imdb.com/search/title/?companies=co0145927',
    'https://www.tiktok.com/@angelstudiosinc',
  ],
  slogan: 'Be part of stories that Amplify Light.',
  url: paths.base,
}

export const releasedEvent: PublicationEvent = {
  type: 'PublicationEvent',
  location: { type: 'Country', name: 'US' },
}

export const twitter = 'https://twitter.com/angelstudiosinc'
