import React, { FC, useState, useEffect, useCallback, useTransition, useRef, MutableRefObject } from 'react'
import { Listbox } from '@headlessui/react'
import classNames from 'classnames'
import { motion } from 'framer-motion'
import AutoSizer from 'react-virtualized-auto-sizer'
import { VariableSizeList as List } from 'react-window'
import InfiniteLoader from 'react-window-infinite-loader'
import { Button } from '@/atoms/Button'
import { CheckDoubleIcon } from '@/atoms/Icons/CheckDoubleIcon'
import { GearIcon } from '@/atoms/Icons/GearIcon'
import { LoadingSpinnerIcon } from '@/atoms/Icons/LoadingSpinnerIcon'
import { CaptionSM, TitleXS } from '@/atoms/Text'
import {
  NotificationsBlank,
  NotificationsHeader,
  NotificationsOption,
  NotificationsTabs,
} from '@/molecules/Notifications'
import {
  UserNotification,
  useMarkNotificationsAllAsRead,
  useUserNotificationsCount,
  NotificationChannel,
} from '@/services/NotificationsService'
import { useSafeTrack } from '@/utils/analytics'
import { useTranslate } from '@/utils/translate/translate-client'
import { useNotifications } from './NotificationsProvider'

interface NotificationsTableProps {
  isDarkMode?: boolean
}

const returnBackgroundClasses = (isDarkMode: boolean | undefined) => {
  return isDarkMode ? 'bg-core-gray-900' : 'bg-white'
}

const returnIconColor = (isDarkMode: boolean | undefined) => {
  return isDarkMode ? 'white' : 'gray-800'
}

const returnModalClasses = (isDarkMode: boolean | undefined) => {
  return isDarkMode ? 'bg-core-gray-950 shadow-dark-4' : 'bg-white text-black shadow-light-1'
}

export const NotificationsTable: FC<NotificationsTableProps> = ({ isDarkMode }) => {
  const { t } = useTranslate('account')
  const track = useSafeTrack()
  const { markNotificationsAllAsRead } = useMarkNotificationsAllAsRead()
  const [isPending, startTransition] = useTransition()
  const [windowWidth, setWindowWidth] = useState(0)
  const [notificationsList, setNotificationsList] = useState<{
    notifications: UserNotification[]
    displayCount: number
    canFetchMore: boolean
  }>({
    notifications: [],
    displayCount: 0,
    canFetchMore: false,
  })
  const [notificationTabIndex, setNotificationTabIndex] = useState(0)
  const [activeNotificationChannel, setActiveNotificationChannel] = useState<NotificationChannel | null>(null)
  const [isUnreadOpen, setIsUnreadOpen] = useState(false)
  const [isRefetchLoading, setIsRefetchLoading] = useState(false)
  const {
    notifications,
    isNotificationsLoading,
    canFetchMore,
    refetchNotifications,
    totalNotificationsCount,
    notificationChannels,
    channelList,
  } = useNotifications()
  const { refetchCount } = useUserNotificationsCount()
  const backgroundClasses = returnBackgroundClasses(isDarkMode)
  const closeIconColor = returnIconColor(isDarkMode)
  const modalClasses = returnModalClasses(isDarkMode)
  type HiddenRef = HTMLDivElement | null
  const hiddenRowRef: MutableRefObject<HiddenRef> = useRef(null)
  const rowHeightsRef = useRef<number[]>([])

  useEffect(() => {
    if (isNotificationsLoading) return
    const numberOfNotificationsReturned = notifications?.length || 0
    const totalNotifications = totalNotificationsCount || 0
    const originalNotifications = notifications as UserNotification[]

    setNotificationsList((current) => {
      const displayCount = numberOfNotificationsReturned + current.displayCount
      const canFetchMore = displayCount < totalNotifications
      setIsRefetchLoading(false)
      return {
        notifications: [...current.notifications, ...originalNotifications],
        displayCount,
        canFetchMore,
      }
    })
  }, [isNotificationsLoading, totalNotificationsCount, notifications])

  useEffect(() => {
    if (isRefetchLoading && notifications?.length === 0) {
      setIsRefetchLoading(false)
    }
  }, [notifications, isRefetchLoading])

  const handleUnreadOnChange = useCallback(() => {
    setIsUnreadOpen(false)
  }, [])

  const handleModalClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation()
      setIsUnreadOpen(!isUnreadOpen)
    },
    [isUnreadOpen],
  )

  const handleTabChange = useCallback(
    (tabIndex: number) => {
      const newChannel: NotificationChannel =
        tabIndex > 0 ? notificationChannels[tabIndex - 1] : { id: null, displayName: null }
      const channelId: string | null = newChannel?.id ?? null
      track('Notification Tab Clicked', { channelId })
      startTransition(() => {
        setNotificationsList({
          notifications: [],
          displayCount: 0,
          canFetchMore: false,
        })
        setActiveNotificationChannel(newChannel)
        setNotificationTabIndex(tabIndex)
        setIsRefetchLoading(true)
        refetchNotifications && refetchNotifications({ cursor: '0', where: { channelId } })
      })
    },
    [notificationChannels, setNotificationTabIndex, refetchNotifications, track],
  )

  const handleLoadMoreNotifications = useCallback(() => {
    if (!canFetchMore) return
    const channelId = activeNotificationChannel?.id ?? null
    setIsRefetchLoading(true)
    refetchNotifications && refetchNotifications({ where: { channelId } })
  }, [activeNotificationChannel?.id, canFetchMore, refetchNotifications])

  const handleMarkAllUnread = useCallback(async () => {
    await markNotificationsAllAsRead()
    const updatedArray = JSON.parse(JSON.stringify(notificationsList.notifications))
    updatedArray.forEach((notification: UserNotification) => (notification.read = true))
    refetchCount()
    track('Notification Marked All as Read')
    setNotificationsList({
      notifications: updatedArray,
      displayCount: notificationsList.displayCount,
      canFetchMore: notificationsList.canFetchMore,
    })
  }, [
    markNotificationsAllAsRead,
    notificationsList.canFetchMore,
    notificationsList.displayCount,
    notificationsList.notifications,
    refetchCount,
    track,
  ])

  const handleUpdateNotificationOption = useCallback(
    (id: string, read: boolean) => {
      const status = read ? 'Read' : 'Unread'
      track(`Notification Marked as ${status}`, id)
      const updatedArray = JSON.parse(JSON.stringify(notificationsList.notifications))
      const newIndex = updatedArray.findIndex((notification: UserNotification) => notification.id === id)
      updatedArray[newIndex].read = read

      setNotificationsList({
        notifications: updatedArray,
        displayCount: notificationsList.displayCount,
        canFetchMore: notificationsList.canFetchMore,
      })
    },
    [notificationsList, track],
  )

  const handleDeleteNotificationOption = useCallback(
    (id: string) => {
      track('Notification Deleted', { notificationId: id })
      const updatedArray = JSON.parse(JSON.stringify(notificationsList.notifications))
      const newIndex = updatedArray.findIndex((notification: UserNotification) => notification.id === id)
      updatedArray.splice(newIndex, 1)

      setNotificationsList({
        notifications: updatedArray,
        displayCount: notificationsList.displayCount,
        canFetchMore: notificationsList.canFetchMore,
      })
    },
    [notificationsList, track],
  )

  const isRowLoaded = useCallback(
    (index: number) => {
      return index <= notificationsList.notifications.length - 2 || !canFetchMore
    },
    [canFetchMore, notificationsList.notifications],
  )

  const getItemSize = useCallback(
    (index: number) => {
      const notification = notificationsList.notifications[index]
      const DEFAULT_ROW_HEIGHT = 68
      if (!notification) return DEFAULT_ROW_HEIGHT

      const { message } = notification

      if (hiddenRowRef.current) {
        hiddenRowRef.current['textContent'] = message
        const hiddenRowHeight = window
          .getComputedStyle(hiddenRowRef.current as Element)
          .getPropertyValue('height')
          .slice(0, -2)
        rowHeightsRef.current.push(Number(hiddenRowHeight))
        const isMobile = windowWidth < 640
        if (Number(hiddenRowHeight) > 36) {
          return Number(hiddenRowHeight) + (isMobile ? 70 : 36)
        } else {
          return Number(hiddenRowHeight) + (isMobile ? 56 : 42)
        }
      }
      return DEFAULT_ROW_HEIGHT
    },
    [notificationsList.notifications, windowWidth],
  )

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth)
    }
    window.addEventListener('resize', handleResize)
    if (windowWidth === 0) {
      handleResize()
    }
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [windowWidth])

  const canShowTabs = Boolean(channelList && channelList.length > 2)
  const showSpinner = isNotificationsLoading || isRefetchLoading
  const noEmptyNotifications = Boolean(notifications?.[0])

  return (
    <>
      <div className="mt-4 min-w-[100vw] sm:max-h-[85vh] sm:min-w-[696px]">
        <div className="mx-4 mb-6 mt-2 flex items-center justify-between sm:mx-0 sm:mt-0">
          <NotificationsHeader isDarkMode={isDarkMode} value={t('notifications', 'Notifications')} />
          <div className="hidden flex-row gap-1 sm:flex">
            <Button className="w-full" variant={isDarkMode ? 'white' : 'black'} onClick={handleMarkAllUnread} outline>
              <CaptionSM weight="bold">{t('markAllAsRead', 'Mark all as read')}</CaptionSM>
            </Button>
          </div>
          <div className="flex flex-row gap-1 sm:hidden">
            <Listbox as="div" onChange={handleUnreadOnChange} value={isUnreadOpen} aria-label="Mark as read">
              <Listbox.Button onClick={handleModalClick}>
                <div className="cursor-pointer">
                  <GearIcon size={24} color={closeIconColor} />
                </div>
              </Listbox.Button>
              {isUnreadOpen && (
                <>
                  <div
                    onClick={handleMarkAllUnread}
                    className={classNames(
                      'absolute z-30 top-22 right-6 rounded-lg min-w-[88vw] sm:min-w-[332px] flex flex-row items-center px-4 py-2 cursor-pointer',
                      modalClasses,
                    )}
                  >
                    <CheckDoubleIcon size={24} color={isDarkMode ? 'white' : 'black'} />
                    <TitleXS className="ml-2 capitalize">{t('markAllAsRead', 'Mark all as read')}</TitleXS>
                  </div>
                </>
              )}
            </Listbox>
          </div>
        </div>
        <div
          className={classNames(
            'sm:min-h-[600px] sm:py-6 sm:rounded-lg flex flex-col justify-start overflow-y-auto shadow-light-1',
            backgroundClasses,
          )}
        >
          <div className="hidden min-h-[28px] sm:mb-6 sm:ml-6 sm:flex">
            {canShowTabs && (
              <NotificationsTabs
                isDarkMode={isDarkMode}
                channelList={channelList}
                notificationTabIndex={notificationTabIndex}
                handleTabChange={handleTabChange}
              />
            )}
          </div>
          <div className="h-[79.5vh] sm:h-[50vh]">
            {isPending ? (
              <div className={classNames('h-full w-full', backgroundClasses)} />
            ) : noEmptyNotifications ? (
              <motion.div
                className="h-full w-full"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5 }}
              >
                <AutoSizer style={{ minHeight: '300px' }}>
                  {({ width, height }: { width: number; height: number }) => (
                    <InfiniteLoader
                      itemCount={notificationsList.notifications.length}
                      loadMoreItems={handleLoadMoreNotifications}
                      isItemLoaded={isRowLoaded}
                      threshold={7}
                    >
                      {({ onItemsRendered, ref }) => {
                        return (
                          <List
                            layout="vertical"
                            className={classNames(
                              'h-[79.5vh] sm:h-[50vh] overflow-y-scroll',
                              isDarkMode
                                ? 'bg-core-gray-950 sm:bg-core-gray-900 scrollbar-track-core-gray-900 scrollbar-thin scrollbar-thumb-[#101010]'
                                : 'scrollbar-track-white scrollbar-thin scrollbar-thumb-core-gray-200',
                            )}
                            itemSize={(index) => getItemSize(index)}
                            itemCount={notificationsList.notifications.length}
                            onItemsRendered={onItemsRendered}
                            height={height ?? '300px'}
                            ref={ref}
                            width={width}
                          >
                            {({ index, style }) => {
                              return (
                                notificationsList.notifications[index] && (
                                  <div style={style}>
                                    <NotificationsOption
                                      key={notificationsList.notifications[index].id}
                                      isUnread={!notificationsList.notifications[index].read}
                                      notification={notificationsList.notifications[index]}
                                      handleUpdateNotificationOption={handleUpdateNotificationOption}
                                      handleDeleteNotificationOption={handleDeleteNotificationOption}
                                      isDarkMode={isDarkMode}
                                      selected={true}
                                      table={true}
                                    />
                                  </div>
                                )
                              )
                            }}
                          </List>
                        )
                      }}
                    </InfiniteLoader>
                  )}
                </AutoSizer>
              </motion.div>
            ) : (
              <NotificationsBlank isDarkMode={isDarkMode} />
            )}
          </div>
          <div ref={hiddenRowRef} style={{ color: 'rgba(255, 255, 255, 0)' }}></div>
          <div className="flex h-8 w-full justify-center sm:mt-3 sm:px-4">
            {showSpinner && <LoadingSpinnerIcon className="mx-auto" size={32} color={'gray-600'} />}
          </div>
        </div>
      </div>
    </>
  )
}
