import { createCache } from '@/utils/cache'
import { cLogger } from '@/utils/logging/client-logger'

const LOCATION_API_URL = 'https://wheres-waldo-angel.herokuapp.com/ip/mine'

export type Location = {
  countryCode: string | null | undefined
  city: string | null | undefined
  stateCode: string | null | undefined
  postalCode: string | null | undefined
  latitude: number | null | undefined
  longitude: number | null | undefined
}

export interface UserLocation {
  hasSearchedLocation: boolean
  countryCode: string
  city: string
  coordinates: { latitude: number; longitude: number } | null
}

type FetchOptions = {
  timeout?: number
}
// prevents fetch from hanging
async function fetchWithTimeout(resource: string, options: FetchOptions) {
  const { timeout = 8000 } = options

  const controller = new AbortController()
  const id = setTimeout(() => controller.abort(), timeout)
  const response = await fetch(resource, {
    signal: controller.signal,
  })
  clearTimeout(id)
  return response
}

async function fetchUserLocation(): Promise<UserLocation> {
  try {
    const response = await fetchWithTimeout(LOCATION_API_URL, { timeout: 3000 })
    const data = await response.json()
    const hasCoordinates = data?.latitude != null && data?.longitude != null
    return {
      hasSearchedLocation: true,
      countryCode: overrideOrDefaultRegion(data?.countryCode),
      city: data?.city ?? '',
      coordinates: hasCoordinates ? { latitude: data.latitude as number, longitude: data.longitude as number } : null,
    }
  } catch (e) {
    return {
      hasSearchedLocation: true,
      countryCode: '',
      city: '',
      coordinates: null,
    }
  }
}

const readUserLocationFromCache = createCache<UserLocation, UserLocation>(
  undefined,
  (val?: UserLocation) => val,
  fetchUserLocation,
)

export async function getUserLocation(): Promise<UserLocation | undefined> {
  try {
    const result = await readUserLocationFromCache()
    if (result.ok) {
      return result.value
    } else {
      cLogger().error('Failed to get user location', { error: result.error })
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    if (error?.name === 'AbortError') return
    cLogger().error('Failed to get user location', { error })
  }
}

export const DEFAULT_REGION = 'US'

/**
 * Provides override and fallback for region.
 * @param region - from wherever you're getting region info
 * @returns If NEXT_PUBLIC_MOCK_REGION is set, it's used as an override.
 * If nothing is set, DEFAULT_REGION is always returned
 */
export function overrideOrDefaultRegion(region: string | null | undefined): string | 'US' {
  return (
    (process.env.NEXT_PUBLIC_ANGEL_ENV === 'development' ? process.env.NEXT_PUBLIC_MOCK_REGION ?? region : region) ??
    DEFAULT_REGION
  )
}
