import { AnchorHTMLAttributes, MouseEventHandler } from 'react'
import React from 'react'
import classNames from 'classnames'
import { useRouter } from 'next/router'
import { ParagraphSM } from '@/atoms/Text'
import { useThemeContext } from '@/contexts/ThemeContext'
import { ReactFCC } from '@/types/react'
import { useSafeTrack } from '@/utils/analytics'

const Modal: ReactFCC = ({ children }) => {
  const { isDarkMode } = useThemeContext()
  return (
    <div
      className={classNames(
        'w-screen absolute px-2 pt-4 se:pt-1 lg:pt-8 pb-8 z-50',
        isDarkMode ? 'bg-core-gray-950' : 'bg-white',
      )}
    >
      <ul className="pb-20">
        {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          React.Children.map(React.Children.toArray(children), (child: any) => {
            return <li className="py-1">{child}</li>
          })
        }
      </ul>
    </div>
  )
}

interface MobileNavMenuProps {
  Element: ReactFCC<AnchorHTMLAttributes<HTMLAnchorElement> & { href: string }>
  label: string
  href: string
  className?: string
  linkContext?: string
  onClick?: MouseEventHandler<HTMLAnchorElement>
  id: string
  /**
   * For convenience, isDarkMode will be passed along to all child elements.
   */
  isDarkMode?: boolean
}

const Menu: ReactFCC<MobileNavMenuProps> = ({ children, Element, className, label, href, onClick, id, ...rest }) => {
  const { isDarkMode } = useThemeContext()
  const track = useSafeTrack()

  return (
    <>
      <Element
        className={classNames(
          'whitespace-nowrap block',
          'rounded-md bg-transparent transition-bg duration-200',
          isDarkMode ? 'hover:bg-[rgb(255,255,255,0.1)]' : 'hover:bg-[rgb(0,0,0,0.06)]',
          className,
        )}
        href={href}
        onClick={(e) => {
          onClick?.(e)
          track('Nav Item Clicked', { href, id: e.currentTarget.id, navType: 'mobile' })
        }}
        id={id}
        {...rest}
      >
        <ParagraphSM
          color={isDarkMode ? 'white' : 'black'}
          className="se:photon-paragraph-xs md:photon-paragraph-md px-2 py-[5px] md:py-1.5 se:py-1"
        >
          {label}
        </ParagraphSM>
      </Element>
      <ul>
        {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          React.Children.map(React.Children.toArray(children), (child: any) => {
            return <li className="py-1">{React.cloneElement(child, { className: 'pl-9' })}</li>
          })
        }
      </ul>
    </>
  )
}

export interface MobileNavItemProps {
  Element: ReactFCC<AnchorHTMLAttributes<HTMLAnchorElement> & { href: string }>
  label: string
  href: string
  className?: string
  linkContext?: string
  onClick?: MouseEventHandler<HTMLAnchorElement>
  id: string
}

const Item: React.FC<MobileNavItemProps> = ({ Element, className, label, href, onClick, ...rest }) => {
  const router = useRouter()
  const { isDarkMode } = useThemeContext()
  const isActive = router.asPath.startsWith(href)
  const fontWeight = isActive ? 'semibold' : 'medium'
  const track = useSafeTrack()

  return (
    <>
      <Element
        className={classNames(
          'whitespace-nowrap block',
          'rounded-md px-2 py-[5px] se:py-1 md:py-1.5 bg-transparent transition-bg duration-200',
          isDarkMode ? 'hover:bg-[rgb(255,255,255,0.1)]' : 'hover:bg-[rgb(0,0,0,0.06)]',
          className,
        )}
        href={href}
        onClick={(e) => {
          onClick?.(e)
          track('Nav Item Clicked', { href, id: e.currentTarget.id, navType: 'mobile' })
        }}
        {...rest}
      >
        <ParagraphSM
          color={isDarkMode ? 'white' : 'black'}
          className="se:photon-paragraph-xs md:photon-paragraph-md"
          weight={fontWeight}
        >
          {label}
        </ParagraphSM>
      </Element>
    </>
  )
}

export const MobileNav = { Modal, Menu, Item }
