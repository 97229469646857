import { PropsWithChildren, memo, useEffect } from 'react'
import Bugsnag from '@bugsnag/js'
import { GrowthBook, GrowthBookProvider } from '@growthbook/growthbook-react'
import { AppFeatures } from '../app-features'

interface Props extends PropsWithChildren {
  growthbook: GrowthBook<AppFeatures>
}

const Provider: React.FC<Props> = ({ growthbook, children }) => {
  useEffect(() => {
    growthbook.init().catch(() => {
      Bugsnag.notify('Growthbook Failed to Load Features - Retrying 1 time')
      growthbook.init().catch(() => {
        Bugsnag.notify('Growthbook Failed to Load Features on Retry')
      })
    })
  }, [growthbook])

  return <GrowthBookProvider growthbook={growthbook}>{children}</GrowthBookProvider>
}

export const ExperimentContextProvider = memo(Provider)
