import React, { ReactNode } from 'react'
import { Dialog } from '@headlessui/react'
import classNames from 'classnames'
import { CloseIcon } from '@/atoms/Icons/CloseIcon'
import { TitleSM } from '@/atoms/Text'
import { useThemeContext } from '@/contexts/ThemeContext'
import { ReactFCC } from '@/types/react'

export interface DialogProps {
  isDarkMode?: boolean
  className?: string
  open: boolean
  onClose: () => void
  title?: ReactNode
  bodyClassName?: string
}

export const AngelDialog: ReactFCC<DialogProps> = ({
  isDarkMode,
  bodyClassName,
  className,
  open,
  onClose,
  title,
  children,
}) => {
  // Make the dialog real high on the z-index to get above all elements
  const { isDarkMode: darkModeFromContext } = useThemeContext()
  const isDarkTheme = isDarkMode ?? darkModeFromContext
  return (
    <Dialog open={open} onClose={onClose} className="fixed inset-0 z-[1000] overflow-y-auto">
      <div className={classNames('flex items-center justify-center w-full mx-auto min-h-screen', className)}>
        <Dialog.Overlay className="fixed inset-0 bg-black opacity-60" />
        <div
          className={classNames(
            'relative mx-auto my-6 p-6 top-1/2 max-w-[90%] rounded-xl',
            isDarkTheme ? 'text-gray-100 bg-black' : 'text-gray-950 bg-white',
            bodyClassName,
          )}
        >
          <div className="mb-4 flex flex-row items-center text-xl">
            <TitleSM color={isDarkTheme ? 'white' : 'core-gray-950'} weight="bold" className="mr-8">
              {title}
            </TitleSM>
            <div
              className={classNames(
                'ml-auto cursor-pointer rounded-full p-1',
                isDarkTheme ? 'bg-core-gray-800' : 'bg-core-gray-100',
              )}
              onClick={onClose}
            >
              <CloseIcon color={isDarkTheme ? 'core-gray-500' : 'core-gray-700'} size={18} />
            </div>
          </div>
          {children}
        </div>
      </div>
    </Dialog>
  )
}

export { Dialog } from '@headlessui/react'
