import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Transition } from '@headlessui/react'
import classNames from 'classnames'
import { DateTime } from 'luxon'
import { useRouter } from 'next/router'
import { Case, Switch } from 'react-if'
import { Button } from '@/atoms/Button'
import { CloseIcon } from '@/atoms/Icons/CloseIcon'
import { GiftBowIcon } from '@/atoms/Icons/GiftBowIcon'
import { GiftIcon } from '@/atoms/Icons/GiftIcon'
import { InternalLink } from '@/atoms/InternalLink'
import { TitleXXS } from '@/atoms/Text'
import { useSaleBanner } from '@/services/ContentfulSaleService/hooks'
import { useGuildUser } from '@/services/GuildUserService'
import { useSafeTrack } from '@/utils/analytics'
import { useCountDown } from '@/utils/countdown/useCountdownHook'
import { getObjectFromLocalStorage, SALE_BANNER_DISMISSED, writeToLocalStorage } from '@/utils/local-storage'

const DISABLED_PATH_REGEXES = [/\/guild.*/, /\/tickets.*/]

interface DismissedSales {
  [key: string]: boolean
}

export const SaleBanner: React.FC = () => {
  const router = useRouter()
  const track = useSafeTrack()
  const { isGuildMember } = useGuildUser()
  const { saleWithBanner } = useSaleBanner()
  const [shouldRender, setShouldRender] = useState(false)
  const end = useMemo(() => DateTime.fromJSDate(new Date(saleWithBanner?.endDate || '')), [saleWithBanner])

  useEffect(() => {
    const untilEnd = end.diffNow('milliseconds')
    const timeout = setTimeout(() => setShouldRender(false), untilEnd.milliseconds)
    return () => clearTimeout(timeout)
  }, [end])

  useEffect(() => {
    if (!saleWithBanner) return

    const dismissedSales = getObjectFromLocalStorage<DismissedSales>(SALE_BANNER_DISMISSED)
    if (!dismissedSales) {
      setShouldRender(true)
      return
    }

    const hasBeenDismissed = dismissedSales[saleWithBanner.id]
    setShouldRender(!hasBeenDismissed)
  }, [saleWithBanner, setShouldRender])

  const handleClick = useCallback(() => {
    track('Sale Banner Clicked', { saleWithBanner })
  }, [track, saleWithBanner])

  const handleCloseBanner = useCallback(() => {
    if (!saleWithBanner) return

    const dismissedSales = getObjectFromLocalStorage<DismissedSales>(SALE_BANNER_DISMISSED) || {}
    dismissedSales[saleWithBanner.id] = true
    writeToLocalStorage(SALE_BANNER_DISMISSED, dismissedSales)

    setShouldRender(false)
    track('Sale Banner Dismissed', { saleWithBanner })
  }, [saleWithBanner, track])

  if (!saleWithBanner || !saleWithBanner.saleBannerUrl) return null
  if (end < DateTime.now()) return null
  if (saleWithBanner.category === 'guild' && isGuildMember) return null
  if (DISABLED_PATH_REGEXES.some((r) => r.test(router.asPath))) return null

  const color = 'copper'

  return (
    <Transition
      show={shouldRender}
      appear
      className="absolute top-[52px] z-[999999] w-full md:top-16"
      enter="transition-opacity duration-1000"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="tranition-opacity duration-1000"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
      as="div"
    >
      <div className="relative w-full">
        <div className="absolute h-11 w-full bg-black opacity-70 shadow-sm"></div>
        <div className={'absolute flex h-11 w-full flex-row items-center justify-between '}>
          <div className="hidden w-11 md:block">
            {/* This div is here to keep the space on left and right equal so the middle content is centered on desktop. */}
          </div>
          <div className="grow-1">
            <div className="m-auto w-[90%] md:w-[80%]">
              <InternalLink href={saleWithBanner?.saleBannerUrl} onClick={handleClick}>
                <div className="flex h-full w-full flex-row items-center justify-center gap-1 p-3">
                  <Switch>
                    <Case condition={saleWithBanner?.saleBannerIcon === 'gift'}>
                      <GiftIcon className="shrink-0" size={20} color={color} />
                    </Case>
                    <Case condition={saleWithBanner?.saleBannerIcon === 'giftBow'}>
                      <GiftBowIcon className="shrink-0" size={20} color={color} />
                    </Case>
                  </Switch>
                  <TitleXXS className="line-clamp-2" color={color} weight="semibold">
                    {saleWithBanner.saleBannerText}{' '}
                    <Countdown className="hidden md:inline" endDate={saleWithBanner?.endDate} />
                  </TitleXXS>
                </div>
              </InternalLink>
            </div>
          </div>
          <div className="h-full w-11">
            <Button className="h-full w-full" variant="plain" onClick={handleCloseBanner}>
              <CloseIcon size={20} color={color} />
            </Button>
          </div>
        </div>
      </div>
    </Transition>
  )
}

const Countdown: React.FC<{ className: string; endDate: string | undefined }> = ({ className, endDate }) => {
  const countdown = useCountDown({
    endDate: endDate,
    format: { listStyle: 'narrow', unitDisplay: 'long' },
    numUnits: 5,
  })

  return <span className={classNames('text-nowrap', className)}>{`— ${countdown.formattedCountdown}`}</span>
}
