import { paths } from '@/constants'

export const buildLoginUrl = (returnTo: string): string => {
  const hashed = Buffer.from(returnTo, 'utf-8').toString('base64')
  return `${paths.auth.login}?returnTo=${hashed}`
}

export const buildLogoutUrl = (returnTo?: string): string => {
  if (returnTo) {
    const hashed = Buffer.from(returnTo, 'utf-8').toString('base64')
    return `${paths.auth.logout}?returnTo=${hashed}`
  } else {
    return paths.auth.logout
  }
}
