import { getCookie, setCookie, hasCookie } from 'cookies-next'
import { IncomingMessage, ServerResponse } from 'http'
import { NextApiRequestCookies } from 'next/dist/server/api-utils'
import { v4 } from 'uuid'

const RID_COOKIE = 'X-RID'
const ONE_YEAR_IN_SECONDS = 31536000

type NextServerRequest = IncomingMessage & { cookies: NextApiRequestCookies }

export const getRID = (req?: NextServerRequest, res?: ServerResponse) => {
  if (hasCookie(RID_COOKIE, { req, res })) {
    return getCookie(RID_COOKIE, { req, res })
  } else {
    const rid = v4()
    setCookie(RID_COOKIE, rid, { maxAge: ONE_YEAR_IN_SECONDS, req, res })
    return rid
  }
}
