import React, { useCallback, useEffect, useMemo, useState } from 'react'
import classNames from 'classnames'
import noop from 'lodash/noop'
import { useRouter } from 'next/router'
import { paths } from '@/constants/paths'
import { BeforeYouLeaveProvider } from '@/contexts/BeforeYouLeaveContext'
import { ThemeContextProvider, useThemeContext } from '@/contexts/ThemeContext'
import { BeforeYouLeaveModal } from '@/molecules/BeforeYouLeaveModal'
import { LanguageModal, useLanguages } from '@/molecules/LanguageSelector'
import { RegionModal } from '@/molecules/RegionSelector/RegionModal'
import { useUserNotificationsCount } from '@/services/NotificationsService'
import { useUser } from '@/services/UserService'
import { TheatricalReleaseRegion } from '@/types/codegen-federation'
import { ReactFCC } from '@/types/react'
import { useSafeTrack } from '@/utils/analytics'

const SiteNavContext = React.createContext<SiteNavContextProviderValues>({
  backgroundClassNames: '',
  closeMobileMenu: noop,
  fetchOnlyNew: false,
  languagesEnabled: false,
  gradientBackground: false,
  handleCloseNavMenu: noop,
  handleOpenNavMenu: noop,
  handleOpenLanguageMenu: noop,
  handleOpenInvestMenu: noop,
  handleOpenWatchMenu: noop,
  handleOpenComingSoonMenu: noop,
  handleOpenGuildMenu: noop,
  handleCloseLanguageModal: noop,
  handleOpenLanguageModal: noop,
  handleOpenNotificationMenu: noop,
  handleOpenRegionMenu: noop,
  handleOpenShopMenu: noop,
  handleCloseRegionModal: noop,
  handleOpenRegionModal: noop,
  hideNavigation: false,
  hideMobileNavigation: false,
  isLoggedIn: false,
  isMobileMenuOpen: false,
  isLanguagesMenuOpen: false,
  isInvestMenuOpen: false,
  isWatchMenuOpen: false,
  isComingSoonMenuOpen: false,
  isGuildMenuOpen: false,
  isNotificationsMenuOpen: false,
  isRegionsMenuOpen: false,
  isShopMenuOpen: false,
  notificationsCount: null,
  openedNavMenu: null,
  regions: [],
  showLanguageAndHelpLinks: false,
  showNotifications: false,
  shouldUseDarkMode: true,
  toggleMobileMenu: noop,
  variant: 'absolute',
  variantStickyClassNames: '',
})

type SiteNavMenu = null | 'region' | 'language' | 'notification' | 'invest' | 'shop' | 'watch' | 'guild' | 'coming-soon'

interface SiteNavContextProviderProps {
  gradientBackground: boolean
  hideNavigation: boolean
  hideMobileNavigation: boolean
  regions?: TheatricalReleaseRegion[]
  transparentBackground: boolean
  variant: 'sticky' | 'absolute'
}

interface SiteNavContextProviderValues {
  backgroundClassNames: string
  closeMobileMenu: () => void
  fetchOnlyNew: boolean
  languagesEnabled: boolean
  gradientBackground: boolean
  handleOpenNavMenu: (menu: SiteNavMenu) => void
  handleCloseNavMenu: () => void
  handleCloseLanguageModal: () => void
  handleOpenLanguageModal: () => void
  handleOpenLanguageMenu: () => void
  handleOpenInvestMenu: () => void
  handleOpenWatchMenu: () => void
  handleOpenComingSoonMenu: () => void
  handleOpenGuildMenu: () => void
  handleOpenNotificationMenu: () => void
  handleOpenRegionMenu: () => void
  handleOpenShopMenu: () => void
  handleCloseRegionModal: () => void
  handleOpenRegionModal: () => void
  hideNavigation: boolean
  hideMobileNavigation: boolean
  isLoggedIn: boolean
  isMobileMenuOpen: boolean
  isLanguagesMenuOpen: boolean
  isInvestMenuOpen: boolean
  isWatchMenuOpen: boolean
  isComingSoonMenuOpen: boolean
  isGuildMenuOpen: boolean
  isNotificationsMenuOpen: boolean
  isRegionsMenuOpen: boolean
  isShopMenuOpen: boolean
  notificationsCount: number | null | undefined
  openedNavMenu: SiteNavMenu
  regions?: TheatricalReleaseRegion[]
  showLanguageAndHelpLinks: boolean
  showNotifications: boolean
  shouldUseDarkMode: boolean
  toggleMobileMenu: () => void
  variant: 'sticky' | 'absolute'
  variantStickyClassNames: string
}

export const SiteNavContextProvider: ReactFCC<SiteNavContextProviderProps> = ({
  children,
  gradientBackground,
  hideNavigation,
  hideMobileNavigation,
  regions,
  transparentBackground,
  variant,
}) => {
  const { isDarkMode } = useThemeContext()
  const track = useSafeTrack()
  const { asPath } = useRouter()
  const { languagesEnabled } = useLanguages()
  const { isLoggedIn } = useUser()
  const { notificationsCount, refetchCount } = useUserNotificationsCount()

  const [openedNavMenu, setOpenedNavMenu] = useState<SiteNavMenu>(null)

  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false)
  const [isRegionsModalOpen, setRegionsModalOpen] = useState(false)
  const [isLanguagesModalOpen, setLanguagesModalOpen] = useState(false)
  const showLanguageAndHelpLinks = isLoggedIn

  const handleOpenNavMenu = useCallback(
    (menu: SiteNavMenu) => {
      track('Nav Menu Opened', { menu })
      setOpenedNavMenu(menu)
    },
    [track],
  )

  const handleOpenLanguageMenu = useCallback(() => {
    handleOpenNavMenu('language')
  }, [handleOpenNavMenu])

  const handleOpenRegionMenu = useCallback(() => {
    handleOpenNavMenu('region')
  }, [handleOpenNavMenu])

  const handleOpenInvestMenu = useCallback(() => {
    handleOpenNavMenu('invest')
  }, [handleOpenNavMenu])

  const handleOpenWatchMenu = useCallback(() => {
    handleOpenNavMenu('watch')
  }, [handleOpenNavMenu])

  const handleOpenComingSoonMenu = useCallback(() => {
    handleOpenNavMenu('coming-soon')
  }, [handleOpenNavMenu])

  const handleOpenShopMenu = useCallback(() => {
    handleOpenNavMenu('shop')
  }, [handleOpenNavMenu])

  const handleOpenGuildMenu = useCallback(() => {
    handleOpenNavMenu('guild')
  }, [handleOpenNavMenu])

  const handleOpenNotificationMenu = useCallback(() => {
    handleOpenNavMenu('notification')
  }, [handleOpenNavMenu])

  const handleCloseNavMenu = useCallback(() => {
    setOpenedNavMenu(null)
  }, [])

  const handleOpenLanguageModal = useCallback(() => {
    setLanguagesModalOpen(true)
  }, [setLanguagesModalOpen])

  const handleCloseLanguageModal = useCallback(() => {
    setLanguagesModalOpen(false)
  }, [setLanguagesModalOpen])

  const handleOpenRegionModal = useCallback(() => {
    track('Region Selector Opened')
    setRegionsModalOpen(true)
  }, [setRegionsModalOpen, track])

  const handleCloseRegionModal = useCallback(() => {
    setRegionsModalOpen(false)
  }, [setRegionsModalOpen])

  const toggleMobileMenu = useCallback(() => {
    setMobileMenuOpen((previousState) => !previousState)
  }, [setMobileMenuOpen])

  const closeMobileMenu = useCallback(() => {
    setMobileMenuOpen(false)
  }, [setMobileMenuOpen])

  const backgroundClassNames = determineBackgroundClassNames({
    isDarkMode,
    isGradientBackground: gradientBackground,
    isTransparentBackground: transparentBackground,
  })

  const fetchOnlyNew = useMemo(() => {
    return Boolean(notificationsCount && notificationsCount > 3)
  }, [notificationsCount])

  useEffect(() => {
    refetchCount()
  }, [refetchCount])

  // When mobile menu is open, disable scrolling on the main page.
  // This prevents the page from scrolling behind the modal.
  useEffect(() => {
    const html = window.document.getElementsByTagName('html')[0]
    if (isMobileMenuOpen) {
      html.style.height = '100vh'
      html.style.overflow = 'hidden'
    } else if (variant === 'sticky') {
      html.style.minHeight = '100vh'
      html.style.height = '100%'
    } else {
      html.style.height = 'initial'
      html.style.overflow = 'initial'
    }
  }, [isMobileMenuOpen, variant])

  const shouldUseDarkMode = (isDarkMode || gradientBackground) && (isMobileMenuOpen || !transparentBackground)
  const showNotifications = isLoggedIn && !asPath.includes(paths.notifications.index)
  const variantStickyClassNames = `sticky top-0 z-[9999] h-16 ${shouldUseDarkMode ? 'bg-core-gray-950' : 'bg-white'}`

  const value = useMemo(() => {
    return {
      backgroundClassNames,
      closeMobileMenu,
      fetchOnlyNew,
      languagesEnabled,
      gradientBackground,
      handleOpenNavMenu,
      handleCloseNavMenu,
      handleOpenLanguageMenu,
      handleOpenInvestMenu,
      handleOpenWatchMenu,
      handleOpenComingSoonMenu,
      handleOpenGuildMenu,
      handleCloseLanguageModal,
      handleOpenLanguageModal,
      handleOpenNotificationMenu,
      handleOpenRegionMenu,
      handleOpenShopMenu,
      handleCloseRegionModal,
      handleOpenRegionModal,
      hideNavigation,
      hideMobileNavigation,
      isLanguagesMenuOpen: openedNavMenu === 'language',
      isLanguagesModalOpen,
      isLoggedIn,
      isInvestMenuOpen: openedNavMenu === 'invest',
      isWatchMenuOpen: openedNavMenu === 'watch',
      isComingSoonMenuOpen: openedNavMenu === 'coming-soon',
      isGuildMenuOpen: openedNavMenu === 'guild',
      isMobileMenuOpen,
      isNotificationsMenuOpen: openedNavMenu === 'notification',
      isRegionsModalOpen,
      isRegionsMenuOpen: openedNavMenu === 'region',
      isShopMenuOpen: openedNavMenu === 'shop',
      notificationsCount,
      openedNavMenu,
      regions,
      showLanguageAndHelpLinks,
      showNotifications,
      shouldUseDarkMode,
      toggleMobileMenu,
      variant,
      variantStickyClassNames,
    }
  }, [
    backgroundClassNames,
    closeMobileMenu,
    fetchOnlyNew,
    languagesEnabled,
    gradientBackground,
    handleCloseNavMenu,
    handleOpenLanguageMenu,
    handleOpenInvestMenu,
    handleOpenWatchMenu,
    handleOpenComingSoonMenu,
    handleOpenGuildMenu,
    handleCloseLanguageModal,
    handleOpenLanguageModal,
    handleOpenNavMenu,
    handleOpenNotificationMenu,
    handleOpenRegionMenu,
    handleCloseRegionModal,
    handleOpenRegionModal,
    handleOpenShopMenu,
    hideNavigation,
    hideMobileNavigation,
    isLanguagesModalOpen,
    isLoggedIn,
    isMobileMenuOpen,
    isRegionsModalOpen,
    notificationsCount,
    openedNavMenu,
    regions,
    showLanguageAndHelpLinks,
    showNotifications,
    shouldUseDarkMode,
    toggleMobileMenu,
    variant,
    variantStickyClassNames,
  ])

  return (
    <SiteNavContext.Provider value={value}>
      <ThemeContextProvider isDarkMode={shouldUseDarkMode}>
        {/* This language modal is used in the mobile view only. Desktop uses a different view. */}
        <LanguageModal onClose={handleCloseLanguageModal} show={isLanguagesModalOpen} />
        {regions && regions?.length > 0 && (
          <RegionModal
            isDarkMode={false}
            show={isRegionsModalOpen}
            onClose={handleCloseRegionModal}
            regions={regions}
          />
        )}
        <BeforeYouLeaveProvider>
          <BeforeYouLeaveModal />
          <header
            id="top-nav-header"
            // Note that the z-index used here must be greater than any other z-indexes so that the nav menu is always on top.
            className={classNames(
              backgroundClassNames,
              'absolute left-0 right-0 z-50',
              variant === 'sticky' && variantStickyClassNames,
            )}
          >
            {children}
          </header>
        </BeforeYouLeaveProvider>
      </ThemeContextProvider>
    </SiteNavContext.Provider>
  )
}

const determineBackgroundClassNames = ({
  isGradientBackground,
  isTransparentBackground,
  isDarkMode,
}: {
  isGradientBackground: boolean
  isTransparentBackground: boolean
  isDarkMode: boolean
}) => {
  if (isGradientBackground) {
    return 'text-white bg-black-transparent-t-b'
  } else if (isTransparentBackground) {
    return 'text-black bg-transparent'
  } else if (isDarkMode) {
    return 'text-white'
  } else {
    return 'bg-white text-black border-solid border-b-2 border-gray-200'
  }
}

export const useSiteNavContext = () => {
  return React.useContext(SiteNavContext)
}
