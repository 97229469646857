import { useCallback } from 'react'
import { useOptimizelyContext } from './OptimizelyContext'

export const useLazyDecision = (flagKey: string) => {
  const { optimizely } = useOptimizelyContext()

  const getDecision = useCallback(() => {
    return optimizely?.decide(flagKey)
  }, [flagKey, optimizely])

  return getDecision
}
