import { useCallback, useMemo } from 'react'
import { cLogger } from '@/utils/logging/client-logger'

interface MobileShareProps {
  url: string
  title?: string
  message?: string
}

export const useNativeMobileShare = () => {
  const share = useCallback(async ({ url, title, message }: MobileShareProps) => {
    const data = {
      title,
      text: message,
      url,
    }
    if (navigator?.canShare && navigator.canShare(data)) {
      try {
        await navigator.share(data)
      } catch (error) {
        cLogger().error(`An error occurred sharing ${url}`, error)
      }
    }
  }, [])

  return useMemo(() => {
    return { share }
  }, [share])
}
