import React from 'react'
import Head from 'next/head'
import locales from '@/constants/locales'
import { paths } from '@/constants/paths'
import { getBasePath } from '@/utils/sitemap/base'

interface HrefLangProps {
  friendlyPath?: string
  path: string
}

export const HrefLang: React.FC<HrefLangProps> = ({ friendlyPath = '', path }) => {
  return (
    <Head>
      <link href={`${paths.base}${path}${friendlyPath}`} hrefLang="x-default" rel="alternate" />
      {locales.public.map((locale) => {
        return (
          <link
            href={`${getBasePath(locale)}${path}${locale === locales.defaultLocale ? friendlyPath : ''}`}
            hrefLang={locale}
            key={locale}
            rel="alternate"
          />
        )
      })}
    </Head>
  )
}
