/** shamelessly borrowed from next-seo. They didn't support a TVSeries and I didn't want to wait for a PR from them... **/
import React from 'react'
import Head from 'next/head'
import toJson from './toJson'

export interface JsonLdProps {
  type?: string
  keyOverride?: string
  scriptId?: string
  scriptKey?: string
  [key: string]: unknown
}

function JsonLd({ keyOverride, scriptKey, scriptId, ...rest }: JsonLdProps & { type: string; scriptKey: string }) {
  return (
    <Head>
      <script
        type="application/ld+json"
        id={scriptId}
        dangerouslySetInnerHTML={{ __html: toJson(rest) }}
        key={`jsonld-${scriptKey}${keyOverride ? `-${keyOverride}` : ''}`}
      />
    </Head>
  )
}

export { JsonLd }
