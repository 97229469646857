import { useEffect, useRef } from 'react'
import { useFeatureValue } from '@growthbook/growthbook-react'
import { CircleAlertIcon } from '@/atoms/Icons/CircleAlertIcon'
import { useToast } from '@/molecules/Toast'

export const useOutageAlert = () => {
  const { showToast } = useToast()
  const bannerValue = useFeatureValue('outage_banner', { message: null })
  const alertShown = useRef(false)

  useEffect(() => {
    const message =
      bannerValue &&
      typeof bannerValue === 'object' &&
      'message' in bannerValue &&
      typeof bannerValue?.message === 'string'
        ? bannerValue?.message
        : ''

    if (!message) {
      if (alertShown.current) {
        showToast('Everything is back to normal')
        alertShown.current = false
      }

      return
    }

    alertShown.current = true

    showToast(message, { showInMillis: 10000, icon: <CircleAlertIcon size={32} /> })
  }, [bannerValue, showToast])
}
