import React, { useMemo } from 'react'
import classNames from 'classnames'
import { colors } from '@/constants/colors'
import type { NamedColor } from '@/constants/types'

type FontFace = 'whitney' | 'roboto' | 'dharma-gothic-e' | 'bio-sans'
export type FontWeight = 'normal' | 'medium' | 'semibold' | 'bold'

export type PhotonTextClassNames =
  | 'photon-display'
  | 'photon-heading-xl'
  | 'photon-heading-lg'
  | 'photon-heading-md'
  | 'photon-heading-sm'
  | 'photon-heading-xs'
  | 'photon-title-lg'
  | 'photon-title-md'
  | 'photon-title-sm'
  | 'photon-title-xs'
  | 'photon-title-xxs'
  | 'photon-eyebrow-lg'
  | 'photon-eyebrow-md'
  | 'photon-eyebrow-sm'
  | 'photon-eyebrow-xs'
  | 'photon-paragraph-xl'
  | 'photon-paragraph-lg'
  | 'photon-paragraph-md'
  | 'photon-paragraph-sm'
  | 'photon-caption-lg'
  | 'photon-caption-md'
  | 'photon-caption-sm'
  | 'photon-caption-xs'
  | 'photon-label-lg'
  | 'photon-label-md'
  | 'photon-label-sm'
  | 'photon-label-xs'
  | 'photon-footer'

interface RenderPropProps {
  className: string
  style: React.CSSProperties
}

type RenderProp = (props: RenderPropProps) => JSX.Element

export interface TextProps extends React.HTMLAttributes<unknown> {
  as?: RenderProp
  className?: string
  color?: NamedColor
  face?: FontFace
  weight?: FontWeight
  italic?: boolean
  strikethrough?: boolean
  underline?: boolean
}

export const Text = ({
  face = 'whitney',
  weight = 'medium',
  italic,
  underline,
  strikethrough,
  color,
  className,
  as,
  ...rest
}: TextProps) => {
  /*
  The color prop is set using style because we want the text component to use by
  default whatever text class has been applied to a parent container. We should
  only provide a custom text color if it's provided directly through the props, and 
  using the style instead of a class will ensure that this color always is given priority.
  */
  const style = useMemo(() => {
    return color ? { color: colors[color] } : {}
  }, [color])

  const pprops: RenderPropProps = {
    className: classNames(className, FONT_FACE[face], FONT_WEIGHT[weight], {
      italic: italic,
      underline: underline,
      'line-through': strikethrough,
      'text-inherit': !color,
    }),
    style,
    ...rest,
  }

  return as ? as(pprops) : <span {...rest} {...pprops} />
}

const FONT_FACE = {
  whitney: 'font-whitney',
  roboto: 'font-roboto',
  dharmaGothicE: 'dharma-gothic-e',
  bioSans: 'bio-sans',
} as unknown as Record<FontFace, string>

const FONT_WEIGHT = {
  normal: 'font-normal',
  medium: 'font-medium',
  semibold: 'font-semibold',
  bold: 'font-bold',
} as Record<FontWeight, string>

const TextHoc = (baseClass: PhotonTextClassNames | 'hidden', defaultAs: RenderProp) => {
  return function Typography({ as, className, ...props }: TextProps) {
    return <Text as={as ?? defaultAs} className={classNames(baseClass, className)} {...props}></Text>
  }
}

export const AsH1 = (props: RenderPropProps) => <h1 {...props} />
export const AsH2 = (props: RenderPropProps) => <h2 {...props} />
export const AsH3 = (props: RenderPropProps) => <h3 {...props} />
export const AsH4 = (props: RenderPropProps) => <h4 {...props} />
export const AsH5 = (props: RenderPropProps) => <h5 {...props} />
export const AsH6 = (props: RenderPropProps) => <h6 {...props} />
export const AsLabel = (props: RenderPropProps) => <label {...props} />
export const AsFigCaption = (props: RenderPropProps) => <figcaption {...props} />
export const AsQ = (props: RenderPropProps) => <q {...props} />
export const AsP = (props: RenderPropProps) => <p {...props} />
export const AsSpan = (props: RenderPropProps) => <span {...props} />
export const AsDiv = (props: RenderPropProps) => <div {...props} />

export const Display = TextHoc('photon-display', AsH1)
/**
 * Using a HeadingInvisible in a section that otherwise would not have a Heading
 * aids screen readers and bots to better understand the content. Use the "as" param to
 * pass the proper heading element for the position in the document outline, defaults to h2.
 */
export const HeadingInvisible = TextHoc('hidden', AsH2)
export const HeadingXL = TextHoc('photon-heading-xl', AsH2)
export const HeadingLG = TextHoc('photon-heading-lg', AsH3)
export const HeadingMD = TextHoc('photon-heading-md', AsH4)
export const HeadingSM = TextHoc('photon-heading-sm', AsH5)
export const HeadingXS = TextHoc('photon-heading-xs', AsH6)

export const TitleLG = TextHoc('photon-title-lg', AsH2)
export const TitleMD = TextHoc('photon-title-md', AsH4)
export const TitleSM = TextHoc('photon-title-sm', AsH5)
export const TitleXS = TextHoc('photon-title-xs', AsH6)
export const TitleXXS = TextHoc('photon-title-xxs', AsH6)

export const EyebrowLG = TextHoc('photon-eyebrow-lg', AsLabel)
export const EyebrowMD = TextHoc('photon-eyebrow-md', AsLabel)
export const EyebrowSM = TextHoc('photon-eyebrow-sm', AsLabel)
export const EyebrowXS = TextHoc('photon-eyebrow-xs', AsLabel)

export const ParagraphXL = TextHoc('photon-paragraph-xl', AsP)
export const ParagraphLG = TextHoc('photon-paragraph-lg', AsP)
export const ParagraphMD = TextHoc('photon-paragraph-md', AsP)
export const ParagraphSM = TextHoc('photon-paragraph-sm', AsP)

export const CaptionLG = TextHoc('photon-caption-lg', AsP)
export const CaptionMD = TextHoc('photon-caption-md', AsP)
export const CaptionSM = TextHoc('photon-caption-sm', AsP)
export const CaptionXS = TextHoc('photon-caption-xs', AsP)

export const LabelLG = TextHoc('photon-label-lg', AsP)
export const LabelMD = TextHoc('photon-label-md', AsP)
export const LabelSM = TextHoc('photon-label-sm', AsP)
export const LabelXS = TextHoc('photon-label-xs', AsP)

export const Footer = TextHoc('photon-footer', AsSpan)
