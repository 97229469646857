export function stringify(data: unknown) {
  if (data === undefined) return `undefined`
  const stringifyData = JSON.stringify(data).split("'").join("\\'").split('\\"').join('\\\\"')
  return `JSON.parse('${stringifyData}')`
}

export function serializeJS(strings: TemplateStringsArray, ...keys: unknown[]) {
  const result = [strings[0]]
  keys.forEach((key, i) => {
    result.push(stringify(key), strings[i + 1])
  })
  return result.join('')
}

export default function createScript({
  innerHTML,
  type,
  id,
  async = true,
}: {
  innerHTML: string
  type?: string
  id?: string
  async?: boolean
}) {
  const script = document.createElement('script')
  script.innerHTML = innerHTML
  if (type) script.type = type
  if (id) script.id = id
  if (async) script.async = async
  document.head.appendChild(script)
  return script
}
