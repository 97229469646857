import { useEffect, useRef } from 'react'
import { isDevelopmentEnvironment } from '@/utils/environment-utils'

type TransitionClass = {
  enter: string
  enterFrom: string
  enterTo: string
  leave: string
  leaveFrom: string
  leaveTo: string
}

type Transition = 'from-right' | 'slide' | 'toast' | 'appear'

export function getTransitionClasses(transition?: Transition): TransitionClass {
  switch (transition) {
    case 'from-right':
      return {
        enter: 'transition-all ease-out-bounce duration-700',
        enterFrom: 'translate-x-0 opacity-0',
        enterTo: '-translate-x-[20px] sm:-translate-x-[40px] opacity-100',
        leave: 'transition-all ease-out duration-500',
        leaveFrom: '-translate-x-[20px] sm:-translate-x-[40px] opacity-100',
        leaveTo: 'translate-x-0 opacity-0',
      }
    case 'slide':
      return {
        enter: 'ease-out-bounce duration-700',
        enterFrom: '-translate-y-[300%]',
        enterTo: 'translate-y-0',
        leave: 'ease-out duration-500',
        leaveFrom: 'translate-y-0',
        leaveTo: '-translate-y-[300%]',
      }
    case 'toast':
      return {
        enter: 'ease-out duration-700',
        enterFrom: '-translate-y-[200%]',
        enterTo: 'translate-y-[33%]',
        leave: 'ease-in duration-700',
        leaveFrom: 'translate-y-[33%]',
        leaveTo: '-translate-y-[200%]',
      }
    case 'appear':
    default:
      return {
        enter: 'ease-out duration-300',
        enterFrom: 'opacity-0 scale-95',
        enterTo: 'opacity-100 scale-100',
        leave: 'ease-in duration-200',
        leaveFrom: 'opacity-100 scale-100',
        leaveTo: 'opacity-0 scale-95',
      }
  }
}

/**
 * React 18 added double rendering every component to help locate possible issues when they add additional functionality down the road.
 * This function is to prevent double re-renders in dev when in strict mode.
 * This should be a temporary band-aid to handle unexpected behavior with mounting, unmounting, and remounting
 * @deprecated'
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useLegacyEffect = (cb: () => void, deps: any) => {
  const isMountedRef = useRef(!isDevelopmentEnvironment())

  useEffect(() => {
    if (!isMountedRef.current) {
      isMountedRef.current = true
      return undefined
    }

    return cb()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps)
}

export default useLegacyEffect
