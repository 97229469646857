import dynamic, { DynamicOptions } from 'next/dynamic'
import { BeforeYouLeaveProps } from '@/contexts/BeforeYouLeaveContext/BeforeYouLeaveContext'
import { cLogger } from '@/utils/logging/client-logger'

export { useBeforeYouLeave } from './BeforeYouLeaveContext'

export const BeforeYouLeaveProvider = dynamic(
  import('./BeforeYouLeaveContext')
    .then((mod) => mod.BeforeYouLeaveProvider)
    .catch((err) =>
      cLogger().error('Failed to load the BeforeYouLeaveContext!', err),
    ) as DynamicOptions<BeforeYouLeaveProps>,
  {
    ssr: false,
  },
)
