import React from 'react'
import { getHexColor, TwoColorIconProps } from '@/atoms/utils'

export const QuestionMarkFilled: React.FC<TwoColorIconProps> = ({
  size = 24,
  color1 = 'white',
  color2 = 'gray-950',
  className,
}) => {
  const hex1 = getHexColor(color1)
  const hex2 = getHexColor(color2)

  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
        fill={hex1}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.7072 6.57008C12.004 6.43021 11.2752 6.502 10.6128 6.77637C9.95039 7.05073 9.38424 7.51536 8.98592 8.11149C8.5876 8.70761 8.375 9.40847 8.375 10.1254C8.375 10.6777 8.82272 11.1254 9.375 11.1254C9.92728 11.1254 10.375 10.6777 10.375 10.1254C10.375 9.80404 10.4703 9.48986 10.6489 9.22263C10.8274 8.9554 11.0812 8.74712 11.3781 8.62412C11.6751 8.50113 12.0018 8.46895 12.317 8.53165C12.6322 8.59435 12.9218 8.74912 13.149 8.97638C13.3763 9.20364 13.5311 9.49319 13.5938 9.80841C13.6565 10.1236 13.6243 10.4504 13.5013 10.7473C13.3783 11.0442 13.17 11.298 12.9028 11.4766C12.6356 11.6551 12.3214 11.7504 12 11.7504C11.7348 11.7504 11.4804 11.8558 11.2929 12.0433C11.1054 12.2309 11 12.4852 11 12.7504V13.5004C11 14.0527 11.4477 14.5004 12 14.5004C12.5147 14.5004 12.9386 14.1115 12.9939 13.6115C13.3545 13.5087 13.6988 13.3501 14.0139 13.1395C14.6101 12.7412 15.0747 12.175 15.3491 11.5127C15.6234 10.8503 15.6952 10.1214 15.5553 9.41823C15.4155 8.71505 15.0702 8.06913 14.5633 7.56217C14.0563 7.0552 13.4104 6.70995 12.7072 6.57008ZM13.125 16.875C13.125 17.4963 12.6213 18 12 18C11.3787 18 10.875 17.4963 10.875 16.875C10.875 16.2537 11.3787 15.75 12 15.75C12.6213 15.75 13.125 16.2537 13.125 16.875Z"
        fill={hex2}
      />
    </svg>
  )
}
