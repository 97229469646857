import React, { FC } from 'react'
import classNames from 'classnames'
import { CheckIcon } from '@/atoms/Icons/CheckIcon'
import { LegacyImage as Image } from '@/atoms/LegacyImage'
import { CaptionMD } from '@/atoms/Text'

interface LanguageOptionProps {
  isDarkMode?: boolean
  countryName: string
  countryCode: string
  selected: boolean
  isMobile?: boolean
}

export const RegionOption: FC<LanguageOptionProps> = ({
  countryName,
  countryCode,
  selected,
  isDarkMode,
  isMobile = false,
}) => {
  const checkColor = isDarkMode ? 'white' : 'gray-800'
  const hoverClasses = isDarkMode ? 'hover:bg-white/10 active:bg-core-gray-950' : 'hover:bg-gray-200 active:bg-gray-300'

  return (
    <div
      className={classNames(
        'flex items-center gap-2 group py-3 w-full rounded border border-x-transparent border-t-transparent relative cursor-pointer',
        hoverClasses,
        isDarkMode ? 'border-b-core-gray-950' : 'border-b-core-gray-200',
        isMobile ? 'px-1' : 'px-6',
      )}
    >
      <CheckIcon
        color={checkColor}
        size={16}
        className={classNames(
          'group-hover:cursor-pointer absolute top-50',
          selected ? 'visible' : 'invisible',
          isMobile ? '-left-4' : 'left-1',
        )}
      />
      <div className="relative h-[18px] w-[24px]">
        <Image
          style={{ aspectRatio: '4/3' }}
          alt="country"
          src={`${countryCode}.svg`}
          objectFit="cover"
          width="24"
          layout="fill"
          loader={({ src }) => `https://flagcdn.com/${src}`}
        />
      </div>
      <CaptionMD
        color={isDarkMode ? 'white' : 'gray-900'}
        className={classNames('group-hover:cursor-pointer whitespace-nowrap')}
      >
        {countryName}
      </CaptionMD>
    </div>
  )
}
