/* eslint-disable @typescript-eslint/no-explicit-any */

/* eslint-disable no-console */
import Bugsnag, { NotifiableError } from '@bugsnag/js'
import { isDevelopmentEnvironment, isProductionEnvironment } from '../environment-utils'

export function reportErrorToBugsnag(err: NotifiableError): void {
  if (isProductionEnvironment()) {
    Bugsnag.notify(err)
  } else {
    console.error(err)
    if (isDevelopmentEnvironment()) {
      if (typeof err === 'string') throw new Error(err)
      else if (typeof err === 'object') throw err
    }
  }
}

export function reportWarningToBugsnag(err: NotifiableError, context?: any): void {
  if (isProductionEnvironment()) {
    Bugsnag.notify(`${err} ${JSON.stringify(context)}`)
  } else {
    if (context) console.error(err, context)
    else console.error(err)
  }
}
