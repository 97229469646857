import React from 'react'
import { getCookieConsentProvider } from './utils'

export function CookieConsentScript() {
  const provider = getCookieConsentProvider()

  if (provider === 'osano') return <OsanoScripts />
  if (provider === 'cookie_yes') return <CookieYesScripts />
  if (provider === 'pie_eye') return <PieEyeScripts />

  return null
}

const OsanoScripts: React.FC = () => {
  return (
    <>
      {/* eslint-disable-next-line @next/next/no-sync-scripts */}
      <script
        id="shopify-consent-tracking-api-script"
        src="https://cdn.shopify.com/shopifycloud/consent-tracking-api/v0.1/consent-tracking-api.js"
      />
      <script
        id="shopify-custom-set-tracking-consent-script"
        dangerouslySetInnerHTML={{
          __html: /*js*/ `
            console.log('Consent Initialization: Inline Shopify Script');
            const setTrackingConsent = window.Shopify.customerPrivacy.setTrackingConsent;
            window.Shopify.customerPrivacy.setTrackingConsent = (function(consentOptions, callback) {
              console.log('Consent: Shopify Wrapped Script: Is recycling "Domain" & "AccessToken" values and re-setting consent values with...', { consentOptions });
              return setTrackingConsent({
                ...consentOptions,
                headlessStorefront: true,
                checkoutRootDomain: 'shop.angel.com',
                storefrontRootDomain: window.location.hostname,
                storefrontAccessToken: 'ba113b3c3998ae023db05e42a85a6ac8'
              }, (e) => {
                console.log(e)
              })
            }).bind(window.Shopify.customerPrivacy)
          `,
        }}
      />
      <style dangerouslySetInnerHTML={{ __html: `.osano-cm-widget {display: none;}` }} />
      {/* eslint-disable-next-line @next/next/no-sync-scripts */}
      <script
        id="osano-script"
        src={`https://cmp.osano.com/16A4MgTIwm4CY2fAD/${process.env.NEXT_PUBLIC_OSANO_INSTANCE_KEY}/osano.js`}
      />
      <script
        id="osano-shopify-ready-script"
        dangerouslySetInnerHTML={{
          __html: /* js */ `
            console.log('Consent Initialization: Inline Osano Script');
            try {
                window.Osano.cm.ready('shopify');
                console.log('Shopify Privacy API: initialized');
            } catch (error) {
                console.error('Osano must be loaded before initializing the Shopify API.');
                console.log('Shopify Privacy API: failed to initialize');
            }
          `,
        }}
      />
    </>
  )
}

const CookieYesScripts: React.FC = () => {
  return (
    <>
      <script
        id="cookieyes"
        async
        type="text/javascript"
        src="https://cdn-cookieyes.com/client_data/1740ef65150dc012619d569e/script.js"
      ></script>
      <style dangerouslySetInnerHTML={{ __html: `.cky-btn-revisit-wrapper {display: none;}` }} />
    </>
  )
}

const PieEyeScripts: React.FC = () => {
  return (
    <>
      {/* Cookie Consent Dialog Script */}
      <script
        id="pieyecookiejs"
        async
        src="https://cdn.cookie.pii.ai/1/release/1.5/main.js"
        config-url="https://cdn.cookie.pii.ai/scripts/1/ws/b880dddf-f59e-4428-b076-4f74b3dfd9ce/domain/a0932b31-34c9-450c-89c2-ba248d3cda4a/config-a0932b31-34c9-450c-89c2-ba248d3cda4a.json"
      ></script>

      {/* Do Not Sell Script */}
      <link rel="stylesheet" href="https://pieeyegpc.pii.ai/1/dsrp/d58d895f-5029-407d-b576-503f3b3aa7e9/gpc.css" />
      <script
        id="pieyedonotsell"
        async
        src="https://pieeyegpc.pii.ai/1/dsrp/d58d895f-5029-407d-b576-503f3b3aa7e9/gpc.min.js"
      ></script>
    </>
  )
}
