/// <reference types="@welldone-software/why-did-you-render" />
import React from 'react'
import { isDevelopmentEnvironment } from '@/utils/environment-utils'

if (isDevelopmentEnvironment()) {
  if (typeof window !== 'undefined') {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const whyDidYouRender = require('@welldone-software/why-did-you-render')
    whyDidYouRender(React, {
      trackAllPureComponents: false,
    })
  }
}
