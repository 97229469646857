export function buildAnalyticsLink({
  href,
  path,
  campaign,
  linkContext,
  source,
}: {
  href: string
  path: string
  campaign?: string
  source?: string
  linkContext: string
}) {
  const utmCampaign = `&utm_campaign=${campaign ?? 'angel-web-generic'}`
  const pathContext = path === '/' ? 'home' : path.slice(1, path.length).split('/').join('-')
  const utmSource = `utm_source=${source ?? 'angel_studios'}`
  return `${href}?${utmSource}&utm_medium=web&utm_content=${pathContext}-${linkContext}${utmCampaign}`
}
