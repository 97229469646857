import React, { HTMLAttributes } from 'react'
import classNames from 'classnames'

interface PlaceholderProps extends HTMLAttributes<HTMLElement> {
  placeholderClassName?: string
}

export const Placeholder: React.FC<PlaceholderProps> = ({
  className = 'h-4 w-full',
  placeholderClassName,
  ...props
}) => {
  return (
    <div className={classNames('overflow-hidden', className)} {...props}>
      <div className={classNames('animate-pulse bg-black w-full h-full', placeholderClassName)}>{props.children}</div>
    </div>
  )
}
