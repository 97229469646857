import React, { Fragment, HTMLAttributes } from 'react'
import { Tab as HeadlessTab } from '@headlessui/react'
import { If, Then } from 'react-if'

interface Props extends HTMLAttributes<unknown> {
  names: readonly string[]
  selectedClassName: string
  selectedIndicator?: React.ReactNode
  unSelectedClassName: string
}

export const TabList: React.FC<Props> = (props) => {
  const { names, selectedIndicator, selectedClassName, unSelectedClassName, ...rest } = props
  return (
    <HeadlessTab.List {...rest}>
      {names.map((name) => (
        <HeadlessTab key={name} as={Fragment}>
          {({ selected }: { selected: boolean }) => {
            return (
              <button
                data-testid={`${name}-tab`}
                role="tab"
                name={name}
                className={selected ? selectedClassName : unSelectedClassName}
              >
                {name}
                <If condition={name === 'My Marketplace'}>
                  <Then>
                    <span className="ml-1.5 rounded bg-black px-1 py-0.5 text-xs text-white"> beta </span>
                  </Then>
                </If>
                <If condition={selected && !!selectedIndicator}>
                  <Then>{selectedIndicator}</Then>
                </If>
              </button>
            )
          }}
        </HeadlessTab>
      ))}
    </HeadlessTab.List>
  )
}
