import { ReactElement, ReactNode } from 'react'
import { NextPage } from 'next'

export type NextPageWithLayout<T = Record<string, unknown>> = NextPage<T> & {
  getLayout?: (page: ReactElement, pageProps?: Record<string, unknown>) => ReactNode
}

export { AccountLayout } from './AccountLayout'
export { BlogLayout } from './BlogLayout'
export { DefaultLayout } from './DefaultLayout'
export { HideTopNavigationLayout } from './HideTopNavigationLayout'
export { NotificationsLayout } from './NotificationsLayout'
export { PIFExplainerLayout } from './PifExplainerLayout'
