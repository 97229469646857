import React from 'react'
import { useRouter } from 'next/router'
import { ExternalLink, ExternalLinkProps } from '@/atoms/ExternalLink'
import { ReactFCC } from '@/types/react'
import { buildAnalyticsLink } from '@/utils/AnalyticsLinkBuilder'

export interface AccelerationFundLinkProps extends ExternalLinkProps {
  linkContext?: string
  projectSlug?: string
}

export const AccelerationFundLink: ReactFCC<AccelerationFundLinkProps> = ({
  children,
  href,
  linkContext = 'angel-web',
  projectSlug,
  ...rest
}) => {
  const { asPath } = useRouter()

  return (
    <ExternalLink href={buildAnalyticsLink({ href, path: asPath, campaign: projectSlug, linkContext })} {...rest}>
      {children}
    </ExternalLink>
  )
}
