import React from 'react'
import { getHexColor, OneColorIconProps } from '@/atoms/utils'

export const CircleAlertIcon: React.FC<OneColorIconProps> = ({ color = 'white', size = 10, className }) => {
  const c = getHexColor(color)

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M6 1.5C3.51 1.5 1.5 3.51 1.5 6C1.5 8.49 3.51 10.5 6 10.5C8.49 10.5 10.5 8.49 10.5 6C10.5 3.51 8.49 1.5 6 1.5ZM6.75 9.08H5.25V7.58H6.75V9.08ZM6.46 6.41L5.56 6.6L5.06 2.92H6.93L6.46 6.41Z"
        fill={c}
      />
    </svg>
  )
}
