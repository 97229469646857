import { display } from '@/constants/locales'

export { LanguageModal } from './LanguageModal'
export { LanguageMenu } from './LanguageMenu'

export { LanguagesProvider, useLanguages } from './LanguagesProvider'

export const getDisplayableName = (language: string) => {
  return (display as { [key: string]: string })[language]
}
