import React, { ReactElement } from 'react'
import { Trans as NextTrans, TFunction as NextTFunction } from 'next-i18next'
// Note, renaming Trans to NextTrans intentionally makes it so the i18n-parser won't parse this file.
// By design, the i18n-parser will throw a warning, which breaks our build, when a variable is provided to a prop.
// In this case, we really do want to pass in variables to props, because we've created the Translate wrapper component.
import { TransChild } from 'react-i18next/TransWithoutContext'
import { reportErrorToBugsnag } from '../bugsnag'
import { TranslationNamespace } from './translate-shared'

export { useTranslateInternal as useTranslate } from './translate-shared'

export type TranslateFunction = NextTFunction

export type TranslateProps = {
  i18nKey: string
  /**
   * @deprecated
   * Use the t function instead. Our custom t function includes validation, which makes it the preferred option.
   */
  ns?: TranslationNamespace
  t?: TranslateFunction
  values?: Record<string, string | number | null | undefined>
  children?: TransChild | readonly TransChild[]
  count?: number
  components?: Record<string, ReactElement>
  defaults?: string
}

export const Translate: React.FC<TranslateProps> = ({ ns, t, ...rest }) => {
  if (!ns && !t) {
    reportErrorToBugsnag('Either the "ns" or "t" prop must be provided, but neither was. The "t" prop is preferred.')
    // Allow the underlying component to handle this error case as gracefully as possible.
    return <NextTrans {...rest} />
  }

  if (ns && t) {
    reportErrorToBugsnag('Either the "ns" or "t" prop must be provided, but both were. The "t" prop is preferred.')
    return <NextTrans t={t} {...rest} />
  }

  if (t) return <NextTrans t={t} {...rest} />

  if (ns) return <NextTrans ns={ns} {...rest} />

  throw Error('It should not be possible to reach this point...')
}
