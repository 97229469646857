import { Duration } from 'luxon'
import { projectTypes } from '@/constants/projectTypes'
import { Episode } from '@/services/ProjectsService'
import { ProjectType, VideoMetaData } from '@/services/ProjectsService'
import { Gravity } from '@/utils/Cloudinary'
import { buildLinkToWatchable } from '@/utils/EpisodeUtil'
import { isAngelBehindTheScenes } from '@/utils/SpecialProjectUtils'
import { getBasePath } from '@/utils/sitemap/base'
import { useTranslate } from '@/utils/translate/translate-client'
import { NextSeoPropsWithRequiredFields } from '..'

interface SeoProjectMeta {
  logoCloudinaryPath: string
  name: string
  projectType: ProjectType
  slug: string
}

export function useWatchTitle(title: string) {
  const { t } = useTranslate('common')

  return t('watchShowOnAngel', 'Watch {{title}} on Angel Studios', { title })
}

export function useDataForVideo(
  locale: string,
  logoCloudinaryPath: string,
  projectName: string,
  projectSlug: string,
  video: VideoMetaData,
) {
  const basePath = getBasePath(locale)
  const url = `${basePath}/watch/${projectSlug}/videos/${video.page}/${video.guid}`
  const title = useWatchTitle(`${projectName}: ${video.title}`)

  return getData({
    url,
    cloudinaryImagePath: video.posterLandscapeCloudinaryPath,
    cloudinaryTransformation: buildBrandedCloudinaryImageTransforms({ logoCloudinaryPath: logoCloudinaryPath }),
    description: video.subtitle,
    title,
    openGraph: {
      title,
      url,
    },
    type: 'other',
    contentUrl: video.source.url,
    duration: video.source.duration,
  })
}

interface EpisodeTitleArgs {
  project: SeoProjectMeta
  episode: Episode
}

function getEpisodeOpenGraphTitle({ project, episode }: EpisodeTitleArgs) {
  if (!isAngelBehindTheScenes(project.slug) && project.projectType === 'series') {
    return `${project.name} Season ${episode.seasonNumber}, Episode ${episode.episodeNumber}: ${episode.subtitle}`
  } else {
    return `${episode.subtitle}`
  }
}

export function getEpisodeTitle({ project, episode }: EpisodeTitleArgs) {
  if (project.projectType === projectTypes.movie) {
    return `${episode.subtitle}`
  } else if (isAngelBehindTheScenes(project.slug)) {
    return `${episode.subtitle}`
  } else {
    return `${project.name} Season ${episode.seasonNumber} Episode ${episode.episodeNumber}: ${episode.subtitle}`
  }
}

function getVideoType(projectType: ProjectType | 'other') {
  switch (projectType) {
    case projectTypes.movie:
      return 'movie'
    case projectTypes.series:
      return 'episode'
    default:
      return 'other'
  }
}

export function useDataForEpisode(project: SeoProjectMeta, episode: Episode, locale: string) {
  const episodePath = buildLinkToWatchable(project.projectType, episode, locale)
  const url = `${getBasePath(locale)}${episodePath}`
  const title = useWatchTitle(getEpisodeTitle({ project, episode }))
  const openGraphTitle = useWatchTitle(getEpisodeOpenGraphTitle({ project, episode }))

  return getData({
    url,
    cloudinaryImagePath: episode?.posterLandscapeCloudinaryPath ?? episode.posterCloudinaryPath,
    cloudinaryTransformation: buildBrandedCloudinaryImageTransforms({ logoCloudinaryPath: project.logoCloudinaryPath }),
    description: episode.description,
    title,
    openGraph: {
      title: openGraphTitle,
      url,
    },
    type: project.projectType,
    releaseDate: episode.releaseDate,
    contentUrl: episode.source?.url,
    duration: episode.source?.duration,
  })
}

function getData({
  cloudinaryImagePath,
  cloudinaryTransformation,
  url,
  description,
  title,
  openGraph,
  type,
  duration,
  releaseDate,
  contentUrl,
}: DataParts) {
  return {
    meta: getMediaMetaData({
      cloudinaryImagePath,
      cloudinaryTransformation,
      url,
      description,
      title,
      openGraph,
      type: getVideoType(type),
      duration,
      releaseDate,
    }),
    jsonLd: getJsonLd({
      name: title,
      description,
      cloudinaryImagePath,
      uploadDate: releaseDate || '2021-03-25T12:00:00Z',
      embedUrl: url,
      contentUrl,
      duration,
    }),
  }
}

export function getMediaMetaData({
  type = 'other',
  cloudinaryImagePath,
  cloudinaryTransformation = 'b_rgb:000000,c_fill,g_north',
  url,
  description,
  title,
  openGraph = {},
  duration,
  releaseDate,
}: MediaMetaDataParts): NextSeoPropsWithRequiredFields {
  return {
    title,
    description,
    canonical: url,
    openGraph: {
      url,
      title,
      description,
      images: [
        {
          url: `https://images.angelstudios.com/image/upload/${cloudinaryTransformation},h_630,w_1200/${cloudinaryImagePath}.webp`,
          height: 630,
          width: 1200,
          alt: description,
        },
      ],
      site_name: 'Angel Studios',
      type: `video.${type}`,
      video: {
        releaseDate,
        duration,
      },
      ...openGraph,
    },
    additionalMetaTags: [
      {
        property: 'twitter:image',
        content: `https://images.angelstudios.com/image/upload/${cloudinaryTransformation},h_600,w_1200/${cloudinaryImagePath}.webp`,
      },
    ],
  }
}

interface BasicPageMetaDataProps {
  cloudinaryImagePath: string
  cloudinaryTransformation: string
  description: string
  locale?: string
  path: string
  title: string
}

export function buildDarkenedOverlay() {
  const pixelPath = `l_v1652712203/angel-studios/one_pixel,`.replace(/\//g, ':')
  return `${pixelPath}/fl_relative,w_1.0,h_1.0,c_scale,e_colorize,co_black,o_50/fl_layer_apply`
}

export function buildProjectLogoTransform({
  logoCloudinaryPath,
  logoGravity = 'g_north_west',
  logoWidth = 0.35,
  x = 0.05,
  y = 0.05,
}: {
  logoCloudinaryPath: string
  logoGravity?: Gravity
  /* as a decimal percentage of the parent */
  logoWidth?: number
  x?: number
  y?: number
}) {
  const logoTransformPath = `l_${logoCloudinaryPath?.replace(/\//g, ':')}`
  return `${logoTransformPath}/fl_relative,w_${logoWidth},c_scale/fl_layer_apply,${logoGravity},y_${y},x_${x},fl_relative`
}

export function buildBrandedCloudinaryImageTransforms({
  logoCloudinaryPath,
  logoGravity,
  logoWidth,
}: {
  logoCloudinaryPath: string
  logoGravity?: Gravity
  logoWidth?: number
}) {
  const angelLogoTransformPath = 'l_v1663942060/angel-studios/logos/Angel-Studios-Logo-White'.replace(/\//g, ':')
  const angelLogoTransform = `${angelLogoTransformPath}/w_0.25,c_scale,fl_relative/fl_layer_apply,g_south_east,y_0.05,x_0.05,fl_relative`
  const logoTransform = buildProjectLogoTransform({ logoCloudinaryPath, logoGravity, logoWidth })
  const blackOverlayTransform = buildDarkenedOverlay()

  return `b_rgb:000000,h_630,w_1200,c_fill,g_north/${blackOverlayTransform}/${logoTransform}/${angelLogoTransform}/`
}

export function getBasicPageMetaData({
  cloudinaryImagePath,
  cloudinaryTransformation,
  description,
  locale,
  path,
  title,
}: BasicPageMetaDataProps) {
  const url = `${getBasePath(locale)}${path}`
  return {
    additionalMetaTags: [
      {
        content: `https://images.angelstudios.com/image/upload/${cloudinaryTransformation},h_600,w_1200/${cloudinaryImagePath}`,
        property: 'twitter:image',
      },
    ],
    description,
    canonical: url,
    openGraph: {
      description,
      images: [
        {
          alt: description,
          height: 630,
          width: 1200,
          url: `https://images.angelstudios.com/image/upload/${cloudinaryTransformation},h_630,w_1200/${cloudinaryImagePath}`,
        },
      ],
      site_name: 'Angel Studios',
      title,
      url,
    },
    path,
    title,
  }
}

export function getJsonLd({
  name,
  description,
  cloudinaryImagePath,
  uploadDate = null,
  embedUrl,
  contentUrl,
  duration,
}: JsonLdParts) {
  return {
    name,
    description,
    uploadDate,
    embedUrl,
    contentUrl,
    duration: duration ? Duration.fromDurationLike({ hour: 0, minute: 0, second: duration }).normalize().toISO() : null,
    thumbnailUrls: [
      `https://images.angelstudios.com/image/upload/b_rgb:000000,c_fill,h_630,w_1200,g_north/${cloudinaryImagePath}.webp`,
    ],
  }
}

export interface DataParts {
  cloudinaryImagePath: string
  cloudinaryTransformation?: string
  url: string
  description: string
  title: string
  openGraph?: OpenGraphParts
  /* https://ogp.me/#type_video */
  type: ProjectType | 'other'
  /* in seconds */
  duration?: number
  releaseDate?: string
  contentUrl?: string
}

export interface OpenGraphParts {
  title?: string
  url?: string
}

export interface MediaMetaDataParts {
  cloudinaryImagePath: string
  cloudinaryTransformation?: string
  url: string
  description: string
  title: string
  openGraph?: OpenGraphParts
  /* https://ogp.me/#type_video */
  type?: 'other' | 'episode' | 'movie' | 'tv_show'
  /* in seconds */
  duration?: number
  releaseDate?: string
}

export interface JsonLdParts {
  cloudinaryImagePath: string
  embedUrl: string
  description: string
  name: string
  /* in seconds */
  duration?: number | null
  uploadDate?: string | null
  contentUrl?: string
}
