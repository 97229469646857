import React from 'react'
import classNames from 'classnames'
import { useThemeContext } from '@/contexts/ThemeContext'

interface RenderPropProps {
  className: string
}

type RenderProp = (props: RenderPropProps) => JSX.Element

export interface TextProps extends React.PropsWithChildren<Omit<React.AllHTMLAttributes<HTMLElement>, 'as'>> {
  as?: RenderProp
  className?: string
  style?: React.CSSProperties
  color?: string
  fontWeight?: string
  lineHeight?: string
  fontSize?: string
  fontFace?: string
  margin?: string
  padding?: string
  letterSpacing?: string
}

/**
 * @deprecated Use src/atoms/Text/Text.tsx instead.
 */
export function Text({
  as,
  fontWeight,
  lineHeight,
  fontSize,
  fontFace = 'font-whitney',
  margin = 'm-0',
  padding = 'p-0',
  color,
  letterSpacing,
  className,
  ...rest
}: TextProps) {
  const { isDarkMode } = useThemeContext()
  const defaultTextColor = isDarkMode ? 'text-white' : 'text-black'
  const textColor = color || defaultTextColor

  const pprops: RenderPropProps = {
    className: classNames(
      className,
      fontWeight,
      fontSize,
      lineHeight,
      fontFace,
      margin,
      padding,
      letterSpacing,
      textColor,
    ),
    ...rest,
  }

  return as ? as(pprops) : <span {...rest} {...pprops} />
}
