import { NextApiHandler } from 'next'
import { HandleLogin } from '@/auth/handlers/login'
import { HandleSignup } from '@/auth/handlers/signup'
import ClientConfig from './ClientConfig'
import { InitOAuth2, SignInWithOAuth2 } from './SignInWithOAuth2'
import {
  callbackHandler as baseCallbackHandler,
  loginHandler as baseLoginHandler,
  signupHandler as baseSignupHandler,
  logoutHandler as baseLogoutHandler,
  profileHandler,
  handlerFactory,
  HandleAuth,
} from './handlers'
import {
  GetAccessToken,
  GetSession,
  accessTokenFactory,
  Claims,
  Session,
  SessionCache,
  sessionFactory,
  CookieStore,
} from './session'

let instance: SignInWithOAuth2

function getInstance(): SignInWithOAuth2 {
  if (instance) {
    return instance
  }
  instance = initOAuth2()
  return instance
}

export const initOAuth2: InitOAuth2 = () => {
  const clientConfig = new ClientConfig()

  // Init base layer (with base config)
  const cookieStore = new CookieStore(clientConfig)
  const sessionCache = new SessionCache(clientConfig, cookieStore)
  const handleLogin = baseLoginHandler(clientConfig)
  const handleSignup = baseSignupHandler(clientConfig)
  const handleLogout = baseLogoutHandler(clientConfig, sessionCache)
  const handleCallback = baseCallbackHandler(clientConfig, sessionCache)

  // Init Next layer (with next config)
  const getSession = sessionFactory(sessionCache)
  const getAccessToken = accessTokenFactory(clientConfig, sessionCache)
  const handleProfile = profileHandler(sessionCache, getAccessToken)
  const handleAuth = handlerFactory({ handleLogin, handleSignup, handleLogout, handleCallback, handleProfile })

  return {
    getSession,
    getAccessToken,
    handleLogin,
    handleSignup,
    handleLogout,
    handleCallback,
    handleProfile,
    handleAuth,
  }
}

export const getSession: GetSession = (...args) => getInstance().getSession(...args)
export const getAccessToken: GetAccessToken = (...args) => getInstance().getAccessToken(...args)
export const handleLogin: HandleLogin = (...args) => getInstance().handleLogin(...args)
export const handleSignup: HandleSignup = (...args) => getInstance().handleSignup(...args)
export const handleLogout: NextApiHandler = (...args) => getInstance().handleLogout(...args)
export const handleCallback: NextApiHandler = (...args) => getInstance().handleCallback(...args)
export const handleProfile: NextApiHandler = (...args) => getInstance().handleProfile(...args)
export const handleAuth: HandleAuth = (...args) => getInstance().handleAuth(...args)

export { SessionCache, Session }

export type { GetSession, GetAccessToken, Claims }
