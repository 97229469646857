import React, { FC } from 'react'
import classNames from 'classnames'
import { motion } from 'framer-motion'
import { MailboxIcon } from '@/atoms/Icons/MailboxIcon'
import { CaptionSM } from '@/atoms/Text'
import { useTranslate } from '@/utils/translate/translate-client'

export interface NotificationsBlankProps {
  isDarkMode?: boolean
}

export const NotificationsBlank: FC<NotificationsBlankProps> = React.memo(({ isDarkMode = true }) => {
  const { t } = useTranslate('account')

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      className={classNames(
        'flex h-[79.5vh] w-full flex-col items-center pt-12 sm:h-[50vh]',
        isDarkMode ? 'bg-core-gray-950' : 'bg-white',
      )}
    >
      <MailboxIcon size={60} color={isDarkMode ? 'gray-500' : 'core-gray-800'} />
      <CaptionSM className={classNames('ml-2', isDarkMode ? 'text-gray-500' : 'text-core-gray-800')}>
        {t('noNotifications', 'No Notifications')}
      </CaptionSM>
    </motion.div>
  )
})

NotificationsBlank.displayName = 'React.memo(NotificationsBlank)'
