import React from 'react'
import { OneColorIconProps, getHexColor } from '../utils'

export const CaretDownIcon: React.FC<OneColorIconProps> = ({ size = 24, color = 'white', className, fill = false }) => {
  const hex = getHexColor(color)

  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill={fill ? hex : 'none'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M19.5 9L12 16.5L4.5 9" stroke={hex} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}
