import { CookieSerializeOptions, serialize } from 'cookie'
import { ServerResponse } from 'http'
import ClientConfig from '../ClientConfig'

export interface StoreOptions {
  sameSite?: boolean | 'lax' | 'strict' | 'none'
  value?: string
}

const config = new ClientConfig()

export class CookieUtil {
  secret: string

  constructor() {
    this.secret = process.env.OAUTH2_SECRET as string
  }

  save(key: string, res: ServerResponse, { sameSite = 'lax', value = '' }: StoreOptions) {
    const isSameSiteNone = sameSite === 'none'
    const basicAttr = {
      secure: config.session.cookie.secure,
      domain: config.session.cookie.domain,
    }

    const secure = isSameSiteNone ? true : basicAttr.secure

    {
      this.setCookie(key, value, res, {
        ...basicAttr,
        sameSite,
        secure,
        path: '/',
      })
    }

    return value
  }

  setCookie(key: string, value: string, res: ServerResponse, options: Record<string, unknown>): void {
    const strCookie = serialize(key, value, options)

    let previousCookies = res.getHeader('Set-Cookie') || []
    if (!Array.isArray(previousCookies)) {
      previousCookies = [previousCookies as string]
    }
    res.setHeader('Set-Cookie', [...previousCookies, strCookie])
  }

  clear(key: string, res: ServerResponse) {
    const { domain, secure, sameSite } = config.session.cookie
    const clearOptions: CookieSerializeOptions = {
      domain,
      maxAge: 0,
    }
    // If SameSite=None is set, the cookie Secure attribute must also be set (or the cookie will be blocked)
    // https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite#none
    if (sameSite === 'none') {
      clearOptions.secure = secure
      clearOptions.sameSite = sameSite
    }

    this.setCookie(key, '', res, { path: '/', ...clearOptions })
  }
}
