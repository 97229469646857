import { useMemo } from 'react'
import { ApolloError, useQuery } from '@apollo/client'
import { GetSalesQuery } from '@/types/codegen-contentful'
import { useLocale } from '@/utils/LocaleUtil'
import { logger } from '@/utils/logging'
import { getContentfulClient } from '../ApolloClient'
import { GET_SALES_QUERY } from './queries'
import { getActiveSales } from './shared'
import { ValidSale } from './types'

interface UseSaleBannerResult {
  error?: ApolloError
  loading: boolean
  saleWithBanner: ValidSale | null
}

export function useSaleBanner(): UseSaleBannerResult {
  const { locale } = useLocale()
  const opts = { locale, preview: false }
  const queryResult = useQuery<GetSalesQuery>(GET_SALES_QUERY, { variables: opts, client: getContentfulClient(opts) })

  const result = useMemo(() => {
    const { data, loading, error } = queryResult

    if (loading) {
      return { loading, error, saleWithBanner: null }
    }

    if (error) {
      logger().error('An error occurred while attempting to query Sale objects from Contentful!', {}, error)
      return { loading, error, saleWithBanner: null }
    }

    const activeSales = getActiveSales(data)
    const salesWithBanner = activeSales.filter((s) => s.showSaleBanner)
    const saleWithBanner = salesWithBanner[0]

    if (salesWithBanner.length === 0) {
      logger().debug('There are currently no active sales with a banner. Nothing will be returned.')
      return { loading, saleWithBanner: null }
    } else if (salesWithBanner.length === 1) {
      logger().info('Successfully fetched an active sale with a banner.', { saleWithBanner })
      return { loading, saleWithBanner }
    } else {
      // Might need some fancy logic here someday, but for now concurrent sales should be an edge case, so just return the first.
      logger().error('There are currently multiple active sales with a banner! Only one will be used.', {
        saleWithBanner,
        salesWithBanner,
      })
      return { loading, saleWithBanner }
    }
  }, [queryResult])

  return result
}
