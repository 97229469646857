import { Result } from './types'

/**
 * Creates a cache for data that should be filled only once.
 * Cache refreshes are unsupported.
 * @param initCacheVal starting value for the cache
 * @param read how to read the cache. Cache will be considered hit
 * for all values except undefined
 * @param fill how to fill data in cache, called on cache miss
 */
export function createCache<C, T>(
  initCacheVal: C | undefined,
  read: (cache?: C) => T | undefined,
  fill: () => Promise<C>,
  overrides?: { maxTries?: number; backoffMsMultiplier?: number },
): () => Promise<Result<T>> {
  let mutex = false
  let cache = initCacheVal
  const opts = { maxTries: 10, backoffMsMultiplier: 200, ...overrides }

  async function readCache(tries = 1): Promise<Result<T>> {
    const value = read(cache)
    if (typeof value !== 'undefined') {
      return { ok: true, value }
    } else {
      if (mutex) {
        return new Promise((resolve) => {
          const timer = setTimeout(() => {
            clearTimeout(timer)
            if (tries > opts.maxTries) {
              return resolve({ ok: false, error: new CacheTimeoutError('Cache timed out') })
            }
            resolve(readCache(++tries))
          }, tries * opts.backoffMsMultiplier)
        })
      } else if (!cache) {
        mutex = true
        try {
          cache = await fill()
        } catch (err) {
          return { ok: false, error: err as Error }
        }
        mutex = false
        return readCache()
      } else {
        return { ok: true, value: undefined }
      }
    }
  }

  return readCache
}

export class CacheTimeoutError extends Error {
  constructor(message: string) {
    super(message)
  }
}
