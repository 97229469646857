import classNames from 'classnames'
import { ReactFCC } from '@/types/react'
import type { WindowWithCookieYes, WindowWithOsano, WindowWithPieEye } from './types'
import { getCookieConsentProvider } from './utils'

interface Props {
  className?: string
}

export const ManageDoNotSellLink: ReactFCC<Props> = ({ children, className }) => {
  const provider = getCookieConsentProvider()

  if (provider === 'osano') return <OsanoDoNotSell className={className}>{children}</OsanoDoNotSell>
  if (provider === 'cookie_yes') return <CookieYesDoNotSell className={className}>{children}</CookieYesDoNotSell>
  if (provider === 'pie_eye') return <PieEyeDoNotSell className={className}>{children}</PieEyeDoNotSell>
}

const OsanoDoNotSell: ReactFCC<Props> = ({ children, className }) => {
  const handleClick = () => {
    const w = window as WindowWithOsano
    w?.Osano?.cm?.showDoNotSell()
  }

  return (
    <span className={classNames('underline cursor-pointer', className)} onClick={handleClick}>
      {children}
    </span>
  )
}

const CookieYesDoNotSell: ReactFCC<Props> = ({ children, className }) => {
  const handleClick = () => {
    const w = window as WindowWithCookieYes
    if (w?.revisitCkyConsent) w.revisitCkyConsent()
  }

  return (
    <span className={classNames('underline cursor-pointer', className)} onClick={handleClick}>
      {children}
    </span>
  )
}

const PieEyeDoNotSell: ReactFCC<Props> = ({ children, className }) => {
  const handleClick = () => {
    const w = window as WindowWithPieEye
    if (w?.showPopup) w.showPopup()
  }

  return (
    <span className={classNames('underline cursor-pointer', className)} onClick={handleClick}>
      {children}
    </span>
  )
}
