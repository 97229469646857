import React from 'react'
import { LinkButton } from '@/atoms/Button'

export interface PifButtonProps extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  offerPath?: string
  textVariation: string
  trackCheckoutStarted?: () => void
}

export const PifButton: React.FC<PifButtonProps> = ({
  offerPath = '#',
  trackCheckoutStarted,
  textVariation,
  ...rest
}) => {
  return (
    <LinkButton
      href={offerPath}
      className="w-full justify-center !px-3 text-center hover:bg-gray-100"
      variant="gray-700"
      onClick={trackCheckoutStarted}
      outline
      {...rest}
    >
      {textVariation}
    </LinkButton>
  )
}
