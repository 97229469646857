import { useEffect, useState } from 'react'
import Bugsnag from '@bugsnag/js'
import { BranchError } from 'branch-sdk'
import { useRouter } from 'next/router'

const getErrorMessage = (err: string | null | Record<string, unknown>): string | undefined => {
  if (typeof err === 'string') {
    return err
  } else if (typeof err?.message === 'string') {
    return err?.message
  } else {
    return err?.toString() ?? undefined
  }
}

const notifyBugsnag = (error: string | null | Record<string, unknown>) => {
  const excludedErrors = ['Request blocked by client, probably adblock', 'Request timed out', 'Error in API: 0']
  const errorMsg = getErrorMessage(error)
  if (errorMsg && !excludedErrors.includes(errorMsg)) {
    Bugsnag.notify(`Branch error=${errorMsg}`)
  }
}

export const useBranch = () => {
  const router = useRouter()
  const [branchFingerPrint, setBranchFingerPrint] = useState<string>('')

  useEffect(() => {
    async function initAndFetch() {
      const BranchSDK = (await import('branch-sdk')).default
      BranchSDK.init(
        process.env.NEXT_PUBLIC_BRANCH_KEY as string,
        { timeout: 8000, retry_delay: 500 },
        (error: BranchError) => {
          if (error) {
            notifyBugsnag(error)
          } else {
            BranchSDK.getBrowserFingerprintId((error, data) => {
              if (error) {
                notifyBugsnag(error)
              } else {
                setBranchFingerPrint(() => {
                  return data as string
                })
              }
            })
          }
        },
      )
    }

    // Initialize here too since an initial page load isn't caught in the route events.  This makes sure
    // we can send track events before the next route event occurs
    initAndFetch()

    router.events.on('routeChangeComplete', initAndFetch)

    return () => {
      router.events.off('routeChangeComplete', initAndFetch)
    }
  }, [router.events])

  return [branchFingerPrint]
}
