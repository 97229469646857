import { DetailedHTMLProps, FC, HTMLAttributes } from 'react'
import classNames from 'classnames'

const getBreakPointClass = (breakPoint: 'sm' | 'md' | 'lg' | 'xl' | '2xl') => {
  switch (breakPoint) {
    case 'sm':
      return 'sm:flex-row sm:items-center'
    case 'md':
      return 'md:flex-row md:items-center'
    case 'lg':
      return 'lg:flex-row lg:items-center'
    case 'xl':
      return 'xl:flex-row xl:items-center'
    case '2xl':
      return '2xl:flex-row 2xl:items-center'
  }
}
interface Props extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  className?: string
  breakPoint?: 'sm' | 'md' | 'lg' | 'xl' | '2xl'
}
export const ResponsiveFlexRow: FC<Props> = ({ className, children, breakPoint = 'md', ...props }) => {
  return (
    <div className={classNames('flex flex-col', getBreakPointClass(breakPoint), className)} {...props}>
      {children}
    </div>
  )
}
