import Bugsnag from '@bugsnag/js'

type SessionProperty = 'pageSlug' | 'projectSlugs' | 'originalUtms' | 'originSlug' | 'torchSubmissionPromoCode'

export const setSessionProperty = (field: SessionProperty, value: unknown) => {
  if (typeof sessionStorage === 'undefined') return

  sessionStorage.setItem(field, JSON.stringify(value))
}

export function getSessionProperty<T>(name: SessionProperty, fallback?: T): T | undefined {
  if (typeof sessionStorage === 'undefined') return fallback
  try {
    return sessionStorage.getItem(name) ? JSON.parse(sessionStorage[name]) : fallback
  } catch (e) {
    Bugsnag.notify(`Error reading SessionStorage for ${name}`)
  }

  return fallback
}
