import React from 'react'
import { ApolloProvider } from '@apollo/client'
import { useRouter } from 'next/router'
import { ThemeContextProvider } from '@/contexts/ThemeContext'
import { Analytics } from '@/layout/Analytics'
import { ErrorBoundary, ErrorFallback } from '@/layout/ErrorBoundary'
import { DefaultSeo } from '@/layout/Seo'
import { LanguagesProvider } from '@/molecules/LanguageSelector'
import { SaleBanner } from '@/molecules/SaleBanner'
import { ToastProvider } from '@/molecules/Toast/ToastProvider'
import { Footer } from '@/organisms/Footer'
import { SiteNav } from '@/organisms/SiteNav'
import { getWebClient } from '@/services/ApolloClient'
import { TheatricalReleaseRegion } from '@/types/codegen-federation'
import { useLocale } from '@/utils/LocaleUtil'

export interface DefaultLayoutProps {
  children: React.ReactNode
  isDarkMode?: boolean
  siteNavGradient?: boolean
  siteNavTransparent?: boolean
  regions?: TheatricalReleaseRegion[]
  showFooter?: boolean
  hideNavigation?: boolean
}

export const DefaultLayout: React.FC<DefaultLayoutProps> = ({
  children,
  isDarkMode = true,
  siteNavGradient = false,
  siteNavTransparent = false,
  regions,
  showFooter = true,
  hideNavigation = false,
}) => {
  const { query } = useRouter()
  const { locale } = useLocale()
  const region = Array.isArray(query.region) ? query.region[0] : query.region
  const client = getWebClient({ locale, region: region?.length === 2 ? region : undefined })

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <DefaultSeo />
      <LanguagesProvider>
        <Analytics>
          <ApolloProvider client={client}>
            <ThemeContextProvider isDarkMode={isDarkMode}>
              <ToastProvider>
                <SiteNav
                  hideNavigation={hideNavigation}
                  regions={regions}
                  gradientBackground={siteNavGradient}
                  transparentBackground={siteNavTransparent}
                />
                <SaleBanner />
                <ErrorBoundary FallbackComponent={ErrorFallback}>{children}</ErrorBoundary>
                {showFooter && <Footer />}
              </ToastProvider>
            </ThemeContextProvider>
          </ApolloProvider>
        </Analytics>
      </LanguagesProvider>
    </ErrorBoundary>
  )
}
