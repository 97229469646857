import React from 'react'
import { getHexColor, OneColorIconProps } from '../utils'

export const CircleQuestionIcon: React.FC<OneColorIconProps> = ({ size = 16, color = 'black', className }) => {
  const hex = getHexColor(color)

  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
        stroke={hex}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.0625 16.875C13.0625 17.4618 12.5868 17.9375 12 17.9375C11.4132 17.9375 10.9375 17.4618 10.9375 16.875C10.9375 16.2882 11.4132 15.8125 12 15.8125C12.5868 15.8125 13.0625 16.2882 13.0625 16.875Z"
        fill="#63696F"
        stroke={hex}
        strokeWidth="0.125"
      />
      <path
        d="M12 13.5004V12.7504C12.5192 12.7504 13.0267 12.5965 13.4584 12.308C13.8901 12.0196 14.2265 11.6096 14.4252 11.13C14.6239 10.6503 14.6758 10.1225 14.5746 9.61332C14.4733 9.10412 14.2233 8.63639 13.8562 8.26927C13.489 7.90216 13.0213 7.65215 12.5121 7.55087C12.0029 7.44958 11.4751 7.50156 10.9955 7.70024C10.5158 7.89892 10.1058 8.23538 9.81739 8.66706C9.52895 9.09874 9.375 9.60625 9.375 10.1254"
        stroke={hex}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
