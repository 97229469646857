import React, { FC, useCallback } from 'react'
import classNames from 'classnames'
import { CloseIcon } from '@/atoms/Icons/CloseIcon'
import { useThemeContext } from '@/contexts/ThemeContext'
import { useLanguages } from '@/molecules/LanguageSelector'
import { Modal } from '@/molecules/Modal'
import { useLocale } from '@/utils/LocaleUtil'
import { useTranslate } from '@/utils/translate/translate-client'
import { LanguageHeader } from './LanguageHeader'
import { LanguageOption } from './LanguageOption'

interface LanguageModalProps {
  show: boolean
  onClose: () => void
}

export const LanguageModal: FC<LanguageModalProps> = ({ onClose, show }) => {
  const { locale } = useLocale()
  const { betaLanguages, publicLanguages, saveLanguage, languagesEnabled, betaLanguagesEnabled } = useLanguages()
  const { t } = useTranslate('common')
  const { isDarkMode } = useThemeContext()

  const handleCloseClick = () => {
    onClose()
  }

  const handleSelectLanguage = useCallback(
    (language: string) => {
      saveLanguage(language)
      onClose()
    },
    [saveLanguage, onClose],
  )

  if (!languagesEnabled) {
    return null
  }

  return show ? (
    <div className="px-20">
      <Modal className={classNames('z-[10000]')} panelClasses="bg-white max-w-[300px]" isOpen={show} onClose={onClose}>
        <div className="mb-4 flex items-center justify-between">
          <LanguageHeader isDarkMode={false} value={t('languages', 'Languages')} />
          <div className="cursor-pointer" onClick={handleCloseClick}>
            <CloseIcon size={18} color="core-gray-800" />
          </div>
        </div>
        <div className="overflow-y-auto">
          {publicLanguages.map((language) => (
            <div key={language} onClick={() => handleSelectLanguage(language)}>
              <LanguageOption isDarkMode={false} language={language} selected={locale === language} />
            </div>
          ))}
          {betaLanguagesEnabled && (
            <>
              <div className="border-b-1 mb-5 mt-4 border border-x-0 border-t-0 border-white/10" />
              <div className="mb-4">
                <LanguageHeader isDarkMode={isDarkMode} value={t('betaLanguages', 'Beta Languages')} />
              </div>
              {betaLanguages.map((language) => (
                <div key={language} onClick={() => handleSelectLanguage(language)}>
                  <LanguageOption
                    isDarkMode={isDarkMode}
                    key={language}
                    language={language}
                    selected={locale === language}
                  />
                </div>
              ))}
            </>
          )}
        </div>
      </Modal>
    </div>
  ) : null
}
