import { MouseEvent, useCallback, useRef } from 'react'
import classNames from 'classnames'
import { ReactFCC } from '@/types/react'
import { Button, ButtonProps } from './Button'

export const ProgressButton: ReactFCC<ButtonProps> = ({ children, className, variant, onClick, ...props }) => {
  const buttonRef = useRef(null)

  const handleButtonClick = useCallback(
    (e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => {
      const element = buttonRef?.current as HTMLDivElement | null
      if (element) {
        element.style.animationPlayState = 'paused'
      }
      if (onClick) {
        onClick(e)
      }
    },
    [onClick],
  )

  return (
    <Button
      variant={variant}
      className={`relative h-10 w-full overflow-hidden rounded-lg border-0 ${className}`}
      {...props}
      onClick={handleButtonClick}
    >
      <div
        className={
          (classNames(variant === 'oxide-bright' ? 'bg-oxide-bright' : 'bg-gray-600'), `absolute inset-0 rounded-lg`)
        }
      />
      <div
        className={classNames(
          'absolute inset-0 scale-x-0 rounded-lg rounded-r-none transition-all ease-linear animate-shrink-progress-btn transform origin-left delay-[250ms]',
          variant === 'oxide-bright' ? 'bg-black opacity-[0.1] transition-opacity duration-[10000ms]' : 'bg-white',
        )}
        ref={buttonRef}
      />
      <div className={`absolute inset-0 flex items-center justify-center bg-transparent`}>{children}</div>
    </Button>
  )
}
