import { IncomingMessage, ServerResponse } from 'http'
import { NextApiRequest, NextApiResponse } from 'next'
import ClientConfig from '../ClientConfig'
import { CookieStore } from './CookieStore'
import { Session, fromJson, fromTokenSet } from './Session'
import { TokenSet } from './TokenSet'

type NextApiOrPageRequest = IncomingMessage | NextApiRequest
type NextApiOrPageResponse = ServerResponse | NextApiResponse

interface InitResponse {
  iat?: number
  shouldAddHeaders?: boolean
}

export class SessionCache {
  private cache: WeakMap<NextApiOrPageRequest, Session | null>
  private cookieStore: CookieStore

  constructor(config: ClientConfig, cookieStore: CookieStore) {
    this.cookieStore = cookieStore
    this.cache = new WeakMap()
  }

  async init(req: NextApiOrPageRequest): Promise<InitResponse> {
    if (!this.cache.has(req)) {
      const { json, iat } = await this.cookieStore.read(req)
      this.cache.set(req, fromJson(json))

      return {
        shouldAddHeaders: true,
        iat,
      }
    }

    return {
      shouldAddHeaders: false,
    }
  }

  async finalize(req: NextApiOrPageRequest, res: NextApiOrPageResponse, iat?: number) {
    const session = this.cache.get(req)
    await this.cookieStore.save(req, res, session, iat)
  }

  async create(req: NextApiOrPageRequest, res: NextApiOrPageResponse, session: Session): Promise<void> {
    this.cache.set(req, session)

    await this.finalize(req, res)
  }

  async delete(req: NextApiOrPageRequest, res: NextApiOrPageResponse): Promise<void> {
    this.cache.set(req, null)
    this.cookieStore.forgetUser(req, res)

    await this.finalize(req, res)
  }

  async isAuthenticated(req: NextApiOrPageRequest, res: NextApiOrPageResponse): Promise<boolean> {
    const { shouldAddHeaders, iat } = await this.init(req)
    const session = this.cache.get(req)

    if (shouldAddHeaders) {
      await this.finalize(req, res, iat)
    }

    return !!session?.user
  }

  async getIdToken(req: NextApiOrPageRequest, res: NextApiOrPageResponse): Promise<string | undefined> {
    const { shouldAddHeaders, iat } = await this.init(req)
    const session = this.cache.get(req)

    if (shouldAddHeaders) {
      await this.finalize(req, res, iat)
    }

    return session?.idToken
  }

  async set(req: NextApiOrPageRequest, res: NextApiOrPageResponse, session: Session | null): Promise<void> {
    const { shouldAddHeaders, iat } = await this.init(req)
    this.cache.set(req, session)

    if (shouldAddHeaders) {
      await this.finalize(req, res, iat)
    }
  }

  async get(req: NextApiOrPageRequest, res: NextApiOrPageResponse): Promise<Session | null | undefined> {
    const { shouldAddHeaders, iat } = await this.init(req)

    if (shouldAddHeaders) {
      await this.finalize(req, res, iat)
    }

    return this.cache.get(req)
  }

  fromTokenSet(tokenSet: TokenSet): Session {
    return fromTokenSet(tokenSet)
  }
}
