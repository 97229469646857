import classNames from 'classnames'
import { ReactFCC } from '@/types/react'
import type { WindowWithCookieYes, WindowWithOsano } from './types'
import { getCookieConsentProvider } from './utils'

interface Props {
  className?: string
}

export const ManageCookieConsentLink: ReactFCC<Props> = ({ children, className }) => {
  const provider = getCookieConsentProvider()

  if (provider === 'osano') return <OsanoDrawerLink className={className}>{children}</OsanoDrawerLink>
  if (provider === 'cookie_yes') return <CookieYesDrawerLink className={className}>{children}</CookieYesDrawerLink>
  if (provider === 'pie_eye') return <PieEyeDrawerLink className={className}>{children}</PieEyeDrawerLink>
}

const OsanoDrawerLink: ReactFCC<Props> = ({ children, className }) => {
  const handleClick = () => {
    const w = window as WindowWithOsano
    w?.Osano?.cm?.showDrawer('osano-cm-dom-info-dialog-open')
  }

  return (
    <span className={classNames('underline cursor-pointer', className)} onClick={handleClick}>
      {children}
    </span>
  )
}

const CookieYesDrawerLink: ReactFCC<Props> = ({ children, className }) => {
  const handleClick = () => {
    const w = window as WindowWithCookieYes
    if (w?.revisitCkyConsent) w.revisitCkyConsent()
  }

  return (
    <span className={classNames('underline cursor-pointer', className)} onClick={handleClick}>
      {children}
    </span>
  )
}

const PieEyeDrawerLink: ReactFCC<Props> = ({ children, className }) => {
  // The id of the element must match what is conifigured in the Pie Eye dashboard. Pie Eye will register a click event based on element id.
  const DIALOG_TAG_ID = 'pie_eye_dialog'

  return (
    <span id={DIALOG_TAG_ID} className={classNames('underline cursor-pointer', className)}>
      {children}
    </span>
  )
}
