export const proxyServiceURLOrigin = `https://proxy.angelstudios.com`

export type ResolveUrlCallback = (params: {
  url: URL
  location: Location
  type: 'fetch' | 'xhr' | 'script' | 'iframe' | 'image'
}) => URL

export type ResolveUrlsConfig = {
  resolveUrl: ResolveUrlCallback | `${'function'} ${string}(${string}) {${string}}`
}

export const resolveUrlsConfig: ResolveUrlsConfig[] = [
  // segment
  {
    resolveUrl: /* js */ `function segmentResolveUrl(url, location, type) {
      // console.debug("segmentResolveUrl", type, url.toString(), url, location)
      if (type === 'script' && url.toString().match(RegExp("^https?://cdn\\.segment\\.com/analytics\\.js/v1/"))) {
        const proxyUrl = new URL("/segment" + url.pathname, "${proxyServiceURLOrigin}")
        proxyUrl.search = url.search
        proxyUrl.hash = url.hash
        return proxyUrl
      }
      if (type === 'script' && url.toString().match(RegExp("^https?://cdn\\.segment\\.com/analytics-next/"))) {
        const proxyUrl = new URL("/segment" + url.pathname, "${proxyServiceURLOrigin}")
        proxyUrl.search = url.search
        proxyUrl.hash = url.hash
        return proxyUrl
      }
      if (type === 'script' && url.toString().match(RegExp("^https?://cdn\\.segment\\.com/next-integrations/"))) {
        const proxyUrl = new URL("/segment" + url.pathname, "${proxyServiceURLOrigin}")
        proxyUrl.search = url.search
        proxyUrl.hash = url.hash
        return proxyUrl
      }

      return url
    }`,
  },
  // facebook
  {
    resolveUrl: /* js */ `function connectFacebookNetResolveUrl(url, location, type) {
      // console.debug("connectFacebookNetResolveUrl", url.toString(), url, location, type)
      if (type === 'script' && url.toString().match(RegExp("^https?://connect\\.facebook\\.net/"))) {
        const proxyUrl = new URL("/facebook" + url.pathname, "${proxyServiceURLOrigin}")
        proxyUrl.search = url.search
        proxyUrl.hash = url.hash
        return proxyUrl
      }
      return url
    }`,
  },
  {
    resolveUrl: /* js */ `function facebookComResolveUrl(url, location, type) {
      // console.debug("facebookComResolveUrl", url.toString(), url, location, type)
      if (type === 'script' && url.toString().match(RegExp("^https?://www\\.facebook\\.com/"))) {
        const proxyUrl = new URL("/facebook-com" + url.pathname, "${proxyServiceURLOrigin}")
        proxyUrl.search = url.search
        proxyUrl.hash = url.hash
        return proxyUrl
      }
      return url
    }`,
  },
]

export const resolveUrl = /* js */ `function resolveUrl(url, location, type) {
  // console.debug("resolveUrl", url.toString(), url, location, type)
  const resolveUrlsConfig = ${JSON.stringify(resolveUrlsConfig)};
  const newURL = resolveUrlsConfig.reduce((acc, resolveUrlConfig) => {
    return new Function('return ' + resolveUrlConfig.resolveUrl)()(acc, location, type) ?? acc
  }, url)
  // console.debug("newURL", newURL.toString(), newURL)
  return newURL
}`
