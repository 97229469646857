import React, { AnchorHTMLAttributes, AriaRole, KeyboardEventHandler, MouseEventHandler, useCallback } from 'react'
import classNames from 'classnames'
import { useRouter } from 'next/router'
import { When } from 'react-if'
import { CaretDownIcon } from '@/atoms/Icons/CaretDownIcon'
import { CaptionMD } from '@/atoms/Text'
import { useThemeContext } from '@/contexts/ThemeContext'
import { ReactFCC } from '@/types/react'
import { useSafeTrack } from '@/utils/analytics'

interface NavMenuProps {
  label: string
  href: string
  isOpen: boolean
  onClose: () => void
  onOpen: () => void
  id: string
}

const NavMenuRoot: ReactFCC<NavMenuProps> = ({ id, label, children, href, isOpen, onClose, onOpen }) => {
  const track = useSafeTrack()
  const { isDarkMode } = useThemeContext()

  const handleClickAwayFromMenu: MouseEventHandler = useCallback(
    (e) => {
      e.preventDefault()
      onClose()
    },
    [onClose],
  )

  const handleItemClick: MouseEventHandler = useCallback(
    (e) => {
      e.stopPropagation()
      e.preventDefault()
      if (isOpen) {
        onClose()
      } else {
        onOpen()
      }
    },
    [isOpen, onOpen, onClose],
  )

  const handleKeyDown: KeyboardEventHandler<HTMLDivElement> = useCallback(
    (e) => {
      if (e.key === 'Enter' || e.key === ' ') {
        if (document.activeElement?.role === 'menu') {
          e.stopPropagation()
          e.preventDefault()
          if (isOpen) {
            onClose()
          } else {
            onOpen()
          }
        }
      }
    },
    [isOpen, onOpen, onClose],
  )

  const handleClick = useCallback(() => {
    // TODO Clean up this tracking event later, just testing top button clicks between the two variations separate from the menu clicks
    track('Site Nav Item Clicked', { href, navType: 'desktop' })
  }, [track, href])

  return (
    <div>
      <When condition={isOpen}>
        <div
          className="absolute left-0 top-0 z-[9998] h-screen w-screen max-w-full bg-transparent"
          onClick={handleClickAwayFromMenu}
        />
      </When>
      <div className="relative" onKeyDown={handleKeyDown}>
        <div className="group-nav-menu-item relative flex flex-row">
          <a
            className={classNames(
              'flex flew-row gap-x-2 px-1.5 py-1 items-center cursor-pointer',
              'rounded-md bg-transparent transition-bg duration-200',
              isDarkMode
                ? 'group-nav-menu-item-hover:bg-[rgb(255,255,255,0.1)]'
                : 'group-nav-menu-item-hover:bg-[rgb(0,0,0,0.06)]',
            )}
            onClick={handleClick}
            href={href}
          >
            <CaptionMD color={isDarkMode ? 'white' : 'black'} className="pl-1">
              {label}
            </CaptionMD>
            <div
              className={classNames(
                'flex flew-row gap-x-2 items-center cursor-pointer',
                'rounded-md p-1 bg-transparent transition-bg duration-200',
                isDarkMode ? 'hover:bg-[rgb(255,255,255,0.3)]' : 'hover:bg-[rgb(0,0,0,0.08)]',
              )}
              onClick={handleItemClick}
              role="button"
              aria-label={label}
              aria-controls={id}
              aria-expanded={isOpen}
              tabIndex={0}
            >
              <CaretDownIcon
                className={classNames('transition-all duration-300 ease-in-out', isOpen ? 'rotate-180' : 'rotate-0')}
                color={isDarkMode ? 'white' : 'black'}
                size={20}
              />
            </div>
          </a>
        </div>
        <div
          id={id}
          className={classNames(
            {
              'z-[9999] absolute left-0 top-8 flex flex-col': isOpen,
              'min-w-[200px] p-2 rounded-b-lg rounded-tr-lg': isOpen,
            },
            isDarkMode ? 'shadow-xl' : 'shadow-md',
            isDarkMode ? 'bg-gray-700' : 'bg-gray-100',
          )}
          onClick={handleItemClick}
        >
          <When condition={isOpen}>
            <ul role="menu">
              {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                React.Children.map(React.Children.toArray(children), (child: any) => {
                  return <li role="menuitem">{React.cloneElement(child)}</li>
                })
              }
            </ul>
          </When>
        </div>
      </div>
    </div>
  )
}

interface ItemProps {
  Element: ReactFCC<AnchorHTMLAttributes<HTMLAnchorElement> & { href: string }>
  label: string
  href: string
  className?: string
  linkContext?: string
  onClick?: () => void
  id: string
  role?: AriaRole
  disableAutoNavigate?: boolean
}

const Item = ({ disableAutoNavigate, label, href, Element, className, onClick, id, role, ...rest }: ItemProps) => {
  const router = useRouter()
  const { isDarkMode } = useThemeContext()
  const track = useSafeTrack()

  const isActive = router.asPath.startsWith(href)
  const fontWeight = isActive ? 'semibold' : 'medium'

  const handleClick: React.MouseEventHandler<HTMLAnchorElement> = useCallback(
    (e) => {
      e.preventDefault()
      e.stopPropagation()
      track('Nav Item Clicked', { href, id, navType: 'desktop' })
      onClick && onClick()
      if ((!disableAutoNavigate && e.currentTarget?.role === 'menuitem') || e.currentTarget?.role === 'link') {
        router.push(href)
      }
    },
    [disableAutoNavigate, track, href, id, onClick, router],
  )

  const handleKeyDown: KeyboardEventHandler<HTMLAnchorElement> = useCallback(
    (e) => {
      track('Nav Item Clicked', { href, id, navType: 'desktop' })
      onClick && onClick()
      if (e.key === 'Enter' || e.key === ' ') {
        e.preventDefault()
        e.stopPropagation()
        onClick && onClick()
        if ((!disableAutoNavigate && e.currentTarget?.role === 'menuitem') || e.currentTarget?.role === 'link') {
          router.push(href)
        }
      }
    },
    [disableAutoNavigate, track, id, href, onClick, router],
  )

  return (
    <>
      <Element
        className={classNames(
          'whitespace-nowrap block',
          'rounded-md p-2 bg-transparent transition-bg duration-200',
          isDarkMode ? 'hover:bg-[rgb(255,255,255,0.1)]' : 'hover:bg-[rgb(0,0,0,0.06)]',
          className,
        )}
        role={role ?? 'link'}
        href={href}
        onClick={handleClick}
        onKeyDown={handleKeyDown}
        aria-label={label}
        {...rest}
      >
        <CaptionMD color={isDarkMode ? 'white' : 'black'} weight={fontWeight}>
          {label}
        </CaptionMD>
      </Element>
    </>
  )
}

export const NavMenu = Object.assign(NavMenuRoot, { Item })
