import React, { useContext } from 'react'
import { Transition } from '@headlessui/react'
import { getTransitionClasses } from '@/molecules/utils'
import { ToastMessage } from './ToastMessage'
import { ToastContext } from './ToastProvider'

export const Toaster = () => {
  const { canShowToast, closeToast, toast } = useContext(ToastContext)

  return (
    <div className="fixed z-[999999] mx-auto mt-16 w-full">
      <div className="mx-4 flex justify-center">
        <Transition show={canShowToast} {...getTransitionClasses('toast')}>
          <ToastMessage
            shouldHideClose={toast?.shouldHideClose}
            icon={toast?.icon}
            button={toast?.button}
            message={toast?.message as string}
            onClose={closeToast}
            showInMillis={toast?.showInMillis}
          />
        </Transition>
      </div>
    </div>
  )
}
