import React from 'react'
import { getHexColor, OneColorIconProps } from '../utils'

export const CheckDoubleIcon: React.FC<OneColorIconProps> = ({ className, color = 'white', size = 16 }) => {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.114 21C9.98446 21 9.86301 20.9793 9.74965 20.938C9.6363 20.8973 9.53104 20.8277 9.43388 20.7293L5.25602 16.4966C5.07789 16.3162 4.99271 16.0822 5.00049 15.7948C5.00891 15.508 5.10218 15.2744 5.28031 15.094C5.45843 14.9135 5.68514 14.8233 5.96043 14.8233C6.23571 14.8233 6.46242 14.9135 6.64054 15.094L10.114 18.613L18.3483 10.2707C18.5264 10.0902 18.7573 10 19.041 10C19.3241 10 19.5547 10.0902 19.7328 10.2707C19.9109 10.4512 20 10.6848 20 10.9715C20 11.259 19.9109 11.4929 19.7328 11.6734L10.7941 20.7293C10.697 20.8277 10.5917 20.8973 10.4784 20.938C10.365 20.9793 10.2435 21 10.114 21Z"
        fill={getHexColor(color)}
      />
      <path
        d="M10.114 14C9.98446 14 9.86301 13.9793 9.74965 13.938C9.6363 13.8973 9.53104 13.8277 9.43388 13.7293L5.25602 9.49664C5.07789 9.31618 4.99271 9.08224 5.00049 8.79481C5.00891 8.50804 5.10218 8.27442 5.28031 8.09396C5.45843 7.9135 5.68514 7.82327 5.96043 7.82327C6.23571 7.82327 6.46242 7.9135 6.64054 8.09396L10.114 11.613L18.3483 3.27069C18.5264 3.09023 18.7573 3 19.041 3C19.3241 3 19.5547 3.09023 19.7328 3.27069C19.9109 3.45116 20 3.68477 20 3.97154C20 4.25897 19.9109 4.49292 19.7328 4.67338L10.7941 13.7293C10.697 13.8277 10.5917 13.8973 10.4784 13.938C10.365 13.9793 10.2435 14 10.114 14Z"
        fill={getHexColor(color)}
      />
    </svg>
  )
}
