import { useCallback } from 'react'
import { Maybe, MoneyCurrencyCode, ReservationDiscount } from '@/types/codegen-federation'
import { useSafeTrack } from '@/utils/analytics'

const PLATFORM = 'angel-web'

const PRODUCT_VIEWED = 'Product Viewed'
const PRODUCT_ADDED = 'Product Added'
const CHECKOUT_STARTED = 'Checkout Started'
const ORDER_COMPLETED = 'Order Completed'

export const ECOMMERCE_EVENTS = [PRODUCT_VIEWED, PRODUCT_ADDED, CHECKOUT_STARTED, ORDER_COMPLETED] as const

export interface EcommPayload {
  funnel: 'pif' | 'theatrical' | 'theatrical-pre-sale' | 'guild' | 'collectibles'
  projectSlug?: string | null
}

export interface Product {
  product_id: string
  sku: string
  category: string
  name: string
  brand?: string
  variant?: string
  price: number
  quantity: number
  coupon?: string
  position?: number
  url?: string | null
  image_url?: string
  currency: MoneyCurrencyCode
  /** @deprecated - used for PIF: delete after old tracking is removed */
  region?: string
}

interface ProductViewedPayload extends EcommPayload, Product {
  price: number
  value?: number // total value of the product after quantity
}

export interface ProductAddedPayload extends EcommPayload, Product {
  cart_id?: string
}

interface CheckoutStartedPayload extends EcommPayload {
  order_id?: string | null
  affiliation?: string
  checkout_id?: string | null
  value: number // revenue with discounts and coupons added in
  revenue?: number // excluding shipping and tax
  shipping?: number
  tax?: number
  discount?: number
  coupon?: string
  currency: MoneyCurrencyCode
  products?: Product[]
  subscription?: boolean
}

export interface OrderCompletedPayload extends EcommPayload {
  email: string
  checkout_id?: string | null
  order_id: string
  affiliation?: string | null
  subtotal?: number
  total: number
  revenue?: number
  shipping?: number
  tax?: number
  discount?: number
  coupon?: string
  currency: MoneyCurrencyCode
  products?: Product[]
  referring_user_id?: string | null
  subscription?: boolean
  /** @deprecated - used for PIF: delete after old tracking is removed */
  category?: string
  /** @deprecated - used for PIF: delete after old tracking is removed */
  region?: string
  /** @deprecated - used for PIF: delete after old tracking is removed */
  project?: string
  /** @deprecated - used for PIF: delete after old tracking is removed */
  payment_method?: string | null
}

function getCoupons(discounts?: Maybe<ReservationDiscount[]>) {
  if (!discounts) return undefined

  return discounts.reduce((prev, curr) => {
    return prev + curr.code + ','
  }, '')
}
/**
 * Hook for tracking ecommerce events.
 * https://www.notion.so/angelstudios/Segment-Ecom-Events-0f7e8222f783493f8ccfd7c6d17ef8f7
 */
export const useEcommerceEvents = () => {
  const track = useSafeTrack()

  /** Fire this event when a visitor views a product. That view might happen on a page, screen, or preview modal. */
  const trackProductViewed = useCallback(
    (payload: ProductViewedPayload) =>
      track(PRODUCT_VIEWED, { ...payload, platform: PLATFORM, value: payload.value ?? payload.price }),
    [track],
  )

  /** Fire this event when a visitor adds a product to their shopping cart or click cta for a product that takes them to checkout. */
  const trackProductAdded = useCallback(
    (payload: ProductAddedPayload) => track(PRODUCT_ADDED, { ...payload, platform: PLATFORM }),
    [track],
  )

  /** Fire this event whenever an order/transaction was started.
   * Fire on the page that the customer lands on after they press the checkout button. */
  const trackCheckoutStarted = useCallback(
    (payload: CheckoutStartedPayload) =>
      track(CHECKOUT_STARTED, {
        ...payload,
        platform: PLATFORM,
        revenue: payload.revenue ?? payload.value,
      }),
    [track],
  )

  /** Fire this event whenever an order/transaction was successfully completed by the customer. */
  const trackOrderCompleted = useCallback(
    (payload: OrderCompletedPayload, options?: unknown | null, callback?: (...params: unknown[]) => void) =>
      track(
        ORDER_COMPLETED,
        {
          ...payload,
          platform: PLATFORM,
          revenue: payload.revenue ?? payload.total,
          value: payload.total,
        },
        options,
        callback,
      ),
    [track],
  )

  return {
    getCoupons,
    trackProductViewed,
    trackProductAdded,
    trackCheckoutStarted,
    trackOrderCompleted,
  }
}
