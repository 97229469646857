import { createContext, PropsWithChildren, useContext, useState } from 'react'
import { ReactFCC } from '@/types/react'

interface BeforeYouLeaveContextType extends BeforeYouLeaveOptions {
  openModal: (options: BeforeYouLeaveOptions) => void
  closeModal: () => void
  showModal: boolean
}

interface BeforeYouLeaveOptions {
  body: string
  header?: string
  continueLabel: string
  stayLabel: string
  onContinue: () => void
  onStay: () => void
}

const BeforeYouLeaveContext = createContext<BeforeYouLeaveContextType>({} as BeforeYouLeaveContextType)

export type BeforeYouLeaveProps = PropsWithChildren<unknown>

export const BeforeYouLeaveProvider: ReactFCC<BeforeYouLeaveProps> = ({ children }) => {
  const [showModal, setShowModal] = useState(false)

  const [options, setOptions] = useState<BeforeYouLeaveOptions | undefined>()

  const openModal = (options: BeforeYouLeaveOptions) => {
    setOptions(options)
    setShowModal(true)
  }

  const closeModal = () => {
    setShowModal(false)
  }

  const value = {
    ...options,
    openModal,
    closeModal,
    showModal,
  } as BeforeYouLeaveContextType

  return <BeforeYouLeaveContext.Provider value={value}>{children}</BeforeYouLeaveContext.Provider>
}

export function useBeforeYouLeave() {
  return useContext(BeforeYouLeaveContext)
}
