import React from 'react'
import { getHexColor, OneColorIconProps } from '../utils'

export const CopyIcon: React.FC<OneColorIconProps> = ({ color = 'gray-600', className, size }) => {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.75 15.7494H20.25V3.74939H8.25V8.24939"
        stroke={getHexColor(color)}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.75 8.24951H3.75V20.2495H15.75V8.24951Z"
        stroke={getHexColor(color)}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
