import React from 'react'
import { getHexColor, OneColorIconProps } from '../utils'

export const LogoutIcon: React.FC<OneColorIconProps> = ({ size = 24, color = 'black', className }) => {
  const hex = getHexColor(color)
  return (
    <svg
      data-testid="logout-icon"
      className={className}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.7552 16.275C15.5718 16.0583 15.4802 15.8125 15.4802 15.5375C15.4802 15.2625 15.5718 15.0333 15.7552 14.85L17.6052 13H10.4302C10.1468 13 9.90934 12.9042 9.71768 12.7125C9.52601 12.5208 9.43018 12.2833 9.43018 12C9.43018 11.7167 9.52601 11.4792 9.71768 11.2875C9.90934 11.0958 10.1468 11 10.4302 11H17.6052L15.7552 9.15C15.5552 8.95 15.4552 8.7125 15.4552 8.4375C15.4552 8.1625 15.5552 7.925 15.7552 7.725C15.9385 7.525 16.1677 7.425 16.4427 7.425C16.7177 7.425 16.9468 7.51667 17.1302 7.7L20.7302 11.3C20.8302 11.4 20.901 11.5083 20.9427 11.625C20.9843 11.7417 21.0052 11.8667 21.0052 12C21.0052 12.1333 20.9843 12.2583 20.9427 12.375C20.901 12.4917 20.8302 12.6 20.7302 12.7L17.1302 16.3C16.9135 16.5167 16.676 16.6125 16.4177 16.5875C16.1593 16.5625 15.9385 16.4583 15.7552 16.275ZM5.43018 21C4.88018 21 4.40934 20.8042 4.01768 20.4125C3.62601 20.0208 3.43018 19.55 3.43018 19V5C3.43018 4.45 3.62601 3.97917 4.01768 3.5875C4.40934 3.19583 4.88018 3 5.43018 3H11.4302C11.7135 3 11.951 3.09583 12.1427 3.2875C12.3343 3.47917 12.4302 3.71667 12.4302 4C12.4302 4.28333 12.3343 4.52083 12.1427 4.7125C11.951 4.90417 11.7135 5 11.4302 5H5.43018V19H11.4302C11.7135 19 11.951 19.0958 12.1427 19.2875C12.3343 19.4792 12.4302 19.7167 12.4302 20C12.4302 20.2833 12.3343 20.5208 12.1427 20.7125C11.951 20.9042 11.7135 21 11.4302 21H5.43018Z"
        fill={hex}
      ></path>
    </svg>
  )
}
