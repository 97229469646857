import React from 'react'
import { ApolloProvider } from '@apollo/client'
import classNames from 'classnames'
import { AccountContextProvider } from '@/contexts/AccountContext'
import { ThemeContextProvider } from '@/contexts/ThemeContext'
import { Analytics } from '@/layout/Analytics'
import { ErrorFallback, ErrorBoundary } from '@/layout/ErrorBoundary'
import { DefaultSeo } from '@/layout/Seo'
import { LanguagesProvider } from '@/molecules/LanguageSelector'
import { ToastProvider } from '@/molecules/Toast/ToastProvider'
import { Footer } from '@/organisms/Footer'
import { SiteNav } from '@/organisms/SiteNav'
import { getWebClient } from '@/services/ApolloClient'
import { useLocale } from '@/utils/LocaleUtil'

interface AccountLayoutProps {
  children: React.ReactNode
  isDarkMode: boolean
}

export const AccountLayout: React.FC<AccountLayoutProps> = ({ children, isDarkMode }) => {
  const { locale } = useLocale()
  const client = getWebClient({ locale })

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <DefaultSeo />
      <ApolloProvider client={client}>
        <LanguagesProvider>
          <AccountContextProvider>
            <Analytics>
              <ThemeContextProvider isDarkMode={isDarkMode}>
                <ToastProvider>
                  <SiteNav />
                  <ErrorBoundary FallbackComponent={ErrorFallback}>
                    <div
                      className={classNames('min-h-screen', {
                        'bg-black text-white': isDarkMode,
                        'bg-core-gray-100 text-black': !isDarkMode,
                      })}
                    >
                      {children}
                    </div>
                  </ErrorBoundary>
                  <Footer />
                </ToastProvider>
              </ThemeContextProvider>
            </Analytics>
          </AccountContextProvider>
        </LanguagesProvider>
      </ApolloProvider>
    </ErrorBoundary>
  )
}
