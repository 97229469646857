import { DetailedHTMLProps, FC, HTMLAttributes } from 'react'
import classNames from 'classnames'

interface Props extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  className?: string
}
export const FlexColumn: FC<Props> = ({ className, children, ...props }) => {
  return (
    <div className={classNames('flex flex-col', className)} {...props}>
      {children}
    </div>
  )
}
