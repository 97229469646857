import { setCookie } from 'cookies-next'
import { NextApiRequest, NextApiResponse } from 'next'
import qs from 'query-string'
import { SEGMENT_ANONYMOUS_ID_COOKIE } from '@/constants/cookies'
import { Durations } from '@/constants/durations'
import { getAnonymousId } from '@/services/requests'
import { getItemOrFirstEntry } from '@/utils/array-utils'
import ClientConfig from '../ClientConfig'
import { getProtocol } from './util'

export type HandleSignup = (req: NextApiRequest, res: NextApiResponse) => Promise<void>

type AuthorizeQuery = {
  client_id: string
  audience: string
  redirect_uri: string
  scope: string
  response_type: string
  response_mode: string
  state?: string
}

export const handler =
  (config: ClientConfig): HandleSignup =>
  async (req: NextApiRequest, res: NextApiResponse) => {
    if (req.method === 'GET') {
      res.setHeader('Cache-Control', 'no-cache, no-store, max-age=0, must-revalidate')
      res.setHeader('Access-Control-Allow-Headers', '*')

      const protocol = getProtocol(req)

      const q = {
        client_id: config.clientId,
        audience: config.audience,
        redirect_uri: `${protocol}://${req.headers.host}/api/auth/callback`,
        scope: config.scope,
        response_type: 'code',
        response_mode: 'query',
        auth_route: 'signup',
      } as AuthorizeQuery

      if (req.query.returnTo) {
        const returnTo = getItemOrFirstEntry(req.query.returnTo)
        const parsed = Buffer.from(returnTo, 'base64').toString('utf-8')
        const fullyQualified = parsed?.startsWith('http') ? parsed : `${protocol}://${req.headers.host}${parsed}`
        const { anonymousId, shouldSetCookie } = getAnonymousId(req.cookies)
        if (shouldSetCookie) {
          setCookie(SEGMENT_ANONYMOUS_ID_COOKIE, anonymousId, {
            req,
            res,
            maxAge: Durations.TEN_YEARS_IN_SECONDS,
            path: '/',
          })
        }
        q.state = Buffer.from(JSON.stringify({ returnTo: fullyQualified, anonymousId }), 'utf-8').toString('base64url')
      }

      const query = qs.stringify(q)
      res.redirect(`${config.authorizationUri}?${query}`)
    } else {
      res.status(405)
      res.end()
    }
  }

export default handler
