import React from 'react'
import { getHexColor, OneColorIconProps } from '../utils'

export const ChatBubbleOutline: React.FC<OneColorIconProps> = ({ size = 24, color = 'black', className }) => {
  const hex = getHexColor(color)
  return (
    <svg
      data-testid="chat-bubble-outline"
      className={className}
      width={size}
      height={size}
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        id="Vector"
        d="M2 20.575V5C2 4.45 2.196 3.979 2.588 3.587C2.97933 3.19567 3.45 3 4 3H20C20.55 3 21.021 3.19567 21.413 3.587C21.8043 3.979 22 4.45 22 5V17C22 17.55 21.8043 18.021 21.413 18.413C21.021 18.8043 20.55 19 20 19H6L3.7 21.3C3.38333 21.6167 3.02067 21.6873 2.612 21.512C2.204 21.3373 2 21.025 2 20.575ZM4 18.175L5.175 17H20V5H4V18.175Z"
        fill={hex}
      />
    </svg>
  )
}
