import dynamic, { DynamicOptions } from 'next/dynamic'
import { reportErrorToBugsnag } from '@/utils/bugsnag'
import { OmniboxSearchInputProps } from './OmniboxSearchInput'

export const OmniboxSearchInput = dynamic(
  import('./OmniboxSearchInput')
    .then((mod) => mod.OmniboxSearchInput)
    .catch((err) =>
      reportErrorToBugsnag(`Failed to load the SiteNavSearchInput! ${err.message}`),
    ) as DynamicOptions<OmniboxSearchInputProps>,
  { ssr: false },
)
