import React from 'react'
import { getHexColor, OneColorIconProps } from '../utils'

export const LanguageIcon: React.FC<OneColorIconProps> = ({ size = 24, color = 'white', className }) => {
  const hex = getHexColor(color)
  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.09375 8.20703C5.95312 7.78516 5.8125 7.01172 5.8125 7.01172H5.77734C5.77734 7.01172 5.63672 7.78516 5.53125 8.20703L5.10938 9.50781H6.48047L6.09375 8.20703ZM22.4062 9.25H12.5625V19.5C12.5625 20.0523 13.0102 20.5 13.5625 20.5H22.4062C22.8633 20.5 23.25 20.1484 23.25 19.6562V10.0938C23.25 9.63672 22.8633 9.25 22.4062 9.25ZM21.5625 13.4688C21.5625 13.7148 21.3516 13.8906 21.1406 13.8906H20.7188C20.4727 14.7344 19.9453 15.5781 19.207 16.3516C19.5234 16.5977 19.8398 16.8086 20.1562 16.9844C20.332 17.125 20.4023 17.3711 20.2969 17.582L20.0156 18.0391C19.875 18.25 19.6289 18.3203 19.418 18.2148C18.9609 17.9336 18.5742 17.6523 18.1875 17.3359C17.8008 17.6523 17.3789 17.9336 16.9219 18.2148C16.7109 18.3203 16.4648 18.25 16.3594 18.0391L16.0781 17.582C15.9375 17.3711 16.0078 17.125 16.2188 16.9844C16.5352 16.8086 16.8516 16.5977 17.1328 16.3516C16.8516 16.0703 16.6055 15.7539 16.3945 15.4727C16.2539 15.2617 16.3242 14.9805 16.5352 14.875L16.7461 14.7344L16.9922 14.5586C17.2031 14.4531 17.4492 14.5234 17.5547 14.6992C17.7305 14.9453 17.9414 15.1914 18.1875 15.4375C18.6445 14.9102 19.0312 14.418 19.2422 13.8906H15.2344C14.9883 13.8906 14.8125 13.7148 14.8125 13.4688V12.9062C14.8125 12.6953 14.9883 12.4844 15.2344 12.4844H17.4844V11.9219C17.4844 11.7109 17.6602 11.5 17.9062 11.5H18.4688C18.6797 11.5 18.8906 11.7109 18.8906 11.9219V12.4844H21.1406C21.3516 12.4844 21.5625 12.6953 21.5625 12.9062V13.4688ZM0.75 4.09375V13.6562C0.75 14.1484 1.10156 14.5 1.59375 14.5H11.4375V4.25C11.4375 3.69772 10.9898 3.25 10.4375 3.25H1.59375C1.10156 3.25 0.75 3.63672 0.75 4.09375ZM2.78906 11.7227L4.82812 5.74609C4.89844 5.60547 5.03906 5.46484 5.21484 5.46484H6.375C6.55078 5.46484 6.72656 5.60547 6.76172 5.74609L8.80078 11.7227C8.87109 11.9688 8.69531 12.25 8.37891 12.25H7.57031C7.39453 12.25 7.21875 12.1445 7.18359 11.9688L6.86719 10.8438H4.72266L4.40625 11.9688C4.37109 12.1445 4.19531 12.25 4.01953 12.25H3.21094C2.92969 12.25 2.71875 11.9688 2.78906 11.7227Z"
        fill={hex}
      />
    </svg>
  )
}
