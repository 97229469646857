import React, { MouseEvent } from 'react'
import { OptimizelyFeature } from '@optimizely/react-sdk'
import classNames from 'classnames'
import { useRouter } from 'next/router'
import { ExternalLink } from '@/atoms/ExternalLink'
import { FacebookCircleSimpleIcon } from '@/atoms/Icons/FacebookCircleSimpleIcon'
import { InstagramCircleIcon } from '@/atoms/Icons/InstagramCircleIcon'
import { TikTokCircleIcon } from '@/atoms/Icons/TikTokCircleIcon'
import { TwitterXCircleIcon } from '@/atoms/Icons/TwitterXCircleIcon'
import { YouTubeCircleIcon } from '@/atoms/Icons/YouTubeCircleIcon'
import { InternalLink } from '@/atoms/InternalLink'
import { PaddedContainer } from '@/atoms/PaddedContainer'
import { paths } from '@/constants/paths'
import { soundOfFreedomTomatoUpdatedDateTime } from '@/constants/rottenTomatoes'
import { BeforeYouLeaveProvider } from '@/contexts/BeforeYouLeaveContext'
import { useThemeContext } from '@/contexts/ThemeContext'
import { BeforeYouLeaveModal } from '@/molecules/BeforeYouLeaveModal'
import { ManageCookieConsentLink, ManageDoNotSellLink } from '@/molecules/CookieManagement'
import { AngelFundingLink } from '@/molecules/InvestLink'
import { ExperimentVariantPicker } from '@/organisms/Footer/ExperimentVariantPicker'
import { getYouTubeLocaleLink, useLocale } from '@/utils/LocaleUtil'
import { useSafeTrack } from '@/utils/analytics'
import { Translate, TranslateFunction, useTranslate } from '@/utils/translate/translate-client'
import { UpdatedLegalDocsModal } from '../UpdatedLegalDocsModal'

const watch = (t: TranslateFunction): Pick<FooterSectionProps, 'label' | 'links'> => ({
  label: t('watch', 'Watch'),
  links: [
    { label: t('theWingfeatherSaga', 'The Wingfeather Saga'), internalHref: paths.watch.wingfeatherSaga },
    { label: t('tuttleTwins', 'Tuttle Twins'), internalHref: paths.watch.tuttleTwins },
    { label: t('dryBarComedy', 'Dry Bar Comedy'), internalHref: paths.watch.dryBar },
    { label: t('hisOnlySon', 'His Only Son'), internalHref: paths.watch.hisOnlySon },
    { label: t('viewAll', 'View All'), internalHref: paths.watch.index },
  ],
})

const genres = (t: TranslateFunction): Pick<FooterSectionProps, 'label' | 'links'> => ({
  label: t('genres', 'Genres'),
  links: [
    {
      label: t('christian', 'Christian'),
      internalHref: paths.category.christianMovies,
    },
    {
      label: t('comedy', 'Comedy'),
      internalHref: paths.category.comedyTvShows,
    },
    {
      label: t('drama', 'Drama'),
      internalHref: paths.category.drama,
    },
    {
      label: t('documentary', 'Documentary'),
      internalHref: paths.category.documentaries,
    },
    {
      label: t('kidsShows', 'Kids Shows'),
      internalHref: paths.category.kidsShows,
    },
    {
      label: t('viewAll', 'View All'),
      internalHref: paths.category.index,
    },
  ],
})

const upcoming = (t: TranslateFunction): Pick<FooterSectionProps, 'label' | 'links'> => ({
  label: t('investAngelProjects', 'Invest in Angel Projects'),
  links: [
    {
      label: 'DAVID',
      externalHref: paths.external.invest.david,
      projectSlug: 'david',
      linkContext: 'footer',
      investLink: true,
    },
    {
      label: 'Live Not By Lies',
      externalHref: paths.external.invest.liveNotByLies,
      projectSlug: 'live',
      linkContext: 'footer',
      investLink: true,
    },
    {
      label: 'Gabriel and the Guardians',
      externalHref: paths.external.invest.gabrielAndTheGuardians,
      projectSlug: 'guardians',
      linkContext: 'footer',
      investLink: true,
    },
    {
      label: 'The Axiom Chronicles',
      externalHref: paths.external.invest.axiom,
      projectSlug: 'axiom',
      linkContext: 'footer',
      investLink: true,
    },
    {
      label: t('viewAll', 'View All'),
      externalHref: paths.external.invest.index,
      linkContext: 'footer',
      investLink: true,
    },
  ],
})

const about = (t: TranslateFunction): Pick<FooterSectionProps, 'label' | 'links'> => ({
  label: t('about', 'About'),
  links: [
    { label: t('company', 'Company'), internalHref: paths.about.index },
    { label: t('ourMission', 'Our Mission'), internalHref: paths.about.ourMission },
    { label: t('investorRelations', 'Investor Relations'), externalHref: paths.external.investorRelations.index },
    { label: t('careers', 'Careers'), externalHref: paths.external.careers },
    { label: t('press', 'Press'), internalHref: paths.press.index },
    { label: t('blog', 'Blog'), internalHref: paths.blog.index },
    { label: t('guild', 'Guild'), internalHref: paths.guild.join },
    { label: t('help', 'Help'), externalHref: paths.external.zendesk.support },
  ],
})

const legal = (t: TranslateFunction): Pick<FooterSectionProps, 'label' | 'links'> => ({
  label: t('legal', 'Legal'),
  links: [
    { label: t('privacyPolicy', 'Privacy Policy'), internalHref: paths.legal.privacyPolicy },
    { label: t('termsOfUse', 'Terms of Use'), internalHref: paths.legal.termsOfUse },
    { label: t('videoPrivacyPolicy', 'Video Privacy Policy'), internalHref: paths.legal.videoPrivacyPolicy },
    { label: t('supportedDevices', 'Supported Devices'), internalHref: paths.legal.devices },
    { label: t('manageCookiePreferences', 'Manage Cookie Preferences'), manageCookieLink: true },
    { label: t('doNotSellMyInformation', 'Do Not Sell My Information'), doNotSellLink: true },
  ],
})

const shows = (t: TranslateFunction): Pick<FooterSectionProps, 'label' | 'links'> => ({
  label: t('learnMore', 'Learn More'),
  links: [
    {
      label: 'SIGHT',
      internalHref: paths.movies.sight,
    },
    {
      label: 'Sound of Hope',
      internalHref: paths.movies.soundOfHopeTheStoryOfPossumTrot,
    },
    {
      label: 'Bonhoeffer',
      internalHref: paths.movies.bonhoeffer,
    },
    {
      label: 'Homestead',
      internalHref: paths.movies.homestead,
    },
  ],
})

const currentYear = new Date().getFullYear()
const footerSectionSpacingClasses = 'py-6 sm:mr-12 lg:basis-[25%] max-[1341px]:basis-[28%] max-[1535px]:basis-1/5'

export const Footer: React.FC = () => {
  const { pathname } = useRouter()
  const { locale } = useLocale()
  // Requested to have dark footer on every page
  const isDarkMode = true
  const footerContentTextClasses = `font-light lg:max-w-[170px] break-words ${
    isDarkMode ? 'text-gray-300' : 'text-gray-700'
  }`
  const { t } = useTranslate('common')

  const updatedDate = Intl.DateTimeFormat(locale, { dateStyle: 'long' }).format(
    new Date(soundOfFreedomTomatoUpdatedDateTime),
  )
  const tomatoUpdate = t('rottenTomatoesScoreUpdatedDate', `Rotten Tomatoes score last updated {{updatedDate}}`, {
    updatedDate,
  })

  return (
    <>
      <OptimizelyFeature feature="maintenance_mode">
        {(isEnabled, variables) => {
          return isEnabled ? (
            <div className="fixed inset-x-0 bottom-0 z-[9001] w-screen py-8">
              <PaddedContainer>
                <div className="relative mx-auto flex max-w-[800px] items-center rounded-lg bg-gray-900 p-4 text-white">
                  <div className="mr-8 h-20 w-4 rounded-full bg-warning-500" />
                  <div>{variables?.message}</div>
                </div>
              </PaddedContainer>
            </div>
          ) : null
        }}
      </OptimizelyFeature>
      <footer
        className={classNames('relative z-10', isDarkMode ? 'bg-core-gray-950 text-white' : 'bg-white text-black')}
      >
        <h2 className="sr-only">
          <Translate i18nKey="footerNav" t={t}>
            Footer Navigation
          </Translate>
        </h2>
        <PaddedContainer className="max-w-[1600px]">
          <div className="flex w-full flex-col items-stretch pb-16 pt-8 md:px-14 lg:flex-row lg:px-0">
            <div className="ml-4 flex flex-col flex-wrap lg:ml-0 lg:flex-row 2xl:grow-1 2xl:flex-nowrap">
              <FooterSection {...watch(t)} footerContentTextClasses={footerContentTextClasses} />
              <FooterSection {...genres(t)} footerContentTextClasses={footerContentTextClasses} />
              <FooterSection {...upcoming(t)} footerContentTextClasses={footerContentTextClasses} />
              <FooterSection {...legal(t)} footerContentTextClasses={footerContentTextClasses} />
              <FooterSection {...about(t)} footerContentTextClasses={footerContentTextClasses} />
              <FooterSection {...shows(t)} footerContentTextClasses={footerContentTextClasses} />
            </div>

            <div
              className={classNames(
                'text-xs ml-4 lg:ml-2',
                footerSectionSpacingClasses,
                footerContentTextClasses,
                '!basis-full !mr-0 md:!max-w-[370px] !lg:max-w-[340px] !lg:max-w-[320px] !xl:max-w-[305px]',
              )}
            >
              <ExperimentVariantPicker key="experiment-variant-picker" logoColor={isDarkMode ? 'white' : 'black'} />

              <div className="mt-6 flex flex-row space-x-6">
                <ExternalLink
                  aria-label={t('followOn', 'Follow on {{socialNetwork}}', { socialNetwork: 'Facebook' })}
                  href="https://www.facebook.com/AngelStudios.Inc"
                  className="transition-all duration-200 ease-in-out hover:brightness-125"
                >
                  <FacebookCircleSimpleIcon size={32} color={isDarkMode ? 'core-gray-300' : 'black'} />
                </ExternalLink>
                <ExternalLink
                  aria-label={t('followOn', 'Follow on {{socialNetwork}}', { socialNetwork: 'YouTube' })}
                  href={getYouTubeLocaleLink(locale)}
                  className="transition-all duration-200 ease-in-out hover:brightness-125"
                >
                  <YouTubeCircleIcon size={32} color={isDarkMode ? 'core-gray-300' : 'black'} />
                </ExternalLink>
                <ExternalLink
                  aria-label={t('followOn', 'Follow on {{socialNetwork}}', { socialNetwork: 'X' })}
                  href="https://twitter.com/AngelStudiosInc"
                  className="transition-all duration-200 ease-in-out hover:brightness-125"
                >
                  <TwitterXCircleIcon size={32} color={isDarkMode ? 'core-gray-300' : 'black'} />
                </ExternalLink>
                <ExternalLink
                  aria-label={t('followOn', 'Follow on {{socialNetwork}}', { socialNetwork: 'Instagram' })}
                  href="https://www.instagram.com/angelstudios_inc/"
                  className="transition-all duration-200 ease-in-out hover:brightness-125"
                >
                  <InstagramCircleIcon size={32} color={isDarkMode ? 'core-gray-300' : 'black'} />
                </ExternalLink>
                <ExternalLink
                  aria-label={t('followOn', 'Follow on {{socialNetwork}}', { socialNetwork: 'TikTok' })}
                  href="https://www.tiktok.com/@angelstudiosinc"
                  className="transition-all duration-200 ease-in-out hover:brightness-125"
                >
                  <TikTokCircleIcon size={32} color={isDarkMode ? 'core-gray-300' : 'black'} />
                </ExternalLink>
              </div>
              <p className="mt-4">
                <Translate t={t} i18nKey="angelStudiosIsANewKindOfStudiov2">
                  Angel Studios is a new kind of movie studio - we produce and distribute award-winning titles from
                  independent creators.
                </Translate>
              </p>
              {pathname?.includes('guild/join/early-access') && <p className="mt-4">*2 Free Tickets opening weekend</p>}
              {pathname?.includes('sound-of-freedom') && (
                <p className="mt-4">
                  {t(
                    'americasNumberOneBoxOfficeV1',
                    `Sound of Freedom was #1 in the box office on July 4 2023, July 10 2023, July 11, and July 20 2023.`,
                  )}
                </p>
              )}
              {pathname?.includes('sound-of-freedom') && <p className="mt-4">{tomatoUpdate}</p>}
              <p className="mt-6 uppercase">
                <Translate t={t} i18nKey="copyright" values={{ currentYear }}>
                  Copyright © {{ currentYear }} by Angel Studios, All Rights Reserved
                </Translate>
              </p>
            </div>
          </div>
          {pathname.match(/\/guild$/) && (
            <div className="-mt-8 space-y-2 px-4 pb-16 md:px-0">
              <h3>{t('giveawayRules', 'Giveaway Rules')}</h3>
              <p className={classNames('text-[14px] font-light', isDarkMode ? 'text-gray-300' : 'text-gray-700')}>
                <Translate t={t} i18nKey="homesteadSweepstakesGeneralRulesV2">
                  * * * LIMIT OF FOURTEEN THOUSAND ONE HUNDRED (14,100) ENTRIES PER PERSON/EMAIL ADDRESS. * * * NO
                  PURCHASE OR PAYMENT OF ANY KIND IS NECESSARY TO ENTER OR WIN. PURCHASE OR PAYMENT DOES NOT IMPROVE
                  YOUR CHANCE OF WINNING. The 300K Instant Homestead Giveaway is open only to legal residents of the 48
                  Contiguous U.S. States (VOID in AK and HI) & DC, 21 or older. Void elsewhere and where prohibited..
                  Promotion starts 10/11/24 and ends 12/22/24. To enter, visit https://angel.com/homesteadgiveaway .
                  Sponsor: Angel Studios, 295 W Center Street, Provo, UT 84601.. Subject to complete Official Rules at{' '}
                  <a href="https://bit.ly/HomesteadSweeps" target="_blank" className="underline">
                    https://bit.ly/HomesteadSweeps.
                  </a>
                </Translate>
              </p>
            </div>
          )}
        </PaddedContainer>
      </footer>
      <UpdatedLegalDocsModal />
    </>
  )
}

interface FooterSectionProps {
  label: string
  links: (FooterLink | InvestFooterLink)[]
  footerContentTextClasses: string
}

interface FooterLink {
  label: string
  internalHref?: string
  externalHref?: string
  investLink?: boolean
  manageCookieLink?: boolean
  doNotSellLink?: boolean
}

interface InvestFooterLink extends FooterLink {
  projectSlug: string
  linkContext: string
  externalHref: string
}

const FooterSection: React.FC<FooterSectionProps> = ({ label, links, footerContentTextClasses }) => {
  const track = useSafeTrack()
  const { isDarkMode } = useThemeContext()

  const handleLinkClick = (event: MouseEvent<HTMLAnchorElement>) => {
    if (event.target instanceof HTMLAnchorElement) {
      track('Footer Link Clicked', { href: event.target.href, label: event.target.firstChild?.textContent })
    }
  }

  return (
    <BeforeYouLeaveProvider>
      <BeforeYouLeaveModal />
      <div className={footerSectionSpacingClasses}>
        <h3>{label}</h3>
        <ul className={classNames('w-full flex flex-row flex-wrap lg:flex-col', footerContentTextClasses)}>
          {links.map((link) => {
            if (link.manageCookieLink) {
              return (
                <li
                  className={classNames(
                    'pr-[11.5px] lg:pr-0 w-1/2 lg:w-full',
                    isDarkMode ? 'hover:text-white' : 'hover:text-gray-600',
                  )}
                  key={link.label}
                >
                  <ManageCookieConsentLink className="mt-4 block no-underline">{link.label}</ManageCookieConsentLink>
                </li>
              )
            }

            if (link.doNotSellLink) {
              return (
                <li
                  className={classNames(
                    'pr-[11.5px] lg:pr-0 w-1/2 lg:w-full',
                    isDarkMode ? 'hover:text-white' : 'hover:text-gray-600',
                  )}
                  key={link.label}
                >
                  <ManageDoNotSellLink className="mt-4 block no-underline">{link.label}</ManageDoNotSellLink>
                </li>
              )
            }

            if (link.investLink) {
              const { projectSlug, linkContext, label, externalHref } = link as InvestFooterLink
              return (
                <li
                  className={classNames(
                    'pr-[11.5px] lg:pr-0 w-1/2 lg:w-full',
                    isDarkMode ? 'hover:text-white' : 'hover:text-gray-600',
                  )}
                  key={label}
                >
                  <AngelFundingLink
                    className="mt-4"
                    href={externalHref}
                    linkContext={linkContext}
                    onClick={handleLinkClick}
                    projectSlug={projectSlug}
                  >
                    {label}
                  </AngelFundingLink>
                </li>
              )
            }

            if (link.internalHref) {
              return (
                <li
                  className={classNames(
                    'pr-[11.5px] lg:pr-0 w-1/2 lg:w-full mt-4',
                    isDarkMode ? 'hover:text-white' : 'hover:text-gray-600',
                  )}
                  key={link.label}
                >
                  <InternalLink href={link.internalHref} onClick={handleLinkClick}>
                    {link.label}
                  </InternalLink>
                </li>
              )
            }

            if (link.externalHref) {
              return (
                <li
                  className={classNames(
                    'pr-[11.5px] lg:pr-0 w-1/2 lg:w-full mt-4',
                    isDarkMode ? 'hover:text-white' : 'hover:text-gray-600',
                  )}
                  key={link.label}
                >
                  <ExternalLink href={link.externalHref} onClick={handleLinkClick}>
                    {link.label}
                  </ExternalLink>
                </li>
              )
            }
          })}
        </ul>
      </div>
    </BeforeYouLeaveProvider>
  )
}
