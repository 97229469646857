import { gql } from '@apollo/client'

export const GET_USER_NOTIFICATIONS = gql`
  query UserNotifications {
    userNotifications {
      cloudinaryPath
      contentGuid
      createdAt
      deepLink
      eventDate
      id
      isDeleted
      message
      projectSlug
      publishDate
      title
      type
      updatedAt
    }
  }
`

export const GET_USER_NOTIFICATIONS_LIST = gql`
  query UserNotificationsList($input: UserNotificationFilter) {
    userNotificationList(input: $input) {
      hasMore
      total
      nextCursor
      notifications {
        id
        message
        publishDate
        read
        projectSlug
        cloudinaryPath
        deepLink
        type
        title
        message
        channel {
          displayName
          id
          iconCloudinaryPath
        }
      }
    }
  }
`

export const GET_USER_NOTIFICATION_COUNT = gql`
  query UserNotificationCount($input: UserNotificationFilter) {
    userNotificationCount: userNotificationList(input: $input) {
      total
    }
  }
`

export const GET_USER_NOTIFICATION_CHANNELS = gql`
  query UserNotificationChannels {
    userNotificationChannels {
      id
      displayName
    }
  }
`

export const SET_INBOX_LAST_CHECKED_AT = gql`
  mutation SetInboxLastCheckedAt {
    setInboxLastCheckedAt {
      id
      inboxLastCheckedAt
    }
  }
`

export const UPDATE_NOTIFICATION = gql`
  mutation NotificationUpdate($input: UserNotificationUpdateInput!) {
    notificationUpdate(input: $input) {
      id
    }
  }
`

export const MARK_ALL_NOTIFICATIONS_AS_READ = gql`
  mutation NotificationsMarkAllAsRead {
    notificationsMarkAllAsRead {
      id
      message
      publishDate
      read
      projectSlug
      cloudinaryPath
      deepLink
      type
      title
      message
    }
  }
`
