import { useCallback, useEffect, useMemo, useState } from 'react'
import segment from '@analytics/segment'
import {
  identify as fullstoryIdentify,
  init as fullstoryInit,
  isInitialized as isFullstoryInitialized,
} from '@fullstory/browser'
import { createInstance, OptimizelyDecideOption, setLogLevel } from '@optimizely/react-sdk'
import { default as AnalyticsModule } from 'analytics'
import { getCookie } from 'cookies-next'
import isEmpty from 'lodash/isEmpty'
import memoize from 'lodash/memoize'
import { NextRouter, useRouter } from 'next/router'
import { isAndroid, isDesktop, isIOS, isMobile } from 'react-device-detect'
import { AnalyticsProvider } from 'use-analytics'
import { SEGMENT_ANONYMOUS_ID_COOKIE } from '@/constants/cookies'
import { latamCountries } from '@/constants/latamCountries'
import { OptimizelyContextProvider } from '@/contexts/OptimizelyContext'
import { ExperimentContextProvider } from '@/experimentation/ExperimentContextProvider'
import { getGrowthbook } from '@/experimentation/utils'
import { useBranch } from '@/layout/useBranch'
import { useLegacyEffect } from '@/molecules/utils'
import { useHasVPPAConsent } from '@/services/EllisIslandService/legalDocumentHooks'
import { proxyServiceURLOrigin } from '@/services/Partytown'
import { useUser } from '@/services/UserService'
import { ReactFCC } from '@/types/react'
import { getRID } from '@/utils/RIDUtil'
import { getSessionProperty, setSessionProperty } from '@/utils/analytics/SessionAnalytics'
import { ECOMMERCE_EVENTS, EcommPayload } from '@/utils/analytics/ecommerce-events'
import { getItemOrFirstEntry } from '@/utils/array-utils'
import { getIsAngelEmployeeUser, getUserDisplayName } from '@/utils/users'
import { angelPartytownPlugin } from './plugins/angel-partytown'
import { everflowSegmentPlugin } from './plugins/everflow'

const appName = 'angel-web'

const buildId = process.env.NEXT_PUBLIC_BUILD_ID as string

// Segment user is always set in middleware with a cookie, so we need to sync it on init of David Wells analytics library.
// Setting syncAnonymousId to true is meant to sync the anonymousId across both segment and David's library, BUT it's not working
// on first page load (David lib and Segment have two different anonymous ID's). This custom plugin
// intercepts and overrides the anonymous ID as suggested here: https://github.com/DavidWells/analytics/issues/258
const customAnonIdPlugin = {
  name: 'set-anon-id',
  bootstrap: () => {
    /**
     * in addition to the setItemStart, we need to make sure to keep the localStorage that Segment uses in sync
     * with the cookie. We need the cookie for server side and middleware experiments.
     * We want to use the cookie value because it gets cleared and refreshed when a user logs out
     * which is the desired behavior because we don't know if it's the same person anymore.
     * so, if the cookie doesn't match localstorage when we start up, we should remove the localstorage entry.
     * i tried setting the localstorage entry instead, but that did not have the desired effect.
     * by removing it though, it does get set correctly from the setItemStart method in the same request/page load
     */
    if (typeof localStorage !== 'undefined') {
      const anonId = getCookie(SEGMENT_ANONYMOUS_ID_COOKIE)

      if (typeof anonId === 'string') {
        const localAnonId = localStorage.getItem('__anon_id')
        const localAjsAnonymousId = localStorage.getItem(SEGMENT_ANONYMOUS_ID_COOKIE)

        if (
          (localAnonId && JSON.parse(localAnonId) !== anonId) ||
          (localAjsAnonymousId && JSON.parse(localAjsAnonymousId) !== anonId)
        ) {
          localStorage.removeItem('__anon_id')
          localStorage.removeItem('__user_id')
          localStorage.removeItem('__user_traits')
          localStorage.removeItem('ajs_anonymous_id')
          localStorage.removeItem('ajs_user_id')
          localStorage.removeItem('ajs_user_traits')
        }
      }
    }
  },
  setItemStart: ({ payload }: { payload: { key: string } }) => {
    if (payload.key === '__anon_id' || payload.key === 'ajs_anonymous_id') {
      const anonId = getCookie(SEGMENT_ANONYMOUS_ID_COOKIE)
      if (anonId) {
        return {
          ...payload,
          ...{ value: anonId },
        }
      }
    }
  },
}

setLogLevel(process?.env?.NEXT_PUBLIC_OPTIMIZELY_LOG_LEVEL || 'warning')
const optimizely = createInstance({
  sdkKey: process.env.NEXT_PUBLIC_OPTIMIZELY_KEY,
  eventBatchSize: 5,
  eventFlushInterval: 5000,
})

const segmentPlugin = segment({
  syncAnonymousId: true,
  writeKey: process.env.NEXT_PUBLIC_SEGMENT_KEY,
  // eslint-disable-next-line prettier/prettier
  customScriptSrc: `${proxyServiceURLOrigin}/segment/analytics.js/v1/${process.env.NEXT_PUBLIC_SEGMENT_KEY}/analytics.min.js`,
})

function optimizelyTrack(eventName: string, payload?: Record<string, unknown>) {
  const o = optimizely as {
    client?: { projectConfigManager?: { getConfig?: () => { eventKeyMap?: Record<string, unknown> } } }
  }

  if (!o?.client?.projectConfigManager?.getConfig?.()?.eventKeyMap?.[eventName]) return

  const {
    user: { anonymousId, traits },
  } = analytics.getState()

  const optimizelyPayload = {} as Record<string, string | number | null>

  /**
   * optimizely only accepts props with string, number or null values so I'm ignoring any complex objects.
   * it's odd that their type seems to not support boolean...
   * https://github.com/optimizely/javascript-sdk/blob/4034c7dfaaa69e50a93cfc4adfcb7514b237658b/packages/optimizely-sdk/lib/shared_types.ts#L57
   */
  if (payload) {
    Object.entries(payload).forEach(([key, value]) => {
      if (typeof value === 'number' || typeof value === 'string' || value === null) {
        if (key === 'revenue' && typeof value === 'number') {
          // segment needs us to report in dollars all of the time, but optimizely wants it in cents
          optimizelyPayload[key] = value * 100
        } else {
          optimizelyPayload[key] = value
        }
      }
    })
  }

  try {
    optimizely.track(eventName, anonymousId, traits, optimizelyPayload)
  } catch (err) {
    // If an error occurs when attempting to send a tracking call to Optimizely, ignore and keep going.
  }
}

const decideAllOptimizelyExperimentDataMemo = memoize((anonymousId: string) => {
  const experiments = {} as Record<string, string>

  const decisions = optimizely.decideAll(
    [OptimizelyDecideOption.DISABLE_DECISION_EVENT, OptimizelyDecideOption.EXCLUDE_VARIABLES],
    anonymousId,
  )

  Object.entries(decisions).forEach(([key, value]) => {
    if (value?.variationKey) {
      experiments[key] = value.variationKey
    }
  })

  return JSON.stringify(experiments)
})

function optimizelyExperimentData(anonymousId: string) {
  if (!optimizely.isReady()) return JSON.stringify({})
  return decideAllOptimizelyExperimentDataMemo(anonymousId)
}

export const analytics = AnalyticsModule({
  app: appName,
  plugins: [
    customAnonIdPlugin,
    everflowSegmentPlugin(),
    {
      name: 'optimizely',
      enabled: true,
      track: (params: { payload: { event: string; properties: { experiments?: unknown } } }) => {
        optimizelyTrack(params.payload.event, params.payload.properties)
      },
      loaded: () => optimizely.isReady(),
    },
    {
      ...segmentPlugin,
      page: (params: { payload: { anonymousId: string; properties: { experiments?: unknown } } }) => {
        params.payload.properties.experiments =
          params.payload.properties.experiments || optimizelyExperimentData(params.payload.anonymousId)
        segmentPlugin.page(params)
      },
      track: (params: {
        payload: {
          event: string
          anonymousId: string
          properties: { experiments?: unknown; funnel?: EcommPayload['funnel'] }
        }
      }) => {
        params.payload.properties.experiments =
          params.payload.properties.experiments || optimizelyExperimentData(params.payload.anonymousId)
        segmentPlugin.track(params)
        if (
          !!params?.payload?.event &&
          !!params?.payload?.properties?.funnel &&
          ECOMMERCE_EVENTS.includes(params?.payload?.event as typeof ECOMMERCE_EVENTS[number])
        ) {
          segmentPlugin.track({
            ...params,
            payload: {
              ...params.payload,
              event: `${params.payload.event} - ${params.payload.properties.funnel}`,
            },
          })
        }
      },
      loaded: () => optimizely.isReady() && segmentPlugin?.loaded?.(),
    },
    angelPartytownPlugin({
      writeKey: process.env.NEXT_PUBLIC_THEATRICAL_SEGMENT_KEY,
      enabledDefault: false,
      enableRegex: /^https?:\/\/[-.a-zA-Z]+(:\d+)?\/((tickets)|(movies)|(pre-sales))\/?/,
    }),
  ],
})

const growthbook = getGrowthbook({}, analytics.track)

export const Analytics: ReactFCC = ({ children }) => {
  const { user } = useUser()
  const router = useRouter()
  const [hasLoaded, setHasLoaded] = useState(false)
  const isAngelEmployeeUser = getIsAngelEmployeeUser(user?.email)

  const {
    context: {
      locale,
      os: { name: operatingSystem },
      referrer,
      sessionId,
      timezone,
      userAgent,
    },
    user: { userId, anonymousId },
  } = analytics.getState()

  const optimizelyUser = useMemo(() => {
    return {
      id: anonymousId,
      attributes: {
        isMobile,
        isDesktop,
        isAngelEmployeeUser,
      },
    }
  }, [anonymousId, isAngelEmployeeUser])

  useLegacyEffect(() => {
    optimizely.setUser(optimizelyUser)
  }, [optimizelyUser])

  useEffect(() => {
    growthbook.setAttributes({
      ...growthbook.getAttributes(),
      id: anonymousId,
      userId,
      isMobile,
      isDesktop,
      isAngelEmployeeUser,
      // TODO: what else??
    })
  }, [anonymousId, isAngelEmployeeUser, userId])

  const handleScreenChange = useCallback(
    (url: string, region?: string) => {
      const previous = analytics.getState().page?.last?.properties?.to
      analytics.page({
        from: previous || url,
        to: url,
        url,
        utm: getSessionProperty('originalUtms', null),
        anonymous_id: anonymousId,
        region: region ? region : 'us',
        user_id: userId,
        session_id: sessionId,
        project: appName,
        buildId,
      })
    },
    [sessionId, anonymousId, userId],
  )

  const [branchFingerPrint] = useBranch()

  useEffect(() => {
    analytics.storage.setItem('browser_fingerprint_id', branchFingerPrint)
  }, [branchFingerPrint])

  const regionName = useMemo(() => {
    const regionString = router?.query?.region as string
    if (latamCountries.has(regionString)) return 'latam'
    if (!regionString) return 'us'
    return regionString
  }, [router?.query?.region])

  useEffect(() => {
    if (!hasLoaded && router) {
      const initialPageLoadUrl = router.asPath
      handleScreenChange(initialPageLoadUrl, regionName)
      setHasLoaded(true)

      if (isEmpty(getSessionProperty('originalUtms')) && router?.query) {
        const originalUtms = {} as Record<string, string>
        for (const [key, value] of Object.entries(router.query)) {
          if (key.startsWith('utm_') && !!value) {
            originalUtms[key] = getItemOrFirstEntry(value)
          }
        }
        setSessionProperty('originalUtms', originalUtms)
      }
    }
  }, [handleScreenChange, router, hasLoaded, regionName])

  useEffect(() => {
    if (typeof window === 'undefined') return
    if (shouldTrackFullStoryImpression(router)) {
      fullstoryInit({
        orgId: process.env.NEXT_PUBLIC_FULL_STORY_ORG_ID || '',
        devMode: process.env.NEXT_PUBLIC_ANGEL_ENV !== 'production',
      })
    }
  }, [router])

  const hasVPPAConsent = useHasVPPAConsent()

  useEffect(() => {
    const userId = user?.uuid
    const displayName = getUserDisplayName(user)
    const noVPPAConsent = 'no_vppa_consent'

    const fbc = getFbcCookie()
    const fbp = getFbpCookie()
    const userTraits = {
      ...(analytics?.user?.()?.traits || {}),
      rid: getRID(),
      did: anonymousId,
      anonymousId,
      userId,
      email: hasVPPAConsent ? user?.email : noVPPAConsent,
      name: hasVPPAConsent ? displayName : noVPPAConsent,
      nickname: null, // no longer exists on api -- optimizely does NOT like this being undefined
      picture: hasVPPAConsent ? user?.profile?.image : noVPPAConsent,
      locale,
      operatingSystem,
      referrer,
      timezone,
      userAgent,
      isMobile,
      isAndroid,
      isIOS,
      region: regionName,
      ...(fbc ? { fbc } : {}),
      ...(fbp ? { fbp } : {}),
    }

    if (userId) {
      analytics.identify(userId, userTraits)
      window?.branch?.setIdentity(userId)
      if (isFullstoryInitialized()) {
        const custom = hasVPPAConsent ? { displayName, email: user.email || '' } : undefined
        fullstoryIdentify(userId, custom)
      }
    } else {
      analytics.identify(analytics?.user()?.userId, userTraits)
    }
  }, [user, anonymousId, hasVPPAConsent, locale, operatingSystem, referrer, timezone, userAgent, regionName])

  const instance = useMemo(() => {
    return {
      ...analytics,
      track: (
        eventName: string,
        payload?: Record<string, unknown>,
        options?: Record<string, unknown>,
        callback?: (...params: unknown[]) => unknown,
      ) => {
        const { context, user } = analytics.getState()

        const fbc = getFbcCookie()
        const fbp = getFbpCookie()
        return analytics.track(
          eventName,
          {
            affiliation: 'Angel Web',
            user_id: user.userId,
            session_id: context.sessionId,
            anonymous_id: user.anonymousId,
            region: regionName,
            buildId,
            utm: getSessionProperty('originalUtms', null),
            browser_fingerprint_id: analytics.storage.getItem('browser_fingerprint_id'),
            angel_env: process.env.NEXT_PUBLIC_ANGEL_ENV,
            ...(fbc ? { fbc } : {}),
            ...(fbp ? { fbp } : {}),
            ...payload,
          },
          options,
          callback,
        )
      },
    }
  }, [regionName])

  return (
    <ExperimentContextProvider growthbook={growthbook}>
      <OptimizelyContextProvider optimizely={optimizely} user={optimizelyUser}>
        <AnalyticsProvider instance={instance}>{children}</AnalyticsProvider>
      </OptimizelyContextProvider>
    </ExperimentContextProvider>
  )
}

// facebook cookie
function getFbcCookie() {
  return getCookie('_fbc')
}
function getFbpCookie() {
  return getCookie('_fbp')
}

const FULL_STORY_SAMPLE_RATE = 0.3
const FULL_STORY_SESSION_STORAGE_KEY = 'fullStoryTracked'
function shouldTrackFullStoryImpression(router: NextRouter): boolean {
  if (router.asPath !== '/guild/join/angel-studios' && router.asPath !== '/guild/join') return false

  // this users session has already been tracked, don't track again
  if (typeof sessionStorage === 'undefined') return false
  if (sessionStorage.getItem(FULL_STORY_SESSION_STORAGE_KEY)) return false

  if (Math.random() < FULL_STORY_SAMPLE_RATE) {
    sessionStorage.setItem(FULL_STORY_SESSION_STORAGE_KEY, JSON.stringify(true))
    return true
  }

  return false
}
