export const latamCountries = new Set([
  'MX', // Mexico
  'GT', // Guatemala
  'HN', // Honduras
  'SV', // El Salvador
  'NI', // Nicaragua
  'BZ', // Belize
  'PA', // Panama
  'CO', // Colombia
  'VE', // Venezuela
  'AR', // Argentina
  'UY', // Uruguay
  'PY', // Paraguay
  'BO', // Bolivia
  'CL', // Chile
  'PE', // Peru
  'EC', // Ecuador
  'CR', // Costa Rica
])
