import { useEffect, useMemo, useState } from 'react'
import { getCookie } from 'cookies-next'
import { AccessTokenResponse } from 'pages/api/auth/accessToken'
import { paths } from '@/constants'
import { AUTH_JWT_COOKIE } from '@/constants/cookies'
import { hasAuthSessionCookie } from '@/utils/auth-utils'

export const getClientAccessToken = async (): Promise<string | undefined> => {
  // TODO rework the way accessToken is exposed so that it can work either on the client or on the server.
  // We get errors on server-rendered pages because this function gets called.
  if (typeof window === 'undefined') return undefined

  // Cookie is set on the server. See pages/api/auth/accessToken.ts
  const accessToken = getCookie(AUTH_JWT_COOKIE)
  if (typeof accessToken === 'string') return accessToken
  if (hasAuthSessionCookie()) return obtainAccessToken()
  else return undefined
}

const obtainAccessToken = (): Promise<string | undefined> => {
  return fetch(paths.auth.accessToken)
    .then((response) => {
      if (response.status === 200) {
        return response.json().then((payload: AccessTokenResponse) => payload?.accessToken)
      } else {
        return undefined
      }
    })
    .catch((e) => {
      // eslint-disable-next-line no-console
      console.error('Unable to fetch Access Token from the client', e)
      return undefined
    })
}

export const useClientAccessToken = (): { token?: string } => {
  const [token, setToken] = useState<string>()

  useEffect(() => {
    if (!token) getClientAccessToken().then(setToken)
  }, [token])

  return useMemo(() => ({ token }), [token])
}
