import React from 'react'
import { getHexColor, OneColorIconProps } from '../utils'

export const MailboxIcon: React.FC<OneColorIconProps> = ({ size = 24, color = 'black', className }) => {
  const hex = getHexColor(color)
  return (
    <svg className={className} width={size} height={size} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        d="m14 11h6v4h-2v-2h-4zm4-7h-10a5 5 0 0 0 -5 5v9h-2v2h20a2 2 0 0 0 2-2v-9a5 5 0 0 0 -5-5m-7 14h-6v-9a3 3 0 0 1 3-3 3 3 0 0 1 3 3zm10 0h-8v-9c0-1.08-.35-2.14-1-3h6a3 3 0 0 1 3 3z"
        fill={hex}
      />
    </svg>
  )
}
