interface PriceFormatterOptions {
  currency: string
  includeDecimals?: boolean
  ignoreEmptyDecimal?: boolean
  locale?: string
  skipDecimalMath?: boolean
  style?: string | undefined
}

/**
 * @param money - this should be the raw price as given by hydra/bim as this function will handle dividing our the decimals
 * @param options a variety of options to control the display of the price
 * @param options.currency this is case-insensitive
 */
export const formatPrice = (money: number, options: PriceFormatterOptions): string => {
  const _currency = options?.currency.toUpperCase()
  const price = !options?.skipDecimalMath ? getPriceForCurrency(money, _currency) : money
  const includeDecimals =
    typeof options?.includeDecimals === 'boolean' ? options.includeDecimals : !ZERO_DECIMAL_CURRENCIES.has(_currency)
  const ignoreEmptyDecimal = typeof options?.ignoreEmptyDecimal === 'boolean' ? options.ignoreEmptyDecimal : false

  const isWholeNumber = price % 1 === 0
  const shouldShowDecimalForNumber = !isWholeNumber || !ignoreEmptyDecimal

  const shouldUseDecimals = includeDecimals && shouldShowDecimalForNumber
  const decimals = shouldUseDecimals ? getDecimalsForCurrency(_currency) : 0

  const formatter = new Intl.NumberFormat(options?.locale || 'en-US', {
    style: options?.style ?? 'currency',
    currency: _currency,
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  })

  return formatter.format(price)
}

export const getPriceForCurrency = (price: number, currency: string): number => {
  const _currency = currency.toUpperCase()
  if (ZERO_DECIMAL_CURRENCIES.has(_currency)) {
    return price
  } else if (THREE_DECIMAL_CURRENCIES.has(_currency)) {
    return price / 1000
  } else {
    return price / 100
  }
}

export const ZERO_DECIMAL_CURRENCIES = new Set([
  'BIF',
  'CLP',
  'DJF',
  'GNF',
  'JPY',
  'KMF',
  'KRW',
  'MGA',
  'PYG',
  'RWF',
  'UGX',
  'VND',
  'VUV',
  'XAF',
  'XOF',
  'XPF',
])
export const THREE_DECIMAL_CURRENCIES = new Set(['BHD', 'JOD', 'KWD', 'OMR', 'TND'])

export const getDecimalsForCurrency = (currency: string): number => {
  const _currency = currency.toUpperCase()
  if (ZERO_DECIMAL_CURRENCIES.has(_currency)) return 0
  if (THREE_DECIMAL_CURRENCIES.has(_currency)) return 3
  return 2
}
