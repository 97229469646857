import { colors } from '../../constants/colors'

export function isHexColor(str: string): boolean {
  const isHex = /^#[0-9A-Fa-f]{3,6}$/
  return isHex.test(str) && (str.length === 4 || str.length === 7)
}

export function getHexColor(color: string): string {
  if (color === 'none') return ''
  if (color === 'inherit') return 'inherit'
  if (color === 'current') return 'currentColor'

  const hex = colors[color]
  if (typeof hex === 'string') return hex

  if (isHexColor(color)) return color

  throw new Error(`Please add a hex definition for color "${color}".`)
}
