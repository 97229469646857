import React from 'react'
import { NextSeo, NextSeoProps } from 'next-seo'
import { fullPublisher } from '@/constants/seo'
import { HrefLang } from './HrefLang'
import { ViewActionJsonLd } from './JsonLd/ViewActionJsonLd'
import { WebPageJsonLd } from './JsonLd/WebPageJsonLd'

export { DefaultSeo } from './Default'
export { VideoJsonLd } from 'next-seo'
export { useDataForEpisode, getBasicPageMetaData } from './SeoHelper'

export type NextSeoPropsWithRequiredFields = Required<
  Pick<NextSeoProps, 'canonical' | 'description' | 'openGraph' | 'title'>
> &
  NextSeoProps & {
    friendlyPath?: string
    path?: string
  }

export const Seo: React.FC<NextSeoPropsWithRequiredFields> = (props) => {
  return (
    <>
      <NextSeo {...props} />
      {typeof props?.path === 'string' && <HrefLang friendlyPath={props.friendlyPath} path={props.path} />}
      <WebPageJsonLd
        id={`${props.canonical}#webpage`}
        url={props.canonical}
        image="https://images.angelstudios.com/image/upload/v1637340036/angel-studios/logos/Angel-logo-black_4x_2.png"
        inLanguage="en"
        name={props.title}
        headline={props.title}
        description={props.description}
        isPartOf={{
          type: 'WebSite',
          id: 'https://www.angel.com/#website',
          url: 'https://www.angel.com/',
          name: 'Angel Studios',
          publisher: fullPublisher,
        }}
      />
      <ViewActionJsonLd
        keyOverride="iOS"
        target={{ type: 'EntryPoint', urlTemplate: 'ios-app://1473663873/https/www.angel.com/' }}
      />
      <ViewActionJsonLd
        keyOverride="Android"
        target={{ type: 'EntryPoint', urlTemplate: 'android-app://com.vidangel.thechosen/https/www.angel.com/' }}
      />
    </>
  )
}
