import React, { useMemo } from 'react'
import { ReactFCC } from '@/types/react'

export interface ExternalLinkProps extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  href: string
  target?: string
  trusted?: boolean
}

export enum TRUSTED_DOMAINS {
  'angel.com' = 'angel.com',
  'angelstudios.com' = 'angelstudios.com',
}

const isTrustedDomain = (href: string): boolean => {
  if (!href) {
    return false
  }

  return href.indexOf(TRUSTED_DOMAINS['angel.com']) > -1 || href.indexOf(TRUSTED_DOMAINS['angelstudios.com']) > -1
}

export const ExternalLink: ReactFCC<ExternalLinkProps> = ({ href, trusted, target = '_blank', children, ...rest }) => {
  const isTrusted = useMemo(() => trusted || isTrustedDomain(href), [href, trusted])
  if (!href) return null

  return (
    // eslint-disable-next-line react/jsx-no-target-blank
    <a href={href} target={target} rel={`external${isTrusted ? '' : ' noopener noreferrer'}`} {...rest}>
      {children}
    </a>
  )
}
