import { useEffect, useMemo, useState } from 'react'
import { DateTime, Duration } from 'luxon'
import { Durations } from '@/constants/durations'

interface Args extends CountdownOptions {
  endDate: string | undefined
}

interface UseCountdownResult {
  countdown?: Duration
  formattedCountdown: string
  isExpired: boolean
}

export const useCountDown = ({ endDate, ...opts }: Args) => {
  const end = useMemo(() => DateTime.fromJSDate(new Date(endDate || '')), [endDate])
  const [result, setResult] = useState<UseCountdownResult>(computeCountdown(end, opts))

  useEffect(() => {
    const interval = setInterval(() => {
      setResult((prev) => {
        const newResult = computeCountdown(end, opts)
        if (prev.formattedCountdown === newResult.formattedCountdown) return prev
        else return newResult
      })
    }, 1000)

    return () => clearInterval(interval)
  }, [end, opts])

  return result
}

interface CountdownOptions {
  format?: ToHumanOptions
  numUnits?: number
}

/**
 * This type is not importing correctly for some reason and is causing build errors, so created a copy.
 */
interface ToHumanOptions {
  listStyle?: 'long' | 'short' | 'narrow' | undefined
  unitDisplay?: 'long' | 'short' | 'narrow' | undefined
  roundingPriority?: 'auto' | 'morePrecision' | 'lessPrecision' | undefined
  roundingIncrement?: 1 | 2 | 5 | 10 | 20 | 25 | 50 | 100 | 200 | 250 | 500 | 1000 | 2000 | 2500 | 5000 | undefined
  roundingMode?:
    | 'ceil'
    | 'floor'
    | 'expand'
    | 'trunc'
    | 'halfCeil'
    | 'halfFloor'
    | 'halfExpand'
    | 'halfTrunc'
    | 'halfEven'
    | undefined
  trailingZeroDisplay?: 'auto' | 'stripIfInteger' | undefined
  maximumFractionDigits?: number
}

function computeCountdown(end: DateTime, options?: CountdownOptions): UseCountdownResult {
  if (!end.isValid) {
    return {
      isExpired: true,
      formattedCountdown: '',
    }
  }

  const isExpired = end <= DateTime.now()
  const numUnits = options?.numUnits || 2

  const msDiff = end.diffNow()
  const units = ['months', 'days', 'hours', 'minutes', 'seconds'] as const
  const unitsIdx =
    msDiff.milliseconds >= Durations.ONE_MONTH_IN_MILLISECONDS
      ? 0
      : msDiff.milliseconds >= Durations.ONE_DAY_IN_MILLISECONDS
      ? 1
      : msDiff.milliseconds >= Durations.ONE_HOUR_IN_MILLISECONDS
      ? 2
      : msDiff.milliseconds >= Durations.ONE_MINUTE_IN_MILLISECONDS
      ? 3
      : 4
  const unitsToUse = units.slice(unitsIdx, unitsIdx + numUnits)

  const countdown = end.diffNow(unitsToUse)

  if (!countdown.isValid) {
    return {
      isExpired: true,
      countdown,
      formattedCountdown: '',
    }
  }

  const toHumanOpts: ToHumanOptions = { maximumFractionDigits: 0, roundingMode: 'floor', ...options?.format }

  return {
    countdown,
    formattedCountdown: countdown.toHuman(toHumanOpts),
    isExpired,
  }
}
