import React, { MouseEventHandler } from 'react'
import classNames from 'classnames'
import { ExternalLink } from '@/atoms/ExternalLink'
import { InternalLink } from '@/atoms/InternalLink'
import { ReactFCC } from '@/types/react'
import { ButtonVariant, getVariantClasses } from './ButtonUtil'

export interface LinkButtonProps extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  href: string
  variant: ButtonVariant
  external?: boolean
  outline?: boolean
  disabled?: boolean
  shallow?: boolean
  prefetch?: boolean
  target?: string
  onClick?: (() => void) | MouseEventHandler<HTMLAnchorElement> | undefined
}

export const LinkButton: ReactFCC<LinkButtonProps> = ({
  href,
  onClick,
  variant,
  external,
  outline,
  disabled,
  className,
  children,
  shallow,
  prefetch = false,
  target,
  ...rest
}) => {
  const classes = classNames('btn', getVariantClasses({ variant, outline, disabled }), className, {
    'pointer-events-none': disabled,
  })

  if (external) {
    return (
      <ExternalLink
        aria-disabled={disabled}
        href={href}
        className={classes}
        {...rest}
        onClick={onClick}
        target={target}
      >
        {children}
      </ExternalLink>
    )
  }

  return (
    <InternalLink
      aria-disabled={disabled}
      onClick={onClick}
      className={classes}
      href={href}
      shallow={shallow}
      prefetch={prefetch}
      {...rest}
    >
      {children}
    </InternalLink>
  )
}
