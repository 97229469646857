import { TokenSet } from './TokenSet'

export interface Claims {
  [key: string]: unknown
}

export class Session {
  user: Claims

  /**
   * The id token.
   */
  idToken?: string | undefined

  /**
   * The access token.
   */
  accessToken?: string | undefined

  /**
   * The access token scopes.
   */
  accessTokenScope?: string | undefined

  /**
   * The expiration of the access token.
   */
  accessTokenExpiresAt?: number

  /**
   * The refresh token.
   */
  refreshToken?: string | undefined;

  [key: string]: unknown

  constructor(user: Claims) {
    this.user = user
  }
}

export function fromJson(json: { [key: string]: unknown } | undefined): Session | null {
  if (!json?.user) {
    return null
  }
  return Object.assign(new Session(json.user as Claims), json)
}

export function getClaims(idToken: string) {
  if (!idToken) {
    throw new TypeError('id_token not present in TokenSet')
  }
  return JSON.parse(Buffer.from(idToken.split('.')[1], 'base64url').toString())
}

export function fromTokenSet(tokenSet: TokenSet): Session {
  const { id_token, access_token, scope, exp, refresh_token, expires_in, expires_at, token_type, ...rest } = tokenSet

  let accessTokenExpiresAt

  if (exp) {
    accessTokenExpiresAt = exp
  } else if (expires_at) {
    accessTokenExpiresAt = expires_at
  } else if (expires_in) {
    accessTokenExpiresAt = Date.now() + expires_in * 1000
  }

  const claims = typeof tokenSet.claims === 'function' ? tokenSet.claims() : getClaims(id_token as string)

  return {
    user: {
      ...claims,
      ...rest,
    },
    idToken: id_token,
    accessToken: access_token,
    accessTokenScope: scope,
    refreshToken: refresh_token,
    accessTokenExpiresAt,
  }
}
