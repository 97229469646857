import countries from '@/constants/countries.json'
import { MAX_CHAR_CITY, MAX_CHAR_FIRST_NAME, MAX_CHAR_LAST_NAME, MAX_CHAR_STATE } from '@/services/UserService'
import { UpdateUserProfileInput } from '@/types/codegen-federation'
import { useTranslate } from '@/utils/translate/translate-client'
import { getUserDisplayName } from '@/utils/users'

export const useNameDisplay = (firstName?: string | null, lastName?: string | null): string => {
  const { t } = useTranslate('common')
  return getUserDisplayName({ profile: { firstName, lastName } }, t('anonymous', 'Anonymous'))
}

export const getLocationDisplay = (city?: string | null, state?: string | null, country?: string | null): string => {
  if (city && state && country) return `${city}, ${state}, ${country}`
  if (city && state) return `${city}, ${state}`
  if (city && country) return `${city}, ${country}`
  if (state && country) return `${state}, ${country}`
  if (city) return `${city}`
  if (state) return `${state}`
  if (country) return `${country}`
  return ''
}

export type ProfileFormProps = Pick<
  UpdateUserProfileInput,
  'firstName' | 'lastName' | 'city' | 'state' | 'country' | 'image'
>

export type ProfileFormId = keyof ProfileFormProps

export interface FormErrorProps {
  error?: boolean
  text: string
}

export function validateProfile(id: ProfileFormId, value: string): FormErrorProps {
  let error = false
  let text = ''

  switch (id) {
    case 'firstName':
      if (value?.length > MAX_CHAR_FIRST_NAME) {
        error = true
        text = `Must be less than ${MAX_CHAR_FIRST_NAME} characters`
      }
      break
    case 'lastName':
      if (value?.length > MAX_CHAR_LAST_NAME) {
        error = true
        text = `Must be less than ${MAX_CHAR_LAST_NAME} characters`
      }
      break
    case 'city':
      if (value?.length > MAX_CHAR_CITY) {
        error = true
        text = `Must be less than ${MAX_CHAR_CITY} characters`
      }
      break
    case 'state':
      if (value?.length > MAX_CHAR_STATE) {
        error = true
        text = `Must be less than ${MAX_CHAR_STATE} characters`
      }
      break
    case 'country':
      if (!countries.includes(value)) {
        error = true
        text = 'Invalid country'
      }
      break
    default:
  }

  return { error, text }
}
