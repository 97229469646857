import React from 'react'
import { getHexColor, OneColorIconProps } from '../utils'

export const TicketIcon: React.FC<OneColorIconProps> = ({ size = 20, color = '#F29F3D', className }) => {
  const fill = getHexColor(color)

  return (
    <svg
      width={size}
      height={size}
      className={className}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.588 19.413C2.97933 19.8043 3.45 20 4 20H20C20.55 20 21.021 19.8043 21.413 19.413C21.8043 19.021 22 18.55 22 18V14C21.45 14 20.979 13.804 20.587 13.412C20.1957 13.0207 20 12.55 20 12C20 11.45 20.1957 10.979 20.587 10.587C20.979 10.1957 21.45 10 22 10V6C22 5.45 21.8043 4.97933 21.413 4.588C21.021 4.196 20.55 4 20 4H4C3.45 4 2.97933 4.196 2.588 4.588C2.196 4.97933 2 5.45 2 6V10C2.55 10 3.021 10.1957 3.413 10.587C3.80433 10.979 4 11.45 4 12C4 12.55 3.80433 13.0207 3.413 13.412C3.021 13.804 2.55 14 2 14V18C2 18.55 2.196 19.021 2.588 19.413ZM20 18H10V6H20V8.55C19.3833 8.91667 18.896 9.404 18.538 10.012C18.1793 10.6207 18 11.2833 18 12C18 12.7167 18.1793 13.3793 18.538 13.988C18.896 14.596 19.3833 15.0833 20 15.45V18ZM8 6H4V8.55C4.61667 8.91667 5.10433 9.404 5.463 10.012C5.821 10.6207 6 11.2833 6 12C6 12.7167 5.821 13.3793 5.463 13.988C5.10433 14.596 4.61667 15.0833 4 15.45V18H8V6Z"
        fill={fill}
      />
    </svg>
  )
}
