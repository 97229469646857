import React, { FC, useState } from 'react'
import classNames from 'classnames'
import { useRouter } from 'next/router'
import { CloseIcon } from '@/atoms/Icons/CloseIcon'
import { MagnifyingGlassIcon } from '@/atoms/Icons/MagnifyingGlassIcon'
import { Modal } from '@/molecules/Modal'
import { RegionOption } from '@/molecules/RegionSelector/RegionOption'
import useCountrySelectorList from '@/molecules/RegionSelector/useCountrySelectorList'
import { TheatricalReleaseRegion } from '@/types/codegen-federation'
import { useTranslate } from '@/utils/translate/translate-client'

interface LanguageModalProps {
  show: boolean
  onClose: () => void
  regions: TheatricalReleaseRegion[]
  isDarkMode?: boolean
}

export const RegionModal: FC<LanguageModalProps> = ({ onClose, show, regions, isDarkMode = false }) => {
  const { t } = useTranslate('common')
  const [value, setValue] = useState<string>('')
  const { push, pathname, asPath, query } = useRouter()
  const { countries, isRegionSelected } = useCountrySelectorList({
    regions,
    inputValue: value,
  })
  const handleClose = () => {
    setValue('')
    onClose()
  }

  const handleSelectRegion = (region: string) => {
    onClose()
    const slug = query?.slug as string
    const path = pathname.includes('tickets') ? `/tickets/${slug}` : `/pay-it-forward/${slug}`
    const queryParams = asPath.split('?')[1]
    if (queryParams) return push(`${path}/${region.toUpperCase()}?${queryParams}`)
    return push(`${path}/${region}`)
  }

  return show ? (
    <div>
      <Modal
        className="z-[10000] mx-auto flex flex-col items-center justify-center !overflow-hidden"
        panelClasses="bg-white !h-4/5 !w-screen !min-w-[300px] !max-w-[350px] px-0 relative"
        isOpen={show}
        onClose={() => {
          handleClose()
        }}
        childWrapperClassName="h-full pb-12 w-full"
      >
        <div className="absolute right-4 top-4 cursor-pointer" onClick={handleClose}>
          <CloseIcon size={18} color="core-gray-800" />
        </div>
        <div
          className={classNames(
            'border border-x-transparent border-t-transparent sticky top-0 z-10',
            isDarkMode ? 'bg-core-gray-900 border-b-core-gray-950' : 'bg-white border-b-core-gray-200',
          )}
        >
          <input
            className={classNames(
              'w-full bg-transparent p-2 pl-10 text-core-gray-950 ring-0 ring-transparent py-4',
              isDarkMode ? 'placeholder:text-core-gray-400 text-core-gray-400' : 'placeholder:text-core-gray-500',
            )}
            value={value}
            placeholder={t('regionSelectorSearchPlaceholder', 'Search Location...')}
            onChange={(evt) => {
              setValue(evt.target.value)
            }}
          />
          <MagnifyingGlassIcon
            stroke={1.5}
            size={22}
            className="absolute left-3 top-1/2 -translate-y-1/2"
            color="core-gray-700"
          />
        </div>
        <div className="relative min-h-full w-full pb-10">
          <div className="absolute inset-0 overflow-scroll px-6">
            {countries.map((country) => (
              <div key={country?.countryCode} onClick={() => handleSelectRegion(country?.region as string)}>
                <RegionOption
                  isMobile
                  countryCode={country?.countryCode as string}
                  isDarkMode={isDarkMode}
                  countryName={country?.country as string}
                  selected={country?.countryCode ? isRegionSelected(country?.countryCode?.toUpperCase()) : false}
                />
              </div>
            ))}
          </div>
        </div>
      </Modal>
    </div>
  ) : null
}
