import { useMemo, useCallback } from 'react'
import { useQuery, useSuspenseQuery, useMutation } from '@apollo/client'
import {
  NotificationUpdateMutation,
  NotificationUpdateMutationVariables,
  NotificationsMarkAllAsReadMutation,
  SetInboxLastCheckedAtMutation,
  UserNotificationChannelsQuery,
  UserNotificationCountQuery,
  UserNotificationFilter,
  UserNotificationUpdateInput,
  UserNotificationsListQuery,
  UserNotificationsListQueryVariables,
  UserNotificationsQuery,
} from '@/types/codegen-federation'
import { getWebClient } from '../ApolloClient'
import {
  GET_USER_NOTIFICATIONS,
  GET_USER_NOTIFICATION_COUNT,
  GET_USER_NOTIFICATIONS_LIST,
  GET_USER_NOTIFICATION_CHANNELS,
  SET_INBOX_LAST_CHECKED_AT,
  UPDATE_NOTIFICATION,
  MARK_ALL_NOTIFICATIONS_AS_READ,
} from './queries'

export type UserNotification = {
  id: string
  cloudinaryPath: string
  deepLink: string
  message: string
  projectSlug: string
  publishDate: string
  read?: boolean
  title: string
  type: string
}

export type NotificationsCursor = {
  nextCursor: string
  notifications: [UserNotification]
  hasMore: boolean
  total: number
}

export type NotificationChannel = {
  id: string | null
  displayName: string | null
}

export const useUserNotifications = () => {
  const client = getWebClient()

  const result = useQuery<UserNotificationsQuery>(GET_USER_NOTIFICATIONS, { client })
  return useMemo(() => {
    const notifications = result?.data?.userNotifications
    return {
      notifications,
      ...result,
    }
  }, [result])
}

export const useUserNotificationsList = (queryVariations: UserNotificationFilter) => {
  const client = getWebClient()

  const { data, refetch } = useSuspenseQuery<UserNotificationsListQuery, UserNotificationsListQueryVariables>(
    GET_USER_NOTIFICATIONS_LIST,
    {
      client,
      variables: {
        input: {
          cursor: queryVariations.cursor ?? '0',
          take: queryVariations.take ?? 20,
          where: queryVariations.where ?? null,
        },
      },
      errorPolicy: 'all',
    },
  )

  const refetchList = useCallback(() => {
    refetch({
      input: {
        cursor: queryVariations.cursor ?? '0',
        take: queryVariations.take ?? 20,
        where: queryVariations.where ?? null,
      },
    })
  }, [refetch, queryVariations.cursor, queryVariations.take, queryVariations.where])

  return useMemo(() => {
    const notifications = data?.userNotificationList?.notifications
    return {
      notifications,
      refetchList,
      ...data,
    }
  }, [data, refetchList])
}

export const useUserNotificationsListTable = (queryVariations: UserNotificationFilter) => {
  const client = getWebClient()

  const { data, refetch, loading } = useQuery<UserNotificationsListQuery, UserNotificationsListQueryVariables>(
    GET_USER_NOTIFICATIONS_LIST,
    {
      client,
      variables: {
        input: {
          cursor: queryVariations.cursor ?? '0',
          take: queryVariations.take ?? 20,
          where: queryVariations.where ?? null,
        },
      },
      errorPolicy: 'all',
    },
  )

  const notifications = data?.userNotificationList?.notifications

  const nextCursor = data?.userNotificationList?.nextCursor

  const refetchNotifications = useCallback(
    (refetchInput: UserNotificationFilter) => {
      refetch({
        input: refetchInput?.where?.channelId
          ? {
              cursor: refetchInput.cursor ?? nextCursor ?? '0',
              take: queryVariations.take ?? 20,
              where: refetchInput.where ?? queryVariations.where ?? null,
            }
          : {
              cursor: refetchInput.cursor ?? nextCursor ?? '0',
              take: queryVariations.take ?? 20,
              where: queryVariations.where ?? null,
            },
      })
    },
    [refetch, nextCursor, queryVariations.take, queryVariations.where],
  )

  return useMemo(() => {
    const canFetchMore = data?.userNotificationList?.hasMore
    return {
      notifications,
      nextCursor,
      canFetchMore,
      loading,
      refetchNotifications,
      ...data,
    }
  }, [data, refetchNotifications, loading, nextCursor, notifications])
}

export const useUserNotificationsCount = () => {
  const { data, refetch } = useQuery<UserNotificationCountQuery>(GET_USER_NOTIFICATION_COUNT, {
    variables: {
      input: {
        where: { read: false },
      },
    },
    errorPolicy: 'all',
  })

  const refetchCount = useCallback(() => {
    refetch({ input: { where: { read: false } } })
  }, [refetch])

  return useMemo(() => {
    const notificationsCount = data?.userNotificationCount?.total
    return {
      notificationsCount,
      refetchCount,
      ...data,
    }
  }, [data, refetchCount])
}

export const useUserNotificationsChannels = () => {
  const result = useQuery<UserNotificationChannelsQuery>(GET_USER_NOTIFICATION_CHANNELS, { errorPolicy: 'all' })
  return useMemo(() => {
    const notificationChannels = result?.data?.userNotificationChannels
    return {
      notificationChannels,
      ...result,
    }
  }, [result])
}

export const useSetInboxLastCheckedAt = () => {
  const [mutate] = useMutation<SetInboxLastCheckedAtMutation>(SET_INBOX_LAST_CHECKED_AT)

  const setInboxLastCheckedAt = useCallback(() => {
    mutate()
  }, [mutate])

  return {
    setInboxLastCheckedAt,
  }
}

export const useUpdateNotification = () => {
  const [mutate] = useMutation<NotificationUpdateMutation, NotificationUpdateMutationVariables>(UPDATE_NOTIFICATION)

  const updateUserNotification = useCallback(
    (input: UserNotificationUpdateInput) => {
      mutate({ variables: { input } })
    },
    [mutate],
  )

  return {
    updateUserNotification,
  }
}

export const useMarkNotificationsAllAsRead = () => {
  const [mutate] = useMutation<NotificationsMarkAllAsReadMutation>(MARK_ALL_NOTIFICATIONS_AS_READ)

  const markNotificationsAllAsRead = useCallback(() => {
    mutate()
  }, [mutate])

  return {
    markNotificationsAllAsRead,
  }
}
