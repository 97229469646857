import React, { FC } from 'react'
import { TitleSM } from '@/atoms/Text'

export interface NotificationsHeaderProps {
  value: string
  isDarkMode?: boolean
}

export const NotificationsHeader: FC<NotificationsHeaderProps> = React.memo(({ isDarkMode = true, value }) => {
  return (
    <TitleSM weight="bold" color={isDarkMode ? 'gray-300' : 'gray-800'} className="whitespace-nowrap uppercase">
      {value}
    </TitleSM>
  )
})

NotificationsHeader.displayName = 'React.memo(NotificationsHeader)'
