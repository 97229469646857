import React from 'react'
import classNames from 'classnames'
import { ReactFCC } from '@/types/react'

interface PaddedContainerProps {
  className?: string
  responsiveBreakpoint?: BreakPoint | undefined
  fluid?: boolean
}

export enum BreakPoint {
  sm = 'sm',
  md = 'md',
  lg = 'lg',
  xl = 'xl',
  none = 'none',
}

export const PaddedContainer: ReactFCC<PaddedContainerProps> = ({
  children,
  className = '',
  responsiveBreakpoint,
  fluid,
}) => {
  let paddingClasses = ''

  if (responsiveBreakpoint === 'sm' || !responsiveBreakpoint) {
    paddingClasses = 'px-4 sm:px-8 md:px-12 xl:px-16'
  } else if (responsiveBreakpoint === 'md') {
    paddingClasses = 'md:px-12 xl:px-16'
  } else if (responsiveBreakpoint === 'xl') {
    paddingClasses = 'xl:px-16'
  } else if (responsiveBreakpoint === 'none') {
    paddingClasses = ''
  }

  return (
    <div
      className={classNames(
        paddingClasses,
        className,
        fluid ? 'overflow-x-hidden scrollbar-none' : 'mx-auto max-w-[1420px]',
      )}
    >
      {children}
    </div>
  )
}
