import React from 'react'
import { ApolloProvider } from '@apollo/client'
import { ThemeContextProvider } from '@/contexts/ThemeContext'
import { Analytics } from '@/layout/Analytics'
import { ErrorFallback, ErrorBoundary } from '@/layout/ErrorBoundary'
import { DefaultSeo } from '@/layout/Seo'
import { ToastProvider } from '@/molecules/Toast/ToastProvider'
import { Footer } from '@/organisms/Footer'
import { SiteNav } from '@/organisms/SiteNav'
import { getWebClient } from '@/services/ApolloClient'
import { useLocale } from '@/utils/LocaleUtil'

interface DefaultLayoutProps {
  children: React.ReactNode
  isDarkMode: boolean
}

export const HideTopNavigationLayout: React.FC<DefaultLayoutProps> = ({ children, isDarkMode }) => {
  const { locale } = useLocale()
  const client = getWebClient({ locale })

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <DefaultSeo />
      <Analytics>
        <ApolloProvider client={client}>
          <ThemeContextProvider isDarkMode={isDarkMode}>
            <ToastProvider>
              <SiteNav hideNavigation={true} />
              <ErrorBoundary FallbackComponent={ErrorFallback}>{children}</ErrorBoundary>
              <Footer />
            </ToastProvider>
          </ThemeContextProvider>
        </ApolloProvider>
      </Analytics>
    </ErrorBoundary>
  )
}
