import React, { createContext, useCallback, useEffect, useMemo, useState } from 'react'
import { formatPrice } from '@/services/PriceFormatter'
import {
  CollectibleOffer,
  CollectibleProductInput,
  CollectibleProductKeepsake,
  CollectibleProductPack,
  CollectibleProductSignature,
} from '@/types/codegen-federation'

interface CollectiblesPurchaseContextProps {
  children: React.ReactNode
}

export interface CollectibleGift {
  [key: string]: string
  firstName: string
  lastName: string
  message: string
  email: string
}

export interface CollectibleCartItem {
  offerId: string
  total?: number
  price?: number
  signerName?: string
  signerImage?: string
  shippingTier?: string
  shippingSize?: string
  products: (CollectibleCartItemProduct | CollectibleProductKeepsake | CollectibleProductSignature)[]
}

export interface CollectibleCartItemProduct extends CollectibleProductInput {
  __typename: 'CollectibleCartItemProduct'
  price?: number
}

interface CollectiblesPurchaseContextType {
  cartItems: CollectibleCartItem[]
  momentsInCart: AvailableMoment[]
  packsInCart: PackCartProduct[]
  collectibleTotal: string
  showSideCart: boolean
  onItemClick: (offerInput: CollectibleCartItem, moment?: AvailableMoment | undefined) => void
  onPackItemClick: (offerInput: CollectibleCartItem, pack: PackCartProduct) => void
  onAddItemsToCart: (items: CollectibleAddToCartItem[]) => void
  setShowSideCart: (show: boolean) => void
  removeFromCart: (momentId: number, offerId: string) => void
  removePackFromCart: (productId: string, offerId: string) => void
  resetCartState: () => void
}

export interface CollectibleAddToCartItem {
  offerInput: CollectibleCartItem
  packInput?: PackCartProduct
  momentInput?: AvailableMoment
}

interface CartActions {
  cartItems: CollectibleCartItem[]
  offerInput: CollectibleCartItem[]
}

export interface PackCartProduct {
  offerId: string
  productId: string
  name: string
  image: string
  price: number
  niftyId?: string
}

export interface AvailableMoment {
  id: string
  title: string
  type: string
  availableFrames: number
  reactionCount: number
  thumbnail: string
  startTime: number
  episode: {
    show: {
      name: string
    }
    season: {
      seasonNumber: number
    }
    episodeNumber: number
    description?: string
  }
  defaultOffer: CollectibleOffer
  description?: string
}

export const localShoppingCartKey = 'collectibleShopCartItems'
export const localMomentCartKey = 'collectibleMomentsCartItems'
export const localPackCartKey = 'collectiblePacksCartItems'

export const CollectiblesPurchaseContext = createContext<CollectiblesPurchaseContextType>({
  cartItems: [],
  momentsInCart: [],
  packsInCart: [],
  collectibleTotal: '',
  showSideCart: false,
  setShowSideCart: () => {},
  removeFromCart: () => {},
  removePackFromCart: () => {},
  onItemClick: () => {},
  onPackItemClick: () => {},
  resetCartState: () => {},
  onAddItemsToCart: () => {},
})

export const CollectiblesPurchaseContextProvider: React.FC<CollectiblesPurchaseContextProps> = ({ children }) => {
  const [cartItems, setCartItems] = useState<CollectibleCartItem[]>([])
  const [momentsInCart, setMomentsInCart] = useState<AvailableMoment[]>([])
  const [packsInCart, setPacksInCart] = useState<PackCartProduct[]>([])
  const [showSideCart, setShowSideCart] = useState(false)

  const resetCartState = useCallback(() => {
    setCartItems([])
    setMomentsInCart([])
    setPacksInCart([])
    localStorage.setItem(localShoppingCartKey, JSON.stringify([]))
    localStorage.setItem(localMomentCartKey, JSON.stringify([]))
    localStorage.setItem(localPackCartKey, JSON.stringify([]))
  }, [])

  const handleAddToCart = useCallback(
    (offerInput: CollectibleCartItem[]) => {
      const existingOfferIds = cartItems
        ?.filter((item) => offerInput.find((offer) => offer.offerId === item.offerId))
        ?.map((item) => item.offerId)

      const newCartItems = [...cartItems.filter((item) => !existingOfferIds?.includes(item.offerId)), ...offerInput]

      localStorage.setItem(localShoppingCartKey, JSON.stringify(newCartItems))
      setCartItems(newCartItems)
    },
    [cartItems],
  )

  useEffect(() => {
    const localStorageCart = typeof localStorage !== 'undefined' ? localStorage.getItem(localShoppingCartKey) : null
    const localPackCart = typeof localStorage !== 'undefined' ? localStorage.getItem(localPackCartKey) : null
    if (localStorageCart) {
      const parsedCartItems = JSON.parse(localStorageCart) as CollectibleCartItem[]
      handleAddToCart(parsedCartItems)
      if (localPackCart) {
        const parsedPackCart = JSON.parse(localPackCart) as PackCartProduct[]
        handleAddToPackInCart(parsedPackCart)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleRemoveCartItem = useCallback(
    (offerId: string) => {
      const updatedCart = removeCartItem({ offerId, cartItems })

      localStorage.setItem(localShoppingCartKey, JSON.stringify(updatedCart))
      return setCartItems(updatedCart)
    },
    [cartItems],
  )

  const removePackFromCart = useCallback(
    (productId: string, offerId: string) => {
      const updatedCart = cartItems
        .map((cartItem) => {
          if (cartItem.offerId === offerId) {
            const filteredProducts = cartItem.products.filter(
              (p) => (p as CollectibleProductInput).productId !== productId,
            )

            return filteredProducts.length > 0 ? { ...cartItem, products: filteredProducts } : null
          }

          return cartItem
        })
        .filter((item) => item !== null) as CollectibleCartItem[]

      const updatedPacks = packsInCart.filter((pack) => pack.productId !== productId)

      const updatedMoments = momentsInCart.filter((moment) => moment.defaultOffer?.id !== offerId)

      localStorage.setItem(localShoppingCartKey, JSON.stringify(updatedCart))
      localStorage.setItem(localPackCartKey, JSON.stringify(updatedPacks))
      localStorage.setItem(localMomentCartKey, JSON.stringify(updatedMoments))

      setPacksInCart(updatedPacks)
      setCartItems(updatedCart)
      setMomentsInCart(updatedMoments)

      const updatedCartItems = cartItems.filter((item) => item.offerId !== offerId)
      setCartItems(updatedCartItems)
    },
    [cartItems, packsInCart, momentsInCart],
  )

  const updateCartItem = useCallback(
    (offerInput: CollectibleCartItem) => {
      const updatedSelected = updateOfferNoteAndGiftDetails({
        cartItems,
        offerInput: [offerInput],
      })

      setCartItems(updatedSelected)
      setShowSideCart(true)
    },
    [cartItems, setCartItems],
  )

  const handleAddToMomentsInCart = useCallback(
    (moments: AvailableMoment[]) => {
      const existingMomentIds = momentsInCart
        .filter((moment) => moments?.some((m) => m.id === moment.id))
        ?.map((m) => m.id)
      const newMomentItems = [...momentsInCart.filter((item) => !existingMomentIds?.includes(item.id)), ...moments]

      localStorage.setItem(localMomentCartKey, JSON.stringify(newMomentItems))

      setMomentsInCart(newMomentItems)
      setShowSideCart(true)
    },
    [momentsInCart, setMomentsInCart, setShowSideCart],
  )

  const handleAddToPackInCart = useCallback(
    (packs: PackCartProduct[]) => {
      const existingPacksIds = packsInCart
        .filter((pack) => packs?.some((p) => p.offerId === pack.offerId))
        ?.map((p) => p.productId)

      const newPacks = [...packsInCart.filter((item) => !existingPacksIds?.includes(item.productId)), ...packs]
      localStorage.setItem(localPackCartKey, JSON.stringify(newPacks))

      setPacksInCart(newPacks)
      setShowSideCart(true)
    },
    [packsInCart],
  )

  const handleRemoveMomentInCart = useCallback(
    (momentId: number) => {
      const updatedMoments = removeMomentInCart(momentId, momentsInCart)

      setMomentsInCart(updatedMoments)
    },
    [momentsInCart],
  )

  const removeFromCart = useCallback(
    (momentId: number, offerId: string) => {
      handleRemoveCartItem(offerId)
      handleRemoveMomentInCart(momentId)
      const updatedPacks = packsInCart.filter((pack) => pack.offerId !== offerId)
      localStorage.setItem(localPackCartKey, JSON.stringify(updatedPacks))
      setPacksInCart(updatedPacks)
    },
    [handleRemoveCartItem, handleRemoveMomentInCart, packsInCart],
  )

  const onItemClick = useCallback(
    (offerInput: CollectibleCartItem, moment?: AvailableMoment) => {
      if (cartItems.some((i) => i.offerId === offerInput.offerId)) {
        updateCartItem(offerInput)
      } else {
        handleAddToCart([offerInput])
        moment && handleAddToMomentsInCart([moment])
      }
    },
    [cartItems, handleAddToCart, handleAddToMomentsInCart, updateCartItem],
  )

  const updatePacksProducts = useCallback(
    (packInput: PackCartProduct) => {
      const newCart = addNewProductToOffer({ cartItems, packInput })

      setCartItems(newCart)
      setShowSideCart(true)
    },
    [cartItems],
  )

  const onPackItemClick = useCallback(
    (offerInput: CollectibleCartItem, pack: PackCartProduct) => {
      if (cartItems.some((i) => i.offerId === offerInput.offerId)) {
        const existingCartItem = cartItems.find((i) => i.offerId === offerInput.offerId) as CollectibleCartItem
        if (!existingCartItem?.products.some((p) => (p as CollectibleProductInput).productId === pack.productId)) {
          handleAddToPackInCart([pack])
          updatePacksProducts(pack)
        } else {
          setShowSideCart(true)
        }
      } else {
        handleAddToCart([offerInput])
        handleAddToPackInCart([pack])
      }
    },
    [cartItems, handleAddToCart, handleAddToPackInCart, updatePacksProducts],
  )

  const onAddItemsToCart = useCallback(
    (items: CollectibleAddToCartItem[]) => {
      const offerInputs = items.map((item) => item.offerInput)
      //check if offer inputs need merged
      const combinedOffers: CollectibleCartItem[] = []
      offerInputs.forEach((offer) => {
        const co = combinedOffers.find((c) => c.offerId === offer.offerId)
        if (co) {
          offer.products?.forEach((p) => co.products.push(p))
        } else {
          combinedOffers.push(offer)
        }
      })

      const packInputs = items.map((item) => item.packInput)?.filter(Boolean)
      handleAddToCart(combinedOffers)
      handleAddToPackInCart(packInputs as PackCartProduct[])
      const momentInputs = items.map((item) => item.momentInput)?.filter(Boolean)
      handleAddToMomentsInCart(momentInputs as AvailableMoment[])
    },
    [handleAddToCart, handleAddToPackInCart, handleAddToMomentsInCart],
  )

  const handleCollectiblesValue = useCallback(() => {
    const total = cartItems.reduce<number>((acc, item) => {
      let subTotal = 0
      item.products?.forEach((p) => {
        if ((p as CollectibleProductPack)?.price) {
          const product = p as CollectibleProductPack
          subTotal = subTotal + (product?.price ?? 0)
        }
      })
      return acc + subTotal
    }, 0)
    return total as number
  }, [cartItems])

  const total = handleCollectiblesValue()

  const collectibleTotal = formatPrice(total, { currency: 'USD', ignoreEmptyDecimal: true })

  const value = useMemo(
    () => ({
      cartItems,
      momentsInCart,
      packsInCart,
      collectibleTotal,
      showSideCart,
      setShowSideCart,
      removeFromCart,
      removePackFromCart,
      onItemClick,
      onPackItemClick,
      resetCartState,
      onAddItemsToCart,
    }),
    [
      cartItems,
      momentsInCart,
      packsInCart,
      collectibleTotal,
      showSideCart,
      removeFromCart,
      removePackFromCart,
      onItemClick,
      onPackItemClick,
      resetCartState,
      onAddItemsToCart,
    ],
  )

  return <CollectiblesPurchaseContext.Provider value={value}>{children}</CollectiblesPurchaseContext.Provider>
}

export const useCollectiblesPurchaseContext = () => React.useContext(CollectiblesPurchaseContext)

export const updateOfferNoteAndGiftDetails = ({ cartItems, offerInput }: CartActions) => {
  const updatedSelected = cartItems.map((i) => {
    if (i.offerId === offerInput[0].offerId) {
      return {
        ...i,
        total: offerInput[0].total,
        products: offerInput[0].products,
      }
    }
    return i
  })

  return updatedSelected
}

interface PackCartActions {
  cartItems: CollectibleCartItem[]
  packInput: PackCartProduct
}

const addNewProductToOffer = ({ cartItems, packInput }: PackCartActions) => {
  const updatedCartItem = cartItems.map((i) => {
    if (i.offerId === packInput.offerId) {
      return {
        ...i,
        total: i.total! + packInput.price,
        products: [...i.products, { productId: packInput.productId }],
      }
    }
    return i
  })

  return updatedCartItem as CollectibleCartItem[]
}

export const addToCart = ({ cartItems, offerInput }: CartActions) => {
  const updatedCart = [...cartItems, ...offerInput]
  localStorage.setItem(localShoppingCartKey, JSON.stringify(updatedCart))
  return updatedCart
}

interface AddOfferProducts {
  offer: CollectibleOffer
  signatureId: string
  actorNote: string
  giftDetails: CollectibleGift
  productId: string
}

export const addOfferProducts = ({ offer, signatureId, actorNote, giftDetails, productId }: AddOfferProducts) => {
  const completedOffer = {
    offerId: offer.id,
    products: {
      productId: productId,
      niftyId: signatureId,
      signatureNote: actorNote,
      giftMeta: giftDetails,
    },
  }

  return completedOffer
}

export const removeCartItem = ({ offerId, cartItems }: { offerId: string; cartItems: CollectibleCartItem[] }) => {
  const updatedCart = cartItems.filter((i) => i.offerId !== offerId)
  localStorage.setItem(localShoppingCartKey, JSON.stringify(updatedCart))
  return updatedCart
}

export const addMomentsToCart = ({
  momentsInCart,
  moment,
}: {
  momentsInCart: AvailableMoment[]
  moment: AvailableMoment[]
}) => {
  const updatedMoments = [...momentsInCart, ...moment]
  localStorage.setItem(localMomentCartKey, JSON.stringify(updatedMoments))
  return updatedMoments
}

export const addPacksToCart = ({ packsInCart, pack }: { packsInCart: PackCartProduct[]; pack: PackCartProduct[] }) => {
  const updatedPacks = [...packsInCart, ...pack]
  localStorage.setItem(localMomentCartKey, JSON.stringify(updatedPacks))
  return updatedPacks
}

export const removeMomentInCart = (momentId: number | string, momentsInCart: AvailableMoment[]) => {
  const momentIdString = momentId.toString()
  const updatedMoments = momentsInCart.filter((m) => m.id !== momentIdString)
  localStorage.setItem(localMomentCartKey, JSON.stringify(updatedMoments))
  return updatedMoments
}
