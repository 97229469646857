import React from 'react'
import { Text, TextProps } from '@/atoms/Typography/Text'

/**
 * @deprecated Use import { ParagraphXL } from '@/atoms/Text'
 */
export function ParagraphXL({ children, ...props }: TextProps): JSX.Element {
  return (
    <Text
      as={(p) => <p {...p} />}
      fontWeight="font-medium"
      lineHeight="leading-8"
      fontSize="text-xl"
      letterSpacing="-tracking-[0.01em]"
      {...props}
    >
      {children}
    </Text>
  )
}

/**
 * @deprecated Use import { ParagraphLG } from '@/atoms/Text'
 */
export function ParagraphLG({ children, ...props }: TextProps): JSX.Element {
  return (
    <Text
      as={(p) => <p {...p} />}
      fontWeight="font-medium"
      lineHeight="leading-8"
      fontSize="text-lg"
      letterSpacing="-tracking-[0.01em]"
      {...props}
    >
      {children}
    </Text>
  )
}

/**
 * @deprecated Use an import from '@/atoms/Text'
 */
export function Paragraph({ children, ...props }: TextProps): JSX.Element {
  return (
    <Text as={(p) => <p {...p} />} fontWeight="font-normal" fontSize="text-base" {...props}>
      {children}
    </Text>
  )
}

/**
 * @deprecated Use import { ParagraphSM } from '@/atoms/Text'
 */
export function ParagraphSM({ children, ...props }: TextProps): JSX.Element {
  return (
    <Text as={(p) => <p {...p} />} fontWeight="font-normal" fontSize="text-sm" {...props}>
      {children}
    </Text>
  )
}

/**
 * @deprecated Use an import from '@/atoms/Text'
 */
export function ParagraphXS({ children, ...props }: TextProps): JSX.Element {
  return (
    <Text as={(p) => <p {...p} />} fontWeight="font-normal" fontSize="text-xs" {...props}>
      {children}
    </Text>
  )
}

/**
 * @deprecated Use an import from '@/atoms/Text'
 */
export function Paragraph2XS({ children, ...props }: TextProps): JSX.Element {
  return (
    <Text as={(p) => <p {...p} />} fontWeight="font-normal" fontSize="text-[11px]" {...props}>
      {children}
    </Text>
  )
}
