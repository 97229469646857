import React from 'react'
import { Image } from '@/atoms/Image'
import { scaleDimensions, getHexColor } from '../utils'
import { OneColorLogoProps } from './LogoTypes'

export const AngelLogo: React.FC<OneColorLogoProps> = ({ height = 32, color = 'white', className }) => {
  const { width: w, height: h } = scaleDimensions(height, 90, 32)
  const hex = getHexColor(color)
  const fill = hex.split('#')[1]
  const version = color === 'white' ? 'v1663942060' : 'v1637340049'
  const name = color === 'white' ? 'Angel-Studios-Logo-White' : 'Angel-Studios-Logo-Black'

  return (
    <Image
      width={w}
      height={h}
      src={`/e_colorize:100,co_rgb:${fill}/${version}/angel-studios/logos/${name}.svg`}
      className={className}
      alt="Angel Studios Logo"
    />
  )
}
