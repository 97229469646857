import { isDefined } from './types'

export function getItemOrFirstEntry<T>(param: T | T[]): T {
  if (Array.isArray(param)) {
    return param[0]
  } else {
    return param
  }
}

export function arrayify<T>(param: T | T[]): T[] {
  if (Array.isArray(param)) {
    return param
  } else {
    return [param]
  }
}

export function mergeArrays<T = unknown>(...arrs: (T[] | undefined)[]): T[] {
  return arrs.flat().filter(isDefined)
}
