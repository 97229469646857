/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useEffect, useMemo, useState } from 'react'

interface FetchState {
  /**
   * loading is true while a fetch request is in progress, otherwise will be false.
   */
  loading: boolean

  /**
   * `fetch()` will generate an error if the network request fails.
   * `fetch()` does not throw an error based on status code, so, for example,
   *  a 404 response will not generate an error.
   * See https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch#checking_that_the_fetch_was_successful
   */
  error: undefined | TypeError

  /**
   * The full response object returned by the `fetch()` function.
   */
  response: undefined | Response

  /**
   * The data from the response. This data is extracted from the response using
   * one of the functions built into the Response object. The format of data is
   * controlled by the ResponseResolution option.
   */
  data: undefined | any

  /**
   * Use the refetch function to make the API request again and refresh the results.
   * You may optionally pass in updated fetch params. Updating the inputs is particularly
   * useful for POST or GET requests that have body / query parameters modified based on user input.
   * This function is also useful for making requests on command, such as waiting to make
   * the request until a button is clicked.
   * When the `defer` option is used, the intial request will be skipped, and a request will not
   * be made until this refetch function is called.
   * @param input
   * @param init
   * @returns
   */
  refetch: (input?: string | URL | globalThis.Request, init?: RequestInit) => void

  /**
   * Call this function to abort a fetch request that is in progress.
   * @returns
   */
  abort: () => void
}

interface UseFetchOptions {
  /**
   * If defer is true, the request will not be made immediately. The fetch call will not be made until `refetch` is called.
   * Default value is false
   */
  defer?: boolean

  /**
   * Response resolution determines how to resolve the fetch Response object.
   * Selecting "none" will result in no processing of the response and the raw response object will be returned unresolved.
   * Default value is 'json'
   */
  responseResolution?: ResponseResolution
}

type ResponseResolution = 'arrayBuffer' | 'blob' | 'formData' | 'json' | 'text' | 'none'

function resolveResponse(response: Response, resolution: ResponseResolution): Promise<any> {
  if (resolution === 'json') return response.json()
  if (resolution === 'text') return response.text()
  if (resolution === 'formData') return response.formData()
  if (resolution === 'blob') return response.blob()
  if (resolution === 'arrayBuffer') return response.arrayBuffer()
  return Promise.resolve(undefined)
}

export const useFetchDefaultOptions: Required<UseFetchOptions> = {
  defer: false,
  responseResolution: 'json',
}

export function useFetch(
  input: string | URL | globalThis.Request,
  init?: RequestInit,
  options?: UseFetchOptions,
): FetchState {
  const [inputs, setInputs] = useState({
    input,
    init,
    abortController: new AbortController(),
    hasFetched: options?.defer ?? useFetchDefaultOptions.defer,
    responseResolution: options?.responseResolution ?? useFetchDefaultOptions.responseResolution,
  })

  const refetch = useCallback((input?: string | URL | globalThis.Request, init?: RequestInit) => {
    setInputs((prev) => ({
      input: input ?? prev.input,
      init: init ?? prev.init,
      abortController: new AbortController(),
      hasFetched: false,
      responseResolution: prev.responseResolution,
    }))
  }, [])

  const abort = useCallback(() => {
    inputs.abortController.abort()
  }, [inputs.abortController])

  const [fetchState, setFetchState] = useState<FetchState>({
    loading: !inputs.hasFetched,
    error: undefined,
    response: undefined,
    data: undefined,
    refetch,
    abort,
  })

  useEffect(() => {
    if (inputs.hasFetched) return

    const duplicateCallPrevention = setTimeout(() => {
      setInputs((prev) => ({ ...prev, hasFetched: true }))
      setFetchState((prev) => ({ ...prev, loading: true }))

      const init = inputs.init ?? {}
      init.signal = inputs.abortController.signal

      fetch(inputs.input, init)
        .then((response) =>
          resolveResponse(response, inputs.responseResolution).then((data) => {
            setFetchState((prev) => ({
              ...prev,
              loading: false,
              response,
              data,
              error: undefined,
            }))
          }),
        )
        .catch((error) =>
          setFetchState((prev) => ({
            ...prev,
            loading: false,
            response: undefined,
            data: undefined,
            error,
          })),
        )
    }, 0)

    return () => clearTimeout(duplicateCallPrevention)
  }, [inputs])

  return fetchState
}

export interface FetchJsonState<T = any> extends Omit<FetchState, 'data'> {
  json: undefined | T
}

export function useFetchJson<T = any>(
  input: string | URL | globalThis.Request,
  init?: RequestInit,
  options?: UseFetchOptions,
): FetchJsonState<T> {
  const state = useFetch(input, init, { ...options, responseResolution: 'json' })
  return useMemo(() => ({ ...state, json: state.data as T }), [state])
}

export interface FetchTextState extends Omit<FetchState, 'data'> {
  text: null | string
}

export function useFetchText(
  input: string | URL | globalThis.Request,
  init?: RequestInit,
  options?: UseFetchOptions,
): FetchTextState {
  const state = useFetch(input, init, { ...options, responseResolution: 'text' })
  return useMemo(() => ({ ...state, text: state.data }), [state])
}
