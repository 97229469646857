import React from 'react'
import { BaseIconProps, getHexColor, IconColor } from '@/atoms/utils'

interface SceneIconProps extends BaseIconProps {
  color1?: IconColor | string
  color2?: IconColor | string
}

export const SceneIcon: React.FC<SceneIconProps> = ({
  size = 16,
  className,
  color1 = 'white',
  color2 = 'gray-950',
}) => {
  const c1 = getHexColor(color1)
  const c2 = getHexColor(color2)
  return (
    <svg height={size} width={size} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 11" className={className}>
      <g id="Layer_1-2" data-name="Layer 1">
        <rect fill={c1} x=".5" y=".5" width="14" height="10" rx="1" ry="1" />
        <path
          fill={c2}
          d="m13.5,11H1.5c-.83,0-1.5-.67-1.5-1.5V1.5C0,.67.67,0,1.5,0h12c.83,0,1.5.67,1.5,1.5v8c0,.83-.67,1.5-1.5,1.5ZM1.5,1c-.28,0-.5.22-.5.5v8c0,.28.22.5.5.5h12c.28,0,.5-.22.5-.5V1.5c0-.28-.22-.5-.5-.5H1.5Z"
        />
        <g>
          <path
            fill={c2}
            d="m6.51,4.62v-1.54c0-.07.05-.1.11-.06l1.06.64.21.13,1.06.64c.06.04.06.09,0,.13l-1.06.64-.21.13-1.06.64c-.06.04-.11,0-.11-.06v-1.29Z"
          />
          <path
            fill={c2}
            d="m6.56,6.25c-.05,0-.11-.01-.15-.04-.1-.06-.16-.17-.16-.3v-2.83c0-.13.06-.24.16-.3.1-.06.23-.05.33.01l2.33,1.41c.11.06.17.17.17.29s-.06.22-.17.29l-2.33,1.41c-.06.04-.12.05-.18.05Zm-.08-.49s0,0,0,0h0Zm.28-2.35v2.18l1.8-1.09-1.8-1.09Z"
          />
        </g>
        <circle fill={c2} cx="7.5" cy="8.5" r="1" />
        <path fill={c2} d="m6,8.5c0-.13.02-.25.05-.38H0v.75h6.05c-.03-.12-.05-.24-.05-.38Z" />
        <path fill={c2} d="m9,8.5c0-.13-.02-.25-.05-.38h6.05v.75h-6.05c.03-.12.05-.24.05-.38Z" />
      </g>
    </svg>
  )
}
