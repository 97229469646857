/* eslint-disable no-console */
import { isDevelopmentEnvironment, isTestEnvironment } from '../environment-utils'
import { getConsoleLogger, getNoopLogger, isClient } from './shared'
import { Logger } from './types'

let logger: Logger

/**
 * Short for "server logger".
 * @deprecated use logger() instead.
 */
export function sLogger() {
  if (!logger) {
    logger = initLogger()
  }
  return logger
}

function initLogger(): Logger {
  const fallbackLogger = getConsoleLogger(getDefaultContext)

  if (isTestEnvironment()) {
    return getNoopLogger()
  }

  if (isDevelopmentEnvironment()) {
    return fallbackLogger
  }

  if (isClient()) {
    fallbackLogger.warn('Tried to initialize the server side logger on the client. Falling back to a console logger.')
    return fallbackLogger
  }

  // Could look into other loggers someday. The console logs are piped from Vercel into Datadog, so a console log is sufficient.
  // Fancier loggers could be added here.
  return fallbackLogger
}

function getDefaultContext() {
  // Could look into passing the req & res objects through and pulling stuff off by default, such as cookie values, user id, etc.
  return {}
}
