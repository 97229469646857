import { useEffect } from 'react'
import { useRouter } from 'next/router'
import { GA_TRACKING_ID } from '@/services/GoogleAnalytics'

const pageView = (url: URL) => {
  if (typeof window === 'undefined') return

  window.gtag('config', GA_TRACKING_ID, { page_path: url })
}

/**
 * This should only be imported in _app.js, importing it elsewhere will cause duplicate tracks per real event
 * I have separated it to its own file for cleanliness of _app.js and ease of testability
 */
export const useGoogleAnalyticsPageViews = () => {
  const router = useRouter()

  useEffect(() => {
    const handleRouteChange = (url: URL) => {
      pageView(url)
    }
    router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events])
}
