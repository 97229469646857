import React, { useMemo } from 'react'
import Link from 'next/link'
import locales from '@/constants/locales'
import { isTranslatedTheatricalPath } from '@/constants/theatricalUtils'
import { ReactFCC } from '@/types/react'
import { useLocale } from '@/utils/LocaleUtil'

export interface InternalLinkProps extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  href: string
  prefetch?: boolean
  shallow?: boolean
  scroll?: boolean
}

const localeMatcher = new RegExp(`^/(${locales.all.join('|')}).+`, 'i')

function hasJumpToAnchor(href: string) {
  return href.startsWith('#')
}

export const InternalLink: ReactFCC<InternalLinkProps> = ({
  href,
  children,
  prefetch = false,
  shallow,
  scroll,
  ...rest
}) => {
  const { locale } = useLocale()

  const linkLocale = useMemo(() => {
    if (locales.public.find((publicLocale) => publicLocale === locale)) return locale
    if (isTranslatedTheatricalPath(href, locale)) return locale
    if (locales.beta.find((publicLocale) => publicLocale === locale)) return locale

    return 'en'
  }, [locale, href])

  const linkHref = useMemo(() => {
    if (hasJumpToAnchor(href)) return href

    const isEnglishPage = linkLocale === locales.defaultLocale
    if (isEnglishPage) return href

    const isHrefRelativePath = href.startsWith('/')
    const isApi = href.startsWith('/api/')
    const doesHrefIncludeLocale = localeMatcher.test(href)

    if (isHrefRelativePath && !doesHrefIncludeLocale && !isApi) {
      return `/${linkLocale}${href}`
    }

    return href
  }, [linkLocale, href])

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const anchorProps: any = { ...rest }
  if (anchorProps.linkContext) delete anchorProps.linkContext

  if (hasJumpToAnchor(href)) {
    return (
      <a href={href} {...anchorProps}>
        {children}
      </a>
    )
  }

  return (
    // prefetch={true} is deprecated so set to undefined if true https://nextjs.org/docs/messages/prefetch-true-deprecated
    <Link
      href={linkHref}
      prefetch={prefetch ? undefined : false}
      shallow={shallow}
      passHref
      legacyBehavior
      scroll={scroll}
    >
      <a {...anchorProps}>{children}</a>
    </Link>
  )
}
