import React from 'react'
import { DefaultSeo as NextSeoDefault } from 'next-seo'

export const DEFAULT_SEO_VALUES = {
  defaultTitle: 'Watch Free Compelling Movies & TV Shows Online | Angel Studios',
  description:
    'Stream free movies & TV shows on Angel.com or the Angel app. Watch Angel Originals like The Wingfeather Saga, Dry Bar Comedy, and more.',
  openGraph: {
    type: 'website',
    url: 'https://www.angel.com',
    locale: 'en_US',
    site_name: 'Angel Studios',
    images: [
      {
        url: 'https://images.angelstudios.com/image/upload/v1709163115/angel-studios/open-graph-240228.jpg',
        width: 1200,
        height: 630,
        alt: 'Angel Studios',
      },
    ],
  },
  twitter: {
    handle: '@AngelStudiosInc',
    site: '@AngelStudiosInc',
    cardType: 'summary_large_image',
  },
  facebook: {
    appId: '208285917998450',
  },
  additionalLinkTags: [
    {
      rel: 'icon',
      href: '/favicon.ico',
    },
    {
      rel: 'apple-touch-icon',
      href: 'https://images.angelstudios.com/image/upload/w_180,h_180/v1652826230/angel-app/for-your-consideration/angel-logo.jpeg',
      sizes: '180x180',
    },
    {
      rel: 'apple-touch-icon',
      href: 'https://images.angelstudios.com/image/upload/w_192,h_192/v1652826230/angel-app/for-your-consideration/angel-logo.jpeg',
      sizes: '192x192',
    },
  ],
}

export const DefaultSeo: React.FC = () => {
  return <NextSeoDefault {...DEFAULT_SEO_VALUES} />
}
