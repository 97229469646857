/** shamelessly borrowed from next-seo **/

/* eslint-disable @typescript-eslint/no-explicit-any */
const toJson = (jsonld: any): string => {
  const updated = replaceKeywords(jsonld)
  return JSON.stringify({ '@context': 'https://schema.org', ...updated }, safeJsonLdReplacer)
}

function replaceKeywords(jsonld: any): any {
  if (typeof jsonld !== 'object') return jsonld
  if (Array.isArray(jsonld)) return jsonld
  if (jsonld === null) return jsonld

  const copy = { ...jsonld }

  if (copy.id) {
    copy['@id'] = copy.id
    delete copy.id
  }
  if (copy.type) {
    copy['@type'] = copy.type
    delete copy.type
  }

  Object.entries(copy).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      copy[key] = value.map(replaceKeywords)
    } else if (typeof value === 'object') {
      copy[key] = replaceKeywords(value)
    }
  })

  return copy
}

// Some of the code below is borrowed from react-schemaorg after the author of the package
// kindly reached out to let me know this was a better way of doing things. ❤️
// https://github.com/google/react-schemaorg/blob/main/src/json-ld.tsx#L173

type JsonValueScalar = string | boolean | number
type JsonValue = JsonValueScalar | Array<JsonValue> | { [key: string]: JsonValue }
type JsonReplacer = (_: string, value: JsonValue) => JsonValue | undefined

const ESCAPE_ENTITIES = Object.freeze({
  '&': '&amp;',
  '<': '&lt;',
  '>': '&gt;',
  '"': '&quot;',
  "'": '&apos;',
})
const ESCAPE_REGEX = new RegExp(`[${Object.keys(ESCAPE_ENTITIES).join('')}]`, 'g')
const ESCAPE_REPLACER = (t: string): string => ESCAPE_ENTITIES[t as keyof typeof ESCAPE_ENTITIES]

/**
 * A replacer for JSON.stringify to strip JSON-LD of illegal HTML entities
 * per https://www.w3.org/TR/json-ld11/#restrictions-for-contents-of-json-ld-script-elements
 */
const safeJsonLdReplacer: JsonReplacer = (_: string, value: JsonValue): JsonValue | undefined => {
  // Replace per https://www.w3.org/TR/json-ld11/#restrictions-for-contents-of-json-ld-script-elements
  // Solution from https://stackoverflow.com/a/5499821/864313
  switch (typeof value) {
    case 'object':
      // Omit null values.
      if (value === null) {
        return undefined
      }

      return value // JSON.stringify will recursively call replacer.
    case 'number':
    case 'boolean':
    case 'bigint':
      return value // These values are not risky.
    case 'string':
      return value.replace(ESCAPE_REGEX, ESCAPE_REPLACER)
    default: {
      // JSON.stringify will remove this element.
      return undefined
    }
  }
}

export default toJson
