import { NextApiRequest, NextApiResponse } from 'next'
import qs from 'query-string'
import { AUTH_JWT_COOKIE, SEGMENT_ANONYMOUS_ID_COOKIE } from '@/constants/cookies'
import { Durations } from '@/constants/durations'
import { appendCookiesToApiResponse } from '@/utils/next-api/next-response-utils'
import ClientConfig from '../ClientConfig'
import { SessionCache, TokenSet } from '../session'
import { getProtocol } from './util'

// from next-auth0 using openid-client
const CALLBACK_PROPERTIES = [
  'access_token', // 6749
  'code', // 6749
  'error', // 6749
  'error_description', // 6749
  'error_uri', // 6749
  'expires_in', // 6749
  'id_token', // Core 1.0
  'state', // 6749
  'token_type', // 6749
  'session_state', // Session Management
  'response', // JARM
]

export const handler =
  (config: ClientConfig, sessionCache: SessionCache) => async (req: NextApiRequest, res: NextApiResponse) => {
    if (req.method !== 'GET') {
      res.status(405)
      res.end()
      return
    }

    res.setHeader('Cache-Control', 'no-cache, no-store, max-age=0, must-revalidate')
    res.setHeader('Access-Control-Allow-Headers', '*')

    try {
      const params = {} as Record<string, string>

      CALLBACK_PROPERTIES.forEach((property) => {
        if (typeof req.query[property] === 'string') {
          params[property] = req.query[property] as string
        }
      })

      const protocol = getProtocol(req)

      const tokenRequest = {
        grant_type: 'authorization_code',
        client_id: config.clientId,
        client_secret: config.clientSecret,
        // this needs to match the same redirect uri provided to the /authorize endpoint, which is the callback endpoint
        redirect_uri: `${protocol}://${req.headers.host}/api/auth/callback`,
        scope: config.scope,
        ...params,
      }
      let tokenSet

      const response = await fetch(`${config.tokenUri}`, {
        method: 'POST',
        headers: {
          'content-type': 'application/x-www-form-urlencoded',
        },
        body: qs.stringify(tokenRequest),
      })

      if (response.ok) {
        const payload = await response.json()
        tokenSet = new TokenSet(payload)
        const session = sessionCache.fromTokenSet(tokenSet)
        await sessionCache.create(req, res, session)

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let state: any = {}

        if (params.state) {
          try {
            state = JSON.parse(Buffer.from(params.state, 'base64url').toString('utf-8'))
          } catch (e) {
            // eslint-disable-next-line no-console
            console.error('error decoding state from authentication callback', e)
          }
        }

        handleAppendCookies(config, payload, state.anonymousId, res)

        res.redirect(state.returnTo ?? `${protocol}://${req.headers.host}/watch`)
      } else {
        const e = {
          error: response.body,
          status: response.status,
          statusText: response.statusText,
        }

        res.status(response.status).send(e)
      }
    } catch (err) {
      res.status(500).end()
    }
  }

export default handler

function handleAppendCookies(
  config: ClientConfig,
  payload: { access_token: string; expires_in: string },
  anonymousId: string,
  res: NextApiResponse,
) {
  const security = config.session.cookie.secure ? ' Secure' : ''
  const newCookies = [`${AUTH_JWT_COOKIE}=${payload.access_token}; Path=/; Max-Age=${payload.expires_in};${security}`]

  if (anonymousId) {
    newCookies.push(`${SEGMENT_ANONYMOUS_ID_COOKIE}=${anonymousId}; Path=/; Max-Age=${Durations.TEN_YEARS_IN_SECONDS}`)
  }

  appendCookiesToApiResponse(res, newCookies)
}
