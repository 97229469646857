import React from 'react'
import { useTranslate } from '@/utils/translate/translate-client'
import { InvestLink, InvestLinkProps } from './InvestLink'

export interface AngelFundingLinkProps
  extends Omit<InvestLinkProps, 'leavingMessage' | 'leavingContinueLabel' | 'leavingStayLabel'> {
  leavingMessage?: string
  leavingContinueLabel?: string
}

export const AngelFundingLink: React.FC<AngelFundingLinkProps> = ({
  children,
  href,
  leavingMessage,
  leavingContinueLabel,
  linkContext,
  projectSlug,
  ...rest
}) => {
  const { t } = useTranslate('common')
  const message =
    leavingMessage ||
    t(
      'nowLeavingToAngelFundingWebsite',
      'You are now leaving this website to enter Angel Funding, a FINRA regulated portal.',
    )
  const continueLabel = leavingContinueLabel || t('continueToAngelFunding', 'Continue to Angel Funding')

  return (
    <InvestLink
      href={href}
      linkContext={linkContext}
      projectSlug={projectSlug}
      leavingMessage={message}
      leavingContinueLabel={continueLabel}
      leavingStayLabel={t('stay', 'Stay')}
      {...rest}
    >
      {children}
    </InvestLink>
  )
}
