import { Fragment, ReactNode } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import classNames from 'classnames'
import { When } from 'react-if'
import { getTransitionClasses } from '@/molecules/utils'
import { ReactFCC } from '@/types/react'

interface ModalProps {
  action?: ReactNode
  children: ReactNode
  className?: string
  isOpen: boolean
  onClose?: () => void
  backgroundScreenColor?: 'gray-200' | 'black'
  panelClasses?: string
  panelClassesOverride?: string
  title?: string
  transition?: 'appear' | 'slide'
  backgroundOverlayClassName?: string
  childWrapperClassName?: string
  id?: string
}

export const Modal: ReactFCC<ModalProps> = ({
  action,
  children,
  className,
  isOpen,
  onClose,
  backgroundScreenColor = 'black',
  backgroundOverlayClassName,
  panelClasses,
  panelClassesOverride,
  title,
  transition = 'appear',
  childWrapperClassName,
  id,
  ...rest
}) => {
  const transitionClasses = getTransitionClasses(transition)
  return (
    <Transition appear as={Fragment} show={isOpen}>
      <Dialog
        as="div"
        aria-labelledby={`modal-${title}`}
        aria-modal="true"
        className={classNames('fixed inset-0 z-50 overflow-y-auto', className)}
        aria-label="modal"
        onClose={onClose ?? noop}
        role="dialog"
        id={id}
        {...rest}
      >
        <div className="min-h-screen text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div
              className={classNames(
                'fixed inset-0 w-screen',
                {
                  'bg-black/75': backgroundScreenColor === 'black',
                  'bg-gray-200/75': backgroundScreenColor === 'gray-200',
                },
                backgroundOverlayClassName,
                'bg-opacity-30',
              )}
            />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span aria-hidden="true" className="inline-block h-screen align-middle">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter={transitionClasses.enter}
            enterFrom={transitionClasses.enterFrom}
            enterTo={transitionClasses.enterTo}
            leave={transitionClasses.leave}
            leaveFrom={transitionClasses.leaveFrom}
            leaveTo={transitionClasses.leaveTo}
          >
            <Dialog.Panel
              className={
                panelClassesOverride
                  ? panelClassesOverride
                  : classNames(
                      'inline-block w-full max-w-md p-6 my-8 overflow-hidden md:max-w-[600px]',
                      'text-left align-middle transition-all transform bg-white',
                      'shadow-xl rounded-2xl',
                      panelClasses,
                    )
              }
            >
              <When condition={title && title?.length > 0}>
                <Dialog.Title as="h3" className="text-2xl font-semibold leading-tight">
                  {title}
                </Dialog.Title>
              </When>
              <div className={classNames('mt-2', childWrapperClassName)}>{children}</div>
              {action && <div className="mt-2">{action}</div>}
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  )
}

function noop() {
  // do nothing
}
