import React, { FC } from 'react'
import { EyebrowMD } from '@/atoms/Text'

export interface LanguageHeaderProps {
  value: string
  isDarkMode?: boolean
}

export const LanguageHeader: FC<LanguageHeaderProps> = ({ isDarkMode = true, value }) => {
  return (
    <EyebrowMD color={isDarkMode ? 'gray-300' : 'gray-800'} className="whitespace-nowrap uppercase">
      {value}
    </EyebrowMD>
  )
}
