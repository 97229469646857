import React from 'react'
import '../styles/globals.css'
import '@splidejs/splide/dist/css/splide.min.css'
import { appWithTranslation } from 'next-i18next'
import type { AppProps } from 'next/app'
import Head from 'next/head'
import Script from 'next/script'
import { CollectiblesPurchaseContextProvider } from '@/contexts/CollectiblesPurchaseContext/CollectiblesPurchaseContextProvider'
import { useGoogleAnalyticsPageViews } from '@/layout/useGoogleAnalyticsPageViews'
import { useLoadProgressBar } from '@/layout/useLoadProgressBar'
import { NextPageWithLayout, DefaultLayout } from '@/layouts'
import { useLocale } from '@/utils/LocaleUtil'
import { isStagingEnvironment } from '@/utils/environment-utils'
import '../wdyr'

interface AppPropsWithLayout extends AppProps {
  Component: NextPageWithLayout
}

export function AngelWeb({ Component, pageProps }: AppPropsWithLayout) {
  useLoadProgressBar()
  useGoogleAnalyticsPageViews()
  const { locale } = useLocale()
  const isInContextTranslation = locale === 'ach-UG'

  const getLayout = Component.getLayout || ((page: React.ReactNode) => <DefaultLayout>{page}</DefaultLayout>)

  const layout = getLayout(
    <>
      {isStagingEnvironment() && isInContextTranslation && (
        <>
          <Script
            id="crowdin-in-context"
            type="text/javascript"
            dangerouslySetInnerHTML={{
              __html: `var _jipt = [];
  _jipt.push(['project', 'angel-localization', 'preload_texts', true]);`,
            }}
          ></Script>

          <Script type="text/javascript" src="https://cdn.crowdin.com/jipt/jipt.js" />
        </>
      )}
      <Head>
        <meta name="viewport" content="width=device-width, minimum-scale=0.5, initial-scale=1, maximum-scale=5.0" />
      </Head>
      <Component {...pageProps} />
    </>,
    pageProps,
  )

  return <CollectiblesPurchaseContextProvider>{layout}</CollectiblesPurchaseContextProvider>
}

export default appWithTranslation(AngelWeb as React.FC<AppProps>)
