/* eslint-disable @typescript-eslint/no-explicit-any */
import { cLogger } from './client-logger'
import { sLogger } from './server-logger'
import { isClient } from './shared'
import { Logger } from './types'

/**
 * A logger function that dynamically determines whether to use client-side or server-side logging.
 */
export function logger(): Logger {
  if (isClient()) return cLogger()
  else return sLogger()
}
