import React, { useEffect, useCallback } from 'react'
import { useRouter } from 'next/router'
import { Button } from '@/atoms/Button'
import { paths } from '@/constants/paths'

export interface FallbackComponentProps {
  clearError: () => void
}

export const ErrorFallback: React.FC<FallbackComponentProps> = ({ clearError }) => {
  useEffect(() => {
    window?.scrollTo?.(0, 0)
  }, [])

  const { push } = useRouter()

  const goHome = useCallback(() => {
    return push(paths.home).then(clearError)
  }, [push, clearError])

  return (
    <div className="w-full bg-core-gray-950">
      <div className="mx-auto py-48 text-center text-white md:max-w-[600px]">
        <h2 className="mb-4 text-3xl font-semibold">Sorry about that.</h2>
        <div className="leading-6">
          <div className="mb-4">
            Something seems to have gone terribly wrong!
            <div>
              Rest assured our best developers have been notified and will hopefully prevent this from happening in the
              future.
            </div>
          </div>
          <div className="mb-12">
            In the meantime you can try to reset the error below.
            <div>Hopefully it won&apos;t happen again, but if it persists try going Home instead.</div>
          </div>
        </div>
        <div className="flex w-full justify-center">
          <Button className="mr-2" variant="white" onClick={clearError}>
            Reset Error
          </Button>
          <Button variant="white" outline onClick={goHome}>
            Go Home
          </Button>
        </div>
      </div>
    </div>
  )
}
