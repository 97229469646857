import React, { useEffect, useMemo, useState } from 'react'
import { OptimizelyProvider as Provider, ReactSDKClient } from '@optimizely/react-sdk'
import { UserInfo } from '@optimizely/react-sdk/dist/utils'
import { ReactFCC } from '@/types/react'

const OptimizelyContext = React.createContext({})

interface OptimizelyProviderProps {
  children: React.ReactNode
  user?: UserInfo | Promise<UserInfo> | undefined
  optimizely: ReactSDKClient
}

export const OptimizelyContextProvider: ReactFCC<OptimizelyProviderProps> = ({ children, optimizely, user }) => {
  const [isOptimizelyReady, setIsOptimizelyReady] = useState(false)

  useEffect(() => {
    if (!isOptimizelyReady) {
      optimizely
        .onReady()
        .then(() => {
          setIsOptimizelyReady(true)
        })
        .catch()
    }
  }, [isOptimizelyReady, optimizely])

  const value = useMemo(() => {
    return {
      optimizely,
      isOptimizelyReady,
      user,
    }
  }, [optimizely, user, isOptimizelyReady])

  return (
    <OptimizelyContext.Provider value={value}>
      <Provider optimizely={optimizely} user={user}>
        {children}
      </Provider>
    </OptimizelyContext.Provider>
  )
}

interface UseOptimizelyContextState {
  optimizely: ReactSDKClient
  isOptimizelyReady: boolean
  user: UserInfo | Promise<UserInfo> | undefined
}

export const useOptimizelyContext = (): UseOptimizelyContextState => {
  const state = React.useContext(OptimizelyContext)

  if (typeof state === 'undefined') {
    throw new Error('useOptimizelyContext must be used within an OptimizelyContextProvider!')
  }

  return state as UseOptimizelyContextState
}
