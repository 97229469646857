/* eslint-disable @typescript-eslint/no-explicit-any */

/* eslint-disable no-console */
import type { Logger } from './types'

export function isClient() {
  return typeof window !== 'undefined'
}

export function isContextAnError(context: any) {
  return context instanceof Error || (context && !!context.stack && !!context.message)
}

export function getConsoleLogger(getDefaultContext: () => Record<string, any>): Logger {
  const logToConsole = (
    logStatus: 'debug' | 'info' | 'warn' | 'error',
    message: string,
    context?: any,
    error?: Error,
  ) => {
    const c = { ...getDefaultContext(), ...context }
    if (error) console[logStatus](message, c, error)
    else if (isContextAnError(context)) console[logStatus](message, getDefaultContext(), context)
    else console[logStatus](message, c)
  }

  return {
    debug(msg, context, error) {
      logToConsole('debug', msg, context, error)
    },
    info(msg, context, error) {
      logToConsole('info', msg, context, error)
    },
    warn(msg, context, error) {
      logToConsole('warn', msg, context, error)
    },
    error(msg, context, error) {
      logToConsole('error', msg, context, error)
    },
    type: 'console',
  }
}

export function getNoopLogger(): Logger {
  return {
    debug() {},
    info() {},
    warn() {},
    error() {},
    type: 'noop',
  }
}
