import React from 'react'
import { getHexColor, OneColorIconProps } from '../utils'

export const CheckIcon: React.FC<OneColorIconProps> = ({ className, color = 'white', size = 16 }) => {
  return (
    <svg className={className} width={size} height={size} viewBox="0 0 17 17" fill="none" version="1.1">
      <path
        d="m 4.4404098,8.6693093 2.54951,2.7088547"
        stroke={getHexColor(color)}
        strokeWidth="2.06155"
        strokeLinecap="square"
      />
      <path
        d="M 7.0403798,11.432075 12.55959,5.5679248"
        stroke={getHexColor(color)}
        strokeWidth="2.06155"
        strokeLinecap="square"
      />
    </svg>
  )
}
