import React from 'react'
import { ApolloProvider } from '@apollo/client'
import classNames from 'classnames'
import { useRouter } from 'next/router'
import { AccountContextProvider } from '@/contexts/AccountContext'
import { ThemeContextProvider } from '@/contexts/ThemeContext'
import { Analytics } from '@/layout/Analytics'
import { ErrorBoundary, ErrorFallback } from '@/layout/ErrorBoundary'
import { DefaultSeo } from '@/layout/Seo'
import { LanguagesProvider } from '@/molecules/LanguageSelector'
import { NotificationsProvider } from '@/molecules/Notifications'
import { SaleBanner } from '@/molecules/SaleBanner'
import { ToastProvider } from '@/molecules/Toast/ToastProvider'
import { Footer } from '@/organisms/Footer'
import { SiteNav } from '@/organisms/SiteNav'
import { getWebClient } from '@/services/ApolloClient'
import { useLocale } from '@/utils/LocaleUtil'

interface NotificationsLayoutProps {
  children: React.ReactNode
}

export const NotificationsLayout: React.FC<NotificationsLayoutProps> = ({ children }) => {
  const { locale } = useLocale()
  const router = useRouter()
  const client = getWebClient({ locale })
  const isDarkMode = router.query.isDarkMode ? JSON.parse(router.query.isDarkMode as string) : false

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <DefaultSeo />
      <ApolloProvider client={client}>
        <LanguagesProvider>
          <NotificationsProvider>
            <AccountContextProvider>
              <Analytics>
                <ThemeContextProvider isDarkMode={isDarkMode}>
                  <ToastProvider>
                    <SiteNav />
                    <SaleBanner />
                    <ErrorBoundary FallbackComponent={ErrorFallback}>
                      <div
                        className={classNames('min-h-screen', {
                          'bg-core-gray-950 text-white': isDarkMode,
                          'bg-core-gray-100 text-black': !isDarkMode,
                        })}
                      >
                        {children}
                      </div>
                    </ErrorBoundary>
                    <Footer />
                  </ToastProvider>
                </ThemeContextProvider>
              </Analytics>
            </AccountContextProvider>
          </NotificationsProvider>
        </LanguagesProvider>
      </ApolloProvider>
    </ErrorBoundary>
  )
}
