import { projectTypes } from '@/constants/projectTypes'
import { slugs } from '@/constants/slugs'
import { ProjectMeta } from '@/services/ProjectsService'
import { useTranslate } from '@/utils/translate/translate-client'

const EXCLUDE_FROM_START_WATCHING = new Set([slugs.forYourConsideration, slugs.insideAngelStudios, slugs.theChosen])

const EXCLUDE_FROM_OUR_SHOWS = new Set([
  slugs.aWeekAwaySeries,
  slugs.axiom,
  slugs.beyondTheMedal,
  slugs.bonhoeffer,
  slugs.braveTheDark,
  slugs.fellowship,
  slugs.gabrielAndTheGuardians,
  slugs.homestead,
  slugs.jacob,
  slugs.liveNotByLies,
  slugs.possumTrot,
  slugs.quitters,
  slugs.redemptionOfHenryMyers,
  slugs.sevenDaysInUtopia,
  slugs.standingUp,
  slugs.telegram,
  slugs.tempest,
  slugs.testamentSeries,
  slugs.the1916Project,
  slugs.theChosen,
  slugs.theGermanKing,
  slugs.toEndAllWars,
  slugs.truthAndConviction,
  slugs.youngWashington,
])

const TRAILER_ONLY_PROJECT = new Set([slugs.cabrini])

// Some projects have "Episodes" that are actually teaser trailers, and we don't want those to show up in the Start Watching carousel
export const isProjectExcludedFromStartWatching = (slug: string): boolean => {
  return EXCLUDE_FROM_START_WATCHING.has(slug)
}

export const isProjectExcludedFromOurShows = (slug: string): boolean => {
  return EXCLUDE_FROM_OUR_SHOWS.has(slug)
}

const IS_ANGEL_BEHIND_THE_SCENES = new Set([slugs.forYourConsideration, slugs.insideAngelStudios])

// These projects use the Season/Episode structure but they are actually behind the scenes videos, teasers and torches. We don't want to do things like show Attribution for these, and we want to build meta tags correctly as well
export const isAngelBehindTheScenes = (slug: string): boolean => {
  return IS_ANGEL_BEHIND_THE_SCENES.has(slug)
}

export const isTrailerOnlyProject = (slug: string): boolean => {
  return TRAILER_ONLY_PROJECT.has(slug)
}

export const useProjectMetaTitle = ({ name, projectType, slug }: ProjectMeta) => {
  const { t } = useTranslate('watch')

  let title =
    projectType === projectTypes.movie
      ? t('watchMovieOnlineForFree', `{{title}} | Watch Movie Online For Free | Angel Studios`, { title: name })
      : t('watchShowOnlineForFree', `{{title}} | Watch Online For Free | Angel Studios`, { title: name })

  if (
    slug === slugs.afterDeath ||
    slug === slugs.cabrini ||
    slug === slugs.homesteadFamilySurvival ||
    slug === slugs.possumTrot ||
    slug === slugs.sight ||
    slug === slugs.somethingToStandForWithMikeRowe ||
    slug === slugs.soundOfFreedom ||
    slug === slugs.soundOfHopeTheStoryOfPossumTrot ||
    slug === slugs.surprisedByOxford ||
    slug === slugs.sweetwater ||
    slug === slugs.theShift ||
    slug === slugs.theWay ||
    slug === slugs.twentyThreeBlast
  ) {
    title = t('earlyAccessStreamingTitle', `{{title}} | Now Streaming for Angel Guild Members`, { title: name })
  }

  if (slug === slugs.lostOnAMountainInMaine) {
    title = t('inTheatersDateTitlev1', '{{title}} | In Theaters {{date}}', {
      title: name,
      date: 'Nov 1st 2024',
    })
  }

  if (slug === slugs.bonhoeffer) {
    title = t('inTheatersDateTitle', '{{title}} | Coming to Theaters {{date}}', {
      title: name,
      date: 'Nov 22nd 2024',
    })
  }

  if (slug === slugs.homestead) {
    title = t('inTheatersDateTitle', '{{title}} | Coming to Theaters {{date}}', {
      title: name,
      date: 'Dec 20th 2024',
    })
  }

  if (slug === slugs.insideAngelStudios) {
    title = name
  }

  return { title }
}

export const getIsTheatrical = (slug: string) => {
  return slug === slugs.soundOfFreedom
}
