import { proxyServiceURLOrigin } from './resolveUrlsConfig'

export function angelWebTheatricalProdSegmentSnippet(
  writeKey: string,
  loadOptions: {
    integrations?: { 'Segment.io'?: { apiHost?: string } }
  } = {},
) {
  return /* js */ `!function(){
    try {
      // ! reset segment analytics if it exists from the main thread
      if (typeof window !== 'undefined' && window.webpackChunk_segment_analytics_next && window.webpackChunk_segment_analytics_next.length) {
        window.webpackChunk_segment_analytics_next = undefined;
        window.analytics = undefined;
      }
      // ! reset facebook pixel if it exists from the main thread
      if (typeof window !== 'undefined' && window.webpackJsonp_name_Integration && window.webpackJsonp_name_Integration.length) {
        window.webpackJsonp_name_Integration = undefined;
        window.fbq = undefined;
        window._fbq = undefined;
      }
      
      // start of segment snippet from https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/quickstart/#step-1-copy-the-snippet
      var analytics=window.analytics=window.analytics||[];
      if(!analytics.initialize)
      if(analytics.invoked){return;window.console&&console.error&&console.error("Segment snippet included twice.");}else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","screen","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware","register"];analytics.factory=function(e){return function(){if(window.analytics.initialized)return window.analytics[e].apply(window.analytics,arguments);var i=Array.prototype.slice.call(arguments);if(["track","screen","alias","group","page","identify"].indexOf(e)>-1){var c=document.querySelector("link[rel='canonical']");i.push({__t:"bpc",c:c&&c.getAttribute("href")||void 0,p:location.pathname,u:location.href,s:location.search,t:document.title,r:document.referrer})}i.unshift(e);analytics.push(i);return analytics}};for(var i=0;i<analytics.methods.length;i++){var key=analytics.methods[i];analytics[key]=analytics.factory(key)}analytics.load=function(key,i){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="${`${proxyServiceURLOrigin}/segment/analytics.js/v1/`}" + key + "/analytics.min.js";document.head.appendChild(t);analytics._loadOptions=i};
        analytics._writeKey="${writeKey}";
        analytics.SNIPPET_VERSION="5.2.0";
        analytics.load("${writeKey}", JSON.parse('${JSON.stringify(loadOptions).split("'").join("\\'")}'));

      // end of segment snippet from https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/quickstart/#step-1-copy-the-snippet
        
        function runOnMainThread({ innerHtml = '', type = 'text/javascript', id = 'run-on-main-thread'} = {} ){const script = document.createElement('script');script.id = id;script.type = type;script.innerHTML = innerHtml;document.head.appendChild(script);};
        runOnMainThread({ id: 'angel-web-theatrical-prod-segment-done' });
      }
    } catch (error) {
      console.error('angelWebTheatricalProdSegmentSnippet error', error)
    }
}();`
}
