import { IncomingMessage } from 'http'

export type QueryParams = Record<string, string | string[] | undefined>

export type ServerRequest = IncomingMessage & {
  cookies: Partial<{
    [key: string]: string
  }>
}

export type NonNullableFields<T> = {
  [P in keyof T]-?: NonNullable<T[P]>
}

/**
 * Useful alternative to .filter(Boolean) that is type safe
 */
export function isDefined<T>(value: T | null | undefined): value is NonNullable<T> {
  return value !== null && value !== undefined
}

export function isString(value: unknown): value is string {
  return typeof value === 'string'
}

export type Result<T, E = Error> = { ok: true; value: T | undefined } | { ok: false; error: E }
