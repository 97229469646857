import { IncomingMessage, ServerResponse } from 'http'
import { NextApiRequest, NextApiResponse } from 'next'
import qs from 'query-string'
import ClientConfig from '@/auth/ClientConfig'
import { fromTokenSet, SessionCache } from '@/auth/session'
import { Durations } from '@/constants/durations'

export interface GetAccessTokenResult {
  expires?: number
  accessToken?: string
}

export type GetAccessToken = (
  req: IncomingMessage | NextApiRequest,
  res: ServerResponse | NextApiResponse,
  accessTokenOptions?: {
    refresh?: boolean
  },
) => Promise<GetAccessTokenResult>

export function accessTokenFactory(config: ClientConfig, sessionCache: SessionCache): GetAccessToken {
  return async (req, res, accessTokenOptions): Promise<GetAccessTokenResult> => {
    const session = await sessionCache.get(req, res)

    if (!session) {
      throw new AccessTokenError(
        'invalid_session',
        `The user does not have a valid session. Auth Cookie:${req?.headers?.cookie}`,
      )
    }

    if (!session.accessToken && !session.refreshToken) {
      throw new AccessTokenError('invalid_session', 'The user does not have a valid access token.')
    }

    if (!session.accessTokenExpiresAt) {
      throw new AccessTokenError(
        'access_token_expired',
        'Expiration information for the access token is not available. The user will need to sign in again.',
      )
    }

    const isTokenExpiringSoon = session.accessTokenExpiresAt - Durations.ONE_MINUTE_IN_SECONDS < Date.now() / 1000

    // Check if the token has expired.
    // There is an edge case where we might have some clock skew where our code assumes the token is still valid.
    // Adding a skew of 1 minute to compensate.
    if (!session.refreshToken && isTokenExpiringSoon) {
      throw new AccessTokenError(
        'access_token_expired',
        'The access token expired and a refresh token is not available. The user will need to sign in again.',
      )
    }

    // Check if the token has expired.
    // There is an edge case where we might have some clock skew where our code assumes the token is still valid.
    // Adding a skew of 1 minute to compensate.
    const shouldRefresh = session.refreshToken && (isTokenExpiringSoon || accessTokenOptions?.refresh)
    if (shouldRefresh) {
      const refreshBody = qs.stringify({
        grant_type: 'refresh_token',
        client_id: config.clientId,
        client_secret: config.clientSecret,
        scope: config.scope,
        refresh_token: session.refreshToken,
      })

      const response = await fetch(`${config.tokenUri}`, {
        method: 'POST',
        headers: {
          'content-type': 'application/x-www-form-urlencoded',
        },
        body: refreshBody,
      })

      if (response.ok) {
        const tokenSet = await response.json()
        const newSession = fromTokenSet(tokenSet)
        const mergedSession = Object.assign({}, session, {
          ...newSession,
          refreshToken: newSession.refreshToken || session.refreshToken,
          user: { ...session.user, ...newSession.user },
        })

        await sessionCache.set(req, res, mergedSession)

        return {
          accessToken: mergedSession.accessToken,
          expires: mergedSession.accessTokenExpiresAt,
        }
      } else {
        throw new AccessTokenError('unable_to_refresh', 'Unable to refresh the accessToken')
      }
    }

    // We don't have an access token.
    if (!session.accessToken) {
      throw new AccessTokenError('invalid_session', 'The user does not have a valid access token.')
    }

    // The access token is not expired and has sufficient scopes;
    return {
      accessToken: session.accessToken,
      expires: session.accessTokenExpiresAt,
    }
  }
}

export class AccessTokenError extends Error {
  public code: string

  /* istanbul ignore next */
  constructor(code: string, message: string) {
    super(message)

    // Saving class name in the property of our custom error as a shortcut.
    this.name = this.constructor.name

    // Capturing stack trace, excluding constructor call from it.
    Error.captureStackTrace(this, this.constructor)

    // Machine readable code.
    this.code = code
    Object.setPrototypeOf(this, AccessTokenError.prototype)
  }
}
