import dynamic, { DynamicOptions } from 'next/dynamic'
import { LanguageMenuProps } from '@/molecules/RegionSelector/RegionSelectorComponent'

export const RegionSelector = dynamic(
  import('./RegionSelectorComponent')
    .then((mod) => mod.RegionSelectorComponent)
    .catch((err) =>
      // eslint-disable-next-line no-console
      console.error(`Failed to load the RegionSelector!`, err),
    ) as DynamicOptions<LanguageMenuProps>,
  { ssr: false },
)
