import React, { FC, useState, useMemo, useCallback } from 'react'
import { Listbox } from '@headlessui/react'
import classNames from 'classnames'
import { useRouter } from 'next/router'
import { CheckIcon } from '@/atoms/Icons/CheckIcon'
import { DeleteIcon } from '@/atoms/Icons/DeleteIcon'
import { GuildIcon } from '@/atoms/Icons/GuildIcon'
import { MoreIcon } from '@/atoms/Icons/MoreIcon'
import { SceneIcon } from '@/atoms/Icons/SceneIcon'
import { TicketIcon } from '@/atoms/Icons/TicketIcon'
import { VideoCameraIcon } from '@/atoms/Icons/VideoCameraIcon'
import { Image } from '@/atoms/Image'
import { RelativeTimeDisplay } from '@/atoms/RelativeTimeDisplay'
import { CaptionSM, TitleXS, TitleXXS } from '@/atoms/Text'
import { OneColorIconProps, TwoColorIconProps } from '@/atoms/utils'
import { useUpdateNotification } from '@/services/NotificationsService'
import { UserNotification } from '@/types/codegen-federation'
import { getCloudinaryImageUrl } from '@/utils/Cloudinary'
import { useSafeTrack } from '@/utils/analytics'
import { useTranslate } from '@/utils/translate/translate-client'

const returnHoverClasses = (isDarkMode: boolean | undefined, isNotificationUnread: boolean) => {
  return isDarkMode && isNotificationUnread
    ? 'bg-black hover:bg-[#1A1A1A]'
    : isDarkMode
    ? 'bg-core-gray-950 hover:bg-[#1A1A1A]'
    : isNotificationUnread
    ? 'bg-gray-100 hover:bg-[#E1E1E0]'
    : 'bg-white hover:bg-[#E1E1E0]'
}

const returnTitleClasses = (isDarkMode: boolean | undefined, isNotificationUnread: boolean) => {
  return isDarkMode && isNotificationUnread
    ? 'text-white'
    : isDarkMode
    ? 'text-gray-300'
    : isNotificationUnread
    ? 'text-black'
    : 'text-gray-800'
}

const returnModalClasses = (isDarkMode: boolean | undefined) => {
  return isDarkMode ? 'bg-core-gray-950' : 'bg-white text-black'
}

const returnIconColor = (isDarkMode: boolean | undefined) => {
  return isDarkMode ? 'gray-950' : 'white'
}

interface NotificationsOptionProps {
  isDarkMode?: boolean
  table?: boolean
  selected: boolean
  notification: UserNotification
  isUnread?: boolean
  handleUpdateNotificationOption?: (id: string, read: boolean) => void
  handleDeleteNotificationOption?: (id: string) => void
}

const FALLBACK_IMG_PATH =
  'https://images.angelstudios.com/image/upload/r_15/v1692208055/angel-notifications-display-images/img-thumb-reveal_2x.webp'

export const NotificationsOption: FC<NotificationsOptionProps> = React.memo(
  ({
    isDarkMode,
    notification,
    table = false,
    isUnread = true,
    handleUpdateNotificationOption,
    handleDeleteNotificationOption,
  }) => {
    const [isUnreadOpen, setIsUnreadOpen] = useState(false)
    const [isNotificationUnread, setIsNotificationUnread] = useState(isUnread)
    const [isImageError, setImageError] = useState<boolean>(false)
    const { t } = useTranslate('common')
    const track = useSafeTrack()
    const router = useRouter()
    const { updateUserNotification } = useUpdateNotification()
    const hoverClasses = returnHoverClasses(isDarkMode, isNotificationUnread)
    const titleClasses = returnTitleClasses(isDarkMode, isNotificationUnread)
    const modalClasses = returnModalClasses(isDarkMode)
    const iconColor = returnIconColor(isDarkMode)

    const NOTIFICATION_CHANNEL_TABS: {
      [key: string]: { Icon: FC<OneColorIconProps | TwoColorIconProps> }
    } = {
      livestream: {
        Icon: VideoCameraIcon,
      },
      tickets: {
        Icon: TicketIcon,
      },
      guild: {
        Icon: GuildIcon,
      },
      coll: {
        Icon: SceneIcon,
      },
    }
    const notificationType = notification?.type ?? 'Collectibles'
    const NotificationIcon = NOTIFICATION_CHANNEL_TABS[notificationType.toLowerCase()]?.Icon
    const notificationPublishDate = new Date(parseInt(notification.publishDate as string))

    const markNotificationAsRead = useCallback(
      (event: React.MouseEvent<HTMLDivElement>) => {
        event.stopPropagation()
        updateUserNotification({ id: notification.id, read: isNotificationUnread })
        setIsUnreadOpen(false)
        setIsNotificationUnread(!isNotificationUnread)
        handleUpdateNotificationOption && handleUpdateNotificationOption(notification.id, isNotificationUnread)
      },
      [
        updateUserNotification,
        setIsUnreadOpen,
        setIsNotificationUnread,
        handleUpdateNotificationOption,
        isNotificationUnread,
        notification.id,
      ],
    )

    const handleNotificationClick = useCallback(
      (e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault()
        if (!notification.read) {
          markNotificationAsRead(e)
        }
        track('Notification Deeplink Clicked', {
          title: notification.title,
          notificationId: notification.id,
          deepLink: notification.deepLink,
        })
        router.push(notification.deepLink ?? '')
      },
      [
        markNotificationAsRead,
        notification.deepLink,
        router,
        track,
        notification.id,
        notification.title,
        notification.read,
      ],
    )

    const handleOnChange = useCallback(() => {
      setIsUnreadOpen(false)
    }, [setIsUnreadOpen])

    const handleModalClick = useCallback(
      (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation()
        updateUserNotification({ id: notification.id, read: true })
        setIsUnreadOpen(!isUnreadOpen)
      },
      [updateUserNotification, isUnreadOpen, notification.id],
    )

    const deleteNotification = useCallback(
      (event: React.MouseEvent<HTMLDivElement>) => {
        event.stopPropagation()
        setIsUnreadOpen(false)
        updateUserNotification({ id: notification.id, isDeleted: true })
        handleDeleteNotificationOption && handleDeleteNotificationOption(notification.id)
      },
      [handleDeleteNotificationOption, updateUserNotification, notification.id],
    )

    const unreadText = useMemo(() => {
      return isNotificationUnread ? t('markAsRead', 'Mark As Read') : t('markAsUnread', 'Mark As Unread')
    }, [t, isNotificationUnread])

    const imagePath = notification.cloudinaryPath ?? FALLBACK_IMG_PATH

    return (
      <div
        key={notification.id}
        className={classNames(
          'group-notification-option',
          'relative flex items-center group py-4 sm:py-2 w-full cursor-pointer duration-300 ease-in-out',
          hoverClasses,
        )}
        onClick={handleNotificationClick}
      >
        <span className={classNames('mx-2 h-2 w-2 rounded-full shrink-0', { 'bg-[#F45B3C]': isNotificationUnread })} />
        <div className={`min-h-12 w-12 shrink-0`}>
          {notification.cloudinaryPath && (
            <Image
              src={isImageError ? FALLBACK_IMG_PATH : imagePath}
              width={48}
              height={48}
              onError={() => {
                setImageError(true)
              }}
              alt={notification.message ?? 'message icon'}
            />
          )}
        </div>
        <div className="mx-3 grow">
          <div className="flex flex-row items-center">
            {notification.channel?.iconCloudinaryPath ? (
              <div className="relative h-[11px] w-[13px] shrink-0 grow-0">
                <Image
                  src={getCloudinaryImageUrl({
                    path: notification.channel.iconCloudinaryPath,
                    color: 'A4A09E',
                  })}
                  fill
                  alt={notification.title ?? ''}
                />
              </div>
            ) : (
              <NotificationIcon size={11} color={'gray-500'} fill={true} color1={'gray-500'} color2={iconColor} />
            )}
            <CaptionSM className="ml-1 text-gray-500">
              {notification.channel?.displayName ?? notification.title}
            </CaptionSM>
          </div>
          <div>
            <TitleXXS className={classNames('', titleClasses)}>{notification.message}</TitleXXS>
          </div>
          <div>
            <RelativeTimeDisplay date={notificationPublishDate}>
              {(relativeTime) => <CaptionSM className="text-gray-500 first-letter:uppercase">{relativeTime}</CaptionSM>}
            </RelativeTimeDisplay>
          </div>
        </div>
        {table && (
          <Listbox as="div" onChange={handleOnChange} value={isUnreadOpen} aria-label="Mark as read">
            <Listbox.Button onClick={handleModalClick}>
              <div className="absolute right-3 top-4 transition duration-300 ease-in-out group-notification-option-hover:block hover:opacity-70 sm:top-2 sm:hidden">
                <MoreIcon size={24} color={'gray-600'} vertical={true} onClick={handleOnChange} />
              </div>
            </Listbox.Button>
            {isUnreadOpen && (
              <>
                <div
                  className={classNames(
                    'absolute z-40 sm:z-30 top-12 sm:top-10 right-2 rounded-lg min-w-[96vw] sm:min-w-[332px] flex flex-col justify-center px-4 py-2',
                    modalClasses,
                  )}
                  style={{ boxShadow: '0px -4px 16px -1px rgba(0, 0, 0, 0.05), 0px 8px 16px -1px rgba(0, 0, 0, 0.12)' }}
                >
                  <div className="flex flex-row items-center p-2" onClick={markNotificationAsRead}>
                    <CheckIcon size={24} color={isDarkMode ? 'white' : 'black'} />
                    <TitleXS className="ml-2">{unreadText}</TitleXS>
                  </div>
                  <div className="flex flex-row items-center p-2" onClick={deleteNotification}>
                    <DeleteIcon size={24} color={isDarkMode ? 'white' : 'black'} />
                    <TitleXS className="ml-2">{t('delete', 'Delete')}</TitleXS>
                  </div>
                </div>
              </>
            )}
          </Listbox>
        )}
      </div>
    )
  },
)

NotificationsOption.displayName = 'React.memo(NotificationsOption)'
