import dynamic, { DynamicOptions } from 'next/dynamic'
import { cLogger } from '@/utils/logging/client-logger'

export const UpdatedLegalDocsModal = dynamic(
  import('./UpdatedLegalDocsModal')
    .then((mod) => mod.UpdatedLegalDocsModal)
    .catch((err) => cLogger().error('Failed to load the UpdatedLegalDocsModal!', err)) as DynamicOptions,
  {
    ssr: false,
  },
)
