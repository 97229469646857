export function getStringOrEmptyString(maybeStr: string | undefined | null): string {
  return typeof maybeStr === 'string' ? maybeStr : ''
}

export function getValidString(arg: unknown): string | null {
  return typeof arg === 'string' && !!arg ? arg : null
}

export function getValidStrings(arg: unknown): string[] {
  if (!Array.isArray(arg)) return []

  return arg.map(getValidString).filter(Boolean) as string[]
}
