import NextLegacyImage, { ImageProps as NextImageProps, ImageLoaderProps } from 'next/legacy/image'

export function cloudinaryLoader({ src, width, quality }: ImageLoaderProps): string {
  const params = ['c_limit', 'w_' + width, 'q_' + (quality || 'auto')]
  return `https://images.angelstudios.com/image/upload/${params.join(',')}${src}`
}

const remoteImageLoader = ({ src }: ImageLoaderProps): string => {
  return src
}

type CustomNextImageProps = Omit<NextImageProps, 'key'> & {
  key?: string | number | null | undefined
}

/**
 * @deprecated Use import { Image } from '@/atoms/Image'
 */
export const LegacyImage = ({ src, ...props }: CustomNextImageProps): JSX.Element | null => {
  if (!src) return null

  const isSrcString = typeof src === 'string'
  if (!isSrcString) {
    return <NextLegacyImage src={src} {...props} />
  }
  const isFullyQualified = src.startsWith('http://') || src.startsWith('https://')
  const hasForwardSlash = src.startsWith('/')
  const shouldAddForwardSlash = !isFullyQualified && !hasForwardSlash
  const internalSrc = shouldAddForwardSlash ? `/${src}` : src
  const loader = isFullyQualified ? remoteImageLoader : cloudinaryLoader

  return <NextLegacyImage src={internalSrc} loader={loader} {...props} />
}
