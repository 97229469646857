import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  defaultDataIdFromObject,
  HttpLink,
  InMemoryCache,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { relayStylePagination } from '@apollo/client/utilities'
import Bugsnag from '@bugsnag/js'
import memoize from 'lodash/memoize'
import locales from '@/constants/locales'
import { analytics } from '@/layout/Analytics/Analytics'
import {
  AdditionalHeaders,
  FEDERATION_CLIENT_NAME,
  FEDERATION_CLIENT_VERSION,
  BaseClientConstructor,
} from '@/services/ApolloClient/utils'
import { getClientAccessToken } from '@/services/OAuthService'
import { getRID } from '@/utils/RIDUtil'
import { isProductionEnvironment } from '@/utils/environment-utils'
import generatedPossibleTypes from './generated-possibleTypes.json'

const webAuthLink = setContext((_, { headers }) => {
  return getClientAccessToken().then((token) => {
    const additionalHeaders: AdditionalHeaders = {}
    additionalHeaders['X-RID'] = getRID() as string
    additionalHeaders['X-PLATFORM'] = FEDERATION_CLIENT_NAME

    if (token) {
      additionalHeaders.Authorization = token
    }

    return {
      headers: {
        ...headers,
        ...additionalHeaders,
      },
    }
  })
})

const deviceIdLink = setContext((_, { headers }) => {
  const {
    user: { anonymousId },
  } = analytics.getState()

  return {
    headers: {
      ...headers,
      'X-DID': anonymousId,
      'X-SEGMENT-ANON-ID': anonymousId,
      'X-PLATFORM': FEDERATION_CLIENT_NAME,
      'X-ANGEL-APP-NAME': FEDERATION_CLIENT_NAME,
      'X-ANGEL-APP-VERSION': FEDERATION_CLIENT_VERSION,
    },
  }
})

export const hydraLocaleLink = (region = 'US', locale = 'en-US') => {
  return setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        'X-Accept-Language': locale,
        'X-Region': region,
      },
    }
  })
}

const CONTENTFUL_LOCALE_MAP = {
  es: 'es-419',
  fr: 'fr',
  'zh-TW': 'zh-Hant',
} as Record<string, string>

const contentfulLocaleLink = (locale = 'en') => {
  return new ApolloLink((operation, forward) => {
    operation.variables = { ...operation.variables, locale: CONTENTFUL_LOCALE_MAP[locale] ?? locale }
    return forward(operation)
  })
}

const contentfulLocaleFallback = onError(({ graphQLErrors, operation, forward }) => {
  if (graphQLErrors) {
    // Retry the request with a different locale if the original request fails
    graphQLErrors.forEach(({ message, locations, path }) => {
      Bugsnag.notify(
        {
          name: `[GraphQL error]: ${message}`,
          message: `Message: ${message}, Location: ${JSON.stringify(locations)}, Path: ${path}`,
        },
        (errorEvent) => {
          errorEvent.addMetadata('graphql', {
            operationName: operation.operationName,
            body: operation.query.loc?.source.body,
            variables: operation.variables,
          })
        },
      )
    })
    const originalVariables = operation.variables
    const originalLocale = originalVariables.locale

    const isNotUsableLocale = !locales.all.includes(originalLocale) || !originalLocale?.startsWith('en')
    const newLocale = isNotUsableLocale ? 'en' : originalLocale
    operation.variables = { ...originalVariables, locale: newLocale }

    return forward(operation)
  }
})

const getMemoizedWebClient = memoize(
  (opts: BaseClientConstructor) => constructWebClient(opts),
  ({ baseUrl, version, locale, name, region }) => `${baseUrl}_${locale}_${version}_${name}_${region}`,
)

const constructWebClient = ({ baseUrl, version, name, region, locale }: BaseClientConstructor) => {
  const backend = createHttpLink({ uri: `${baseUrl}/graphql` })

  return new ApolloClient({
    ssrMode: typeof window === 'undefined', // set to true for SSR
    link: ApolloLink.from([webAuthLink, hydraLocaleLink(region, locale), deviceIdLink, backend]),
    cache: new InMemoryCache({
      possibleTypes: generatedPossibleTypes,
      typePolicies: {
        Query: {
          fields: {
            reservations: relayStylePagination(['input']),
          },
        },
        Content: {
          keyFields: (object) => `Content:${object.id}`,
        },
        ComingSoonTagGroup: {
          fields: {
            connection: relayStylePagination(['preview']),
          },
        },
      },
    }),
    name: name ?? FEDERATION_CLIENT_NAME,
    version: version ?? FEDERATION_CLIENT_VERSION,
  })
}

const createContentfulClient = memoize(
  ({ baseUrl, locale }: BaseClientConstructor) => {
    const backend = createHttpLink({ uri: baseUrl })
    return new ApolloClient({
      ssrMode: typeof window === 'undefined',
      link: ApolloLink.from([contentfulLocaleLink(locale), contentfulLocaleFallback, backend]),
      cache: new InMemoryCache({
        dataIdFromObject(responseObject) {
          const resp = responseObject as { sys?: { id?: string } }
          if (resp?.sys?.id) {
            return resp.sys.id as string
          }
          return defaultDataIdFromObject(responseObject)
        },
      }),
    })
  },
  ({ baseUrl, name, version, locale }: BaseClientConstructor) => `${baseUrl}_${name}_${version}_${locale}`,
)

export const getShopifyClient = memoize(() => {
  return new ApolloClient({
    link: new HttpLink({
      uri: `https://${process.env.NEXT_PUBLIC_SHOPIFY_DOMAIN}/api/2024-10/graphql.json`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-SDK-Variant': 'storefront-api-client',
        'X-SDK-Version': '1.0.2',
        'X-Shopify-Storefront-Access-Token': process.env.NEXT_PUBLIC_SHOPIFY_STOREFRONT_ACCESS_TOKEN as string,
      },
    }),
    cache: new InMemoryCache(),
  })
})

export interface WebClientConfig {
  locale?: string
  region?: string
  baseUrl?: string
}

export const getWebClient = (config?: WebClientConfig) => {
  return getMemoizedWebClient({
    baseUrl: config?.baseUrl ?? (process.env.NEXT_PUBLIC_HYDRA as string),
    locale: config?.locale,
    region: config?.region,
    name: FEDERATION_CLIENT_NAME,
    version: FEDERATION_CLIENT_VERSION,
  })
}

export const createWebClient = (config?: WebClientConfig) => {
  return constructWebClient({
    baseUrl: config?.baseUrl ?? (process.env.NEXT_PUBLIC_HYDRA as string),
    locale: config?.locale,
    region: config?.region,
    name: FEDERATION_CLIENT_NAME,
    version: FEDERATION_CLIENT_VERSION,
  })
}

export interface ContentfulClientOptions {
  locale?: string
  preview?: boolean
  region?: string
}

export const getSharedContentfulClient = (options?: ContentfulClientOptions): ApolloClient<object> => {
  const { locale } = options ?? {}

  return createContentfulClient({
    baseUrl: `https://graphql.contentful.com/content/v1/spaces/${process.env.NEXT_PUBLIC_SHARED_CONTENTFUL_SPACE_ID}?access_token=${process.env.NEXT_PUBLIC_SHARED_CONTENTFUL_ACCESS_TOKEN}`,
    locale: locale ?? 'en',
  })
}

export const getContentfulClient = (options?: ContentfulClientOptions): ApolloClient<object> => {
  const { preview, locale } = options ?? {}

  return createContentfulClient({
    baseUrl:
      (preview ? process.env.NEXT_PUBLIC_CONTENTFUL_PROXY_PREVIEW_URL : process.env.NEXT_PUBLIC_CONTENTFUL_PROXY_URL) ??
      'https://api.angelstudios.com/cms/web',
    locale: locale ?? 'en',
  })
}

/* Can be used to test backend changes with the UI */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const getLocalHostClient = (config?: WebClientConfig) => {
  if (isProductionEnvironment()) return

  return getMemoizedWebClient({
    baseUrl: process.env.NEXT_PUBLIC_LOCAL_GRAPHQL as string,
    locale: config?.locale,
    name: FEDERATION_CLIENT_NAME,
    version: FEDERATION_CLIENT_VERSION,
  })
}

// Useful for debugging Apollo requests
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const logRequestLink = new ApolloLink((operation, forward) => {
  const { operationName, variables, getContext } = operation
  const context = getContext()
  // eslint-disable-next-line no-console
  console.log('graph request', { operationName, variables, headers: context.headers })
  return forward(operation)
})
