import classNames from 'classnames'
import { Button } from '@/atoms/Button'
import { AsLabel, TitleXS } from '@/atoms/Text'
import { SubmitProps } from './types'

export const Submit: React.FC<SubmitProps> = ({
  className = '',
  disabled = false,
  labelCancel = 'Cancel',
  labelSubmit = 'Submit',
  loading = false,
  onCancel,
  onSubmit,
}) => {
  return (
    <div className={classNames('flex flex-col gap-2 md:flex-row-reverse', className)}>
      <Button className="h-12" disabled={disabled} onClick={onSubmit} type="button" variant="black">
        <TitleXS
          weight="bold"
          as={AsLabel}
          className={classNames('mx-auto cursor-pointer', { 'cursor-not-allowed': disabled, 'cursor-wait': loading })}
          color="white"
        >
          {labelSubmit}
        </TitleXS>
      </Button>
      <Button className="h-12" onClick={onCancel} type="button" variant="lightgray">
        <TitleXS className="mx-auto cursor-pointer" weight="bold" as={AsLabel} color="core-gray-950">
          {labelCancel}
        </TitleXS>
      </Button>
    </div>
  )
}
