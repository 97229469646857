import React from 'react'
import { getHexColor, OneColorIconProps } from '../utils'

export const CloseIcon: React.FC<OneColorIconProps> = ({ color = 'gray-600', className, size }) => {
  return (
    <svg className={className} width={size} height={size} viewBox="0 0 24 24" fill="none">
      <path
        d="M18.75 5.25L5.25 18.75"
        stroke={getHexColor(color)}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.75 18.75L5.25 5.25"
        stroke={getHexColor(color)}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
