import { getCookie } from 'cookies-next'
import { EVERFLOW_TRANSACTION_ID_COOKIE } from '@/constants/cookies'
import { reportErrorToBugsnag } from '@/utils/bugsnag'
import { analytics } from '../Analytics'

const EF_TRANSACTION_ID_LOCAL_STORAGE = '_ef_transaction_id' as const
const EF_TRANSACTION_ID_QUERY_PARAM = '_ef_transaction_id' as const

const logger: {
  log: typeof console.log
  debug: typeof console.debug
  error: (error?: Error | null | unknown, message?: string) => void
} = {
  debug: (...args) => {
    if (!process.env.DEBUG) return
    // eslint-disable-next-line no-console
    console.debug(...args)
  },
  log: (...args) => {
    // eslint-disable-next-line no-console
    console.log(...args)
  },
  error: (error, message) => {
    reportErrorToBugsnag({
      ...(typeof error === 'object' ? error : {}),
      name: `AngelPartytownError ${(error instanceof Error && error?.name) || 'Unknown Error'}}`,
      message: [error instanceof Error && error?.message, message].filter(Boolean).join(' '),
      stack: (error instanceof Error && error?.stack) || '',
    })
  },
}
type AnalyticsEventParams = {
  payload: {
    properties: {
      _ef_transaction_id?: string
    }
  }
}

function getEverflowTransactionId() {
  logger.debug('Everflow getEverflowTransactionId')
  if (typeof window === 'undefined') return
  try {
    const { searchParams } = new URL(window.location.href, window.location.origin)
    const paramsEfTransactionId = searchParams.get(EF_TRANSACTION_ID_QUERY_PARAM)
    if (paramsEfTransactionId) {
      localStorage.setItem(EF_TRANSACTION_ID_LOCAL_STORAGE, paramsEfTransactionId)
      return paramsEfTransactionId
    }

    const cookieEfTransactionId = getCookie(EVERFLOW_TRANSACTION_ID_COOKIE)
    if (typeof cookieEfTransactionId === 'string' && cookieEfTransactionId) {
      localStorage.setItem(EF_TRANSACTION_ID_LOCAL_STORAGE, cookieEfTransactionId)
      return cookieEfTransactionId
    }

    const localStorageEfTransactionId = localStorage.getItem(EF_TRANSACTION_ID_LOCAL_STORAGE)
    if (localStorageEfTransactionId) {
      return localStorageEfTransactionId
    }

    return analytics?.user()?.traits?._ef_transaction_id || undefined
  } catch (error) {
    return
  }
}

function attachEverflow(params: AnalyticsEventParams) {
  let _ef_transaction_id = params.payload.properties._ef_transaction_id

  if (!_ef_transaction_id) {
    _ef_transaction_id = getEverflowTransactionId()
  }

  logger.debug('_ef_transaction_id', _ef_transaction_id)
  if (_ef_transaction_id) {
    params.payload.properties._ef_transaction_id = _ef_transaction_id
  }
}

export function everflowSegmentPlugin() {
  return {
    name: 'everflow',

    page() {
      try {
        const _ef_transaction_id = getEverflowTransactionId()
        logger.debug('Everflow plugin page', _ef_transaction_id)
        if (_ef_transaction_id && _ef_transaction_id !== analytics?.user()?.traits?._ef_transaction_id) {
          analytics.identify(analytics?.user()?.userId, { _ef_transaction_id })
        }
      } catch (error) {
        logger.error(error, 'Everflow page error')
      }
    },

    pageStart(params: AnalyticsEventParams) {
      try {
        logger.debug('Everflow plugin pageStart', params)
        attachEverflow(params)
      } catch (error) {
        logger.error(error, 'Everflow pageStart error')
      }
    },

    trackStart(params: AnalyticsEventParams) {
      try {
        logger.debug('Everflow plugin trackStart', params)
        attachEverflow(params)
      } catch (error) {
        logger.error(error, 'Everflow trackStart error')
      }
    },

    loaded() {
      logger.debug('Everflow plugin loaded')
      return true
    },
  }
}
