import React, { ChangeEventHandler, RefObject, useCallback, useRef, useState } from 'react'
import { ApolloQueryResult, OperationVariables } from '@apollo/client'
import isEqual from 'lodash/isEqual'
import AvatarEditor from 'react-avatar-editor'
import { DropEvent, FileRejection } from 'react-dropzone'
import { GuildUser, useGuildUser } from '@/services/GuildUserService'
import { useUserProfile, UserProfile, useUpdateUserProfile } from '@/services/UserService'
import { UserGuildMembershipQuery } from '@/types/codegen-federation'
import { ReactFCC } from '@/types/react'
import { FormErrorProps, ProfileFormId, ProfileFormProps, validateProfile } from './utils'

interface AccountContextProps {
  children: React.ReactNode
}

interface AccountContextType
  extends Pick<GuildUser, 'hasGuildMembership' | 'isGuildMember' | 'isSubscribedToPifOrGuild'> {
  avatarEditor: RefObject<AvatarEditor> | null
  closeProfileForm: () => void
  userId?: string
  email?: string
  errors: Record<string, FormErrorProps>
  handleChange: ChangeEventHandler<HTMLInputElement>
  handleChangeCountry: (value: string) => void
  handleChangeState: (value: string) => void
  handleDrop: <T extends File>(acceptedFiles: T[], fileRejections: FileRejection[], event: DropEvent) => void
  handleModalCancel: () => void
  handleModalSubmit: () => void
  handleSubmitProfile: () => void
  image?: string | File | null
  isSubmitAvatarDisabled: boolean
  isSubmitProfileDisabled: boolean
  isLoading: boolean
  isLoadingUserProfile: boolean
  isSubmitting: boolean
  openAvatarModal: () => void
  openProfileForm: () => void
  payload?: ProfileFormProps
  profile?: {
    firstName?: string | null
    lastName?: string | null
    image?: string | null
    city?: string | null
    state?: string | null
    country?: string | null
    isChosenInvestorSceneClaimed?: boolean
    isChosenPifferSceneClaimed?: boolean
    isChosenSubscriberSceneClaimed?: boolean
    isHosTicketSceneClaimed?: boolean
  }
  showProfileForm: boolean
  showAvatarModal: boolean
  uploadingAvatar: boolean
  refetchGuildUser: (
    variables?: Partial<OperationVariables>,
  ) => Promise<ApolloQueryResult<UserGuildMembershipQuery> | undefined>
  refetchUserContext: () => Promise<
    [ApolloQueryResult<UserProfile> | undefined, ApolloQueryResult<UserGuildMembershipQuery> | undefined]
  >
}

const INITIAL_FORM_ERRORS: Record<ProfileFormId, FormErrorProps> = {
  firstName: { error: false, text: '' },
  lastName: { error: false, text: '' },
  city: { error: false, text: '' },
  state: { error: false, text: '' },
  country: { error: false, text: '' },
  image: { error: false, text: '' },
}

function getFormData(profile?: ProfileFormProps): ProfileFormProps {
  return {
    firstName: profile?.firstName ?? '',
    lastName: profile?.lastName ?? '',
    city: profile?.city ?? '',
    state: profile?.state ?? '',
    country: profile?.country,
  }
}

const AccountContext = React.createContext<AccountContextType>({
  avatarEditor: null,
  closeProfileForm: () => null,
  userId: '',
  email: '',
  errors: INITIAL_FORM_ERRORS,
  handleChange: () => null,
  handleChangeCountry: () => null,
  handleChangeState: () => null,
  handleDrop: () => null,
  handleModalCancel: () => null,
  handleModalSubmit: () => null,
  handleSubmitProfile: () => null,
  hasGuildMembership: false,
  image: undefined,
  isGuildMember: false,
  isSubscribedToPifOrGuild: false,
  isSubmitAvatarDisabled: true,
  isSubmitProfileDisabled: true,
  isLoading: false,
  isLoadingUserProfile: false,
  isSubmitting: false,
  payload: undefined,
  profile: undefined,
  openAvatarModal: () => null,
  openProfileForm: () => null,
  showProfileForm: false,
  showAvatarModal: false,
  uploadingAvatar: false,
  refetchGuildUser: () => Promise.resolve(undefined),
  refetchUserContext: () => Promise.resolve([undefined, undefined]),
})

export const AccountContextProvider: ReactFCC<AccountContextProps> = ({ children }) => {
  const {
    loading: isLoadingUserProfile,
    data,
    refetch: refetchUserProfile,
  } = useUserProfile({ nextFetchPolicy: 'no-cache' })

  const { updateUserProfile, updatedUser } = useUpdateUserProfile()

  const user = data?.user
  const profile = updatedUser || user?.profile
  const {
    loading: isLoadingGuildUser,
    isGuildMember,
    isSubscribedToPifOrGuild,
    hasGuildMembership,
    refetch: refetchGuildUser,
  } = useGuildUser()

  const refetchUserContext = useCallback(() => {
    return Promise.all([refetchUserProfile(), refetchGuildUser()] as const)
  }, [refetchUserProfile, refetchGuildUser])

  const [showProfileForm, setShowProfileForm] = useState(false)
  const [showAvatarModal, setShowAvatarModal] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isSubmitProfileDisabled, setIsSubmitProfileDisabled] = useState(true)
  const [isSubmitAvatarDisabled, setIsSubmitAvatarDisabled] = useState(true)
  const [errors, setErrors] = useState<Record<string, FormErrorProps>>(INITIAL_FORM_ERRORS)
  const [payload, setPayload] = useState(getFormData())
  const [image, setImage] = useState<File | string | null | undefined>(profile?.image)
  const [uploadingAvatar, setUploadingAvatar] = useState(false)

  const avatarEditor = useRef<AvatarEditor>(null)

  const closeProfileForm = () => {
    setIsSubmitting(false)
    setShowProfileForm(false)
  }

  const openAvatarModal = () => {
    setUploadingAvatar(false)
    setIsSubmitAvatarDisabled(true)
    setImage(profile?.image)
    setShowAvatarModal(true)
  }

  const openProfileForm = useCallback(() => {
    setErrors(INITIAL_FORM_ERRORS)
    setPayload(getFormData(profile))
    setIsSubmitProfileDisabled(true)
    setShowProfileForm(true)
  }, [profile])

  const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const { id, value } = e.target
    const newErrors = { ...errors, [id]: validateProfile(id as ProfileFormId, value) }
    setPayload({ ...payload, [id]: value })
    setErrors(newErrors)
    setIsSubmitProfileDisabled(Object.keys(newErrors).some((k) => errors[k].error) || isEqual(payload, profile))
  }

  const handleChangeCountry = (value: string) => {
    const newErrors = { ...errors, country: validateProfile('country', value) }
    setPayload({ ...payload, country: value, state: value === 'The United States' ? payload.state : '' })
    setErrors(newErrors)
    setIsSubmitProfileDisabled(Object.keys(newErrors).some((k) => errors[k].error) || isEqual(payload, profile))
  }

  const handleChangeState = (value: string) => {
    const newErrors = { ...errors, state: validateProfile('state', value) }
    setPayload({ ...payload, state: value })
    setErrors(newErrors)
    setIsSubmitProfileDisabled(Object.keys(newErrors).some((k) => errors[k].error) || isEqual(payload, profile))
  }

  const handleSubmitProfile = async () => {
    setIsSubmitting(true)
    await updateUserProfile(payload)
    closeProfileForm()
  }

  const handleDrop = (dropped: File[]) => {
    setImage(dropped[0])
    setIsSubmitAvatarDisabled(false)
  }

  const handleModalCancel = () => {
    setShowAvatarModal(false)
    setUploadingAvatar(false)
  }

  const handleModalSubmit = async () => {
    setUploadingAvatar(true)
    if (image !== profile?.image) {
      const image = avatarEditor?.current?.getImageScaledToCanvas().toDataURL().split(',')[1]
      await updateUserProfile({ image })
      setShowAvatarModal(false)
    } else {
      setShowAvatarModal(false)
    }
  }

  const value = {
    avatarEditor,
    closeProfileForm,
    userId: user?.uuid,
    email: user?.email,
    errors,
    handleChange,
    handleChangeCountry,
    handleChangeState,
    handleDrop,
    handleModalCancel,
    handleModalSubmit,
    handleSubmitProfile,
    hasGuildMembership,
    image,
    isGuildMember,
    isSubscribedToPifOrGuild,
    isSubmitAvatarDisabled,
    isSubmitProfileDisabled,
    isLoading: isLoadingUserProfile || isLoadingGuildUser,
    isLoadingUserProfile,
    isSubmitting,
    openAvatarModal,
    openProfileForm,
    payload,
    profile,
    showProfileForm,
    showAvatarModal,
    uploadingAvatar,
    refetchGuildUser,
    refetchUserContext,
  }

  return <AccountContext.Provider value={value}>{children}</AccountContext.Provider>
}

export function useAccountContext() {
  return React.useContext(AccountContext)
}
