import { Button } from '@/atoms/Button'
import { ConfirmProps } from './types'

export const Confirm: React.FC<ConfirmProps> = ({
  disabled = false,
  label = 'Confirm',
  onConfirm,
  buttonClassName,
}) => {
  return (
    <div className="flex flex-row-reverse gap-2">
      <Button disabled={disabled} onClick={onConfirm} type="button" variant="oxide" className={buttonClassName}>
        {label}
      </Button>
    </div>
  )
}
