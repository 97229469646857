/* istanbul ignore file */

/**
 * If we ever are able to convert this to be a ts file we should
 * build the type in @/util/LocaleUtil from typeof locales['all'][number]
 */

const defaultLocale = 'en'
const publicLocales = [defaultLocale, 'es', 'pt-BR', 'zh-CN']
if (process.env.NEXT_PUBLIC_ANGEL_ENV === 'staging') publicLocales.push('ach-UG')
const beta = ['it', 'ja']
const alpha = []
const theatricalLocales = [
  'de',
  'es-ES',
  'fr',
  'el',
  'hr',
  'hu',
  'id',
  'ja',
  'ko',
  'mn',
  'pl',
  'pt',
  'no',
  'nl',
  'da',
  'fi',
  'sv',
  'zh-Hant',
]

const display = {
  'ach-UG': 'In Context Translations',
  da: 'Dansk',
  de: 'Deutsch',
  en: 'English',
  es: 'Español',
  'es-ES': 'Español(España)',
  fr: 'Français',
  el: 'Ελληνικά',
  hr: 'Hrvatski',
  hu: 'Magyar',
  id: 'Bahasa Indonesia',
  ja: '日本語',
  it: 'Italiano',
  ko: '한국어',
  mn: 'Монгол',
  nl: 'Nederlands',
  no: 'Norsk',
  pl: 'Polski',
  pt: 'Português',
  'pt-BR': 'Português(Brasil)',
  fi: 'Suomi',
  sv: 'Svenska',
  'zh-CN': '简体中文',
  'zh-Hant': '繁體中文',
}

// https://stripe.com/docs/js/appendix/supported_locales
const stripeLocales = {
  da: 'da',
  de: 'de',
  en: 'en',
  es: 'es-419',
  'es-ES': 'es',
  fi: 'fi',
  fr: 'fr',
  el: 'el',
  hr: 'hr',
  hu: 'hu',
  id: 'id',
  it: 'it',
  ko: 'ko',
  nl: 'nl',
  no: 'nb',
  pl: 'pl',
  pt: 'pt',
  'pt-BR': 'pt-BR',
  sv: 'sv',
  'zh-Hant': 'zh-TW',
}

const allSet = new Set([...publicLocales, ...beta, ...alpha, ...theatricalLocales])

module.exports = {
  allSet,
  all: [...new Set([...publicLocales, ...beta, ...alpha, ...theatricalLocales])],
  alpha,
  beta,
  defaultLocale,
  display,
  public: publicLocales,
  stripeLocales,
  theatricalLocales,
}
