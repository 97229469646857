import React from 'react'
import classNames from 'classnames'
import { ButtonVariant, getVariantClasses } from './ButtonUtil'

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant: ButtonVariant
  outline?: boolean
}

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ variant, outline, className, ...props }, ref) => {
    return (
      <button
        {...props}
        ref={ref}
        className={
          variant === 'plain'
            ? className
            : classNames('btn', getVariantClasses({ variant, outline, disabled: props.disabled }), className)
        }
      >
        {props.children}
      </button>
    )
  },
)

Button.displayName = 'Button'
