import { CookieSerializeOptions, serialize } from 'cookie'
import { getCookies } from 'cookies-next'
import { IncomingMessage, ServerResponse } from 'http'
import { CompactEncrypt, compactDecrypt, CompactDecryptResult } from 'jose'
import { deriveKey } from '@/auth/session/KeyGenerator'
import { reportErrorToBugsnag } from '@/utils/bugsnag'
import ClientConfig from '../ClientConfig'
import { CookieUtil } from './CookieUtil'

const epoch = () => (Date.now() / 1000) | 0

const cookieUtil = new CookieUtil()
const alg = 'dir'
const enc = 'A256GCM'

export class CookieStore {
  config: ClientConfig
  chunkSize: number
  key: Buffer

  constructor(config: ClientConfig) {
    this.config = config
    const emptyCookie = serialize(config.session.name + '.0', '', {
      expires: !config.session.cookie.transient ? new Date() : undefined,
      ...config.session.cookie,
    })
    this.chunkSize = 4096 - emptyCookie.length
    this.key = deriveKey(config.secret)
  }

  async read(req: IncomingMessage): Promise<{ json?: Record<string, unknown>; iat?: number }> {
    const cookies = getCookies({ req })

    const session = this.config.session
    const sessionName = session.name
    let existingSessionValue
    try {
      if (sessionName in cookies) {
        // get JWE from unchunked session cookie
        existingSessionValue = cookies[sessionName]
      } else if (sessionName + '.0' in cookies) {
        // get JWE from chunked session cookie
        // iterate all cookie names
        // match and filter for the ones that match sessionName.<number>
        // sort by chunk index
        // concat
        existingSessionValue = Object.entries(cookies)
          .map(([cookie, value]) => {
            const match = cookie.match('^' + sessionName + '\\.(\\d+)$')
            if (match) {
              return [match[1], value]
            }
            return null
          })
          .filter((value) => value !== null)
          .sort((valueA, valueB) => {
            const a = valueA?.[0] ?? ''
            const b = valueB?.[0] ?? ''
            return parseInt(a, 10) - parseInt(b, 10)
          })
          .map((cookie) => {
            return cookie?.[1]
          })
          .join('')
      }
      if (existingSessionValue) {
        const { plaintext, protectedHeader } = await this.decrypt(existingSessionValue)
        return { json: JSON.parse(plaintext.toString()), iat: protectedHeader.iat as number }
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err)
    }
    return {}
  }

  forgetUser(req: IncomingMessage, res: ServerResponse) {
    const cookies = getCookies({ req })
    Object.keys(cookies).forEach((cookieName) => {
      cookieUtil.clear(cookieName, res)
    })
  }

  clearSessionCookies(req: IncomingMessage, res: ServerResponse) {
    const { name: sessionName } = this.config.session
    const cookies = getCookies({ req })
    Object.keys(cookies).forEach((cookieName) => {
      if (cookieName.match('^' + sessionName + '\\.\\d$')) {
        cookieUtil.clear(cookieName, res)
      }
    })
  }

  async save(
    req: IncomingMessage,
    res: ServerResponse,
    session: { [key: string]: unknown } | undefined | null,
    createdAt?: number,
  ): Promise<void> {
    const { name: sessionName, ...sessionConfig } = this.config.session
    const { transient, ...cookieConfig } = sessionConfig.cookie
    if (!session) {
      this.clearSessionCookies(req, res)
      return
    }
    const uat = epoch()
    const iat = typeof createdAt === 'number' ? createdAt : uat
    const exp = this.calculateExp(iat, uat)
    const cookieOptions = { ...cookieConfig } as CookieSerializeOptions
    if (!transient) {
      cookieOptions.expires = new Date(exp * 1000)
    }
    const value = await this.encrypt(JSON.stringify(session), { iat, uat, exp })
    const chunkCount = Math.ceil(value.length / this.chunkSize)

    const cookies = getCookies({ req })

    if (chunkCount > 1) {
      for (let i = 0; i < chunkCount; i++) {
        const chunkValue = value.slice(i * this.chunkSize, (i + 1) * this.chunkSize)
        const chunkCookieName = sessionName + '.' + i
        cookieUtil.save(chunkCookieName, res, { value: chunkValue })
      }
      if (sessionName in cookies) {
        cookieUtil.clear(sessionName, res)
      }
    } else {
      cookieUtil.save(sessionName, res, { value })
      try {
        this.clearSessionCookies(req, res)
      } catch (error) {
        reportErrorToBugsnag(`Unable to save cookie: ${sessionName} ${error}`)
      }
    }
  }

  private calculateExp(iat: number, uat: number) {
    const { absoluteDuration, rollingDuration } = this.config.session

    return Math.min(uat + rollingDuration, iat + absoluteDuration)
  }

  private async decrypt(jwe: string): Promise<CompactDecryptResult> {
    return await compactDecrypt(jwe, this.key)
  }

  private async encrypt(payload: string, headers?: { iat: number; uat: number; exp: number }): Promise<string> {
    return await new CompactEncrypt(new TextEncoder().encode(payload))
      .setProtectedHeader({
        alg,
        enc,
        ...headers,
      })
      .encrypt(this.key)
  }
}
