import { GetHook, SetHook, partytownSnippet } from '@builder.io/partytown/integration'
import { PartytownProps } from '@builder.io/partytown/react'
import { angelWebTheatricalProdSegmentSnippet, resolveUrl } from '@/services/Partytown'
import { reportErrorToBugsnag } from '@/utils/bugsnag'
import { serializeJS } from '@/utils/dom'

const logger: {
  log: typeof console.log
  debug: typeof console.debug
  error: (error?: Error | null | unknown, message?: string) => void
} = {
  debug: (...args) => {
    if (!process.env.DEBUG) return
    // eslint-disable-next-line no-console
    console.debug(...args)
  },
  log: (...args) => {
    // eslint-disable-next-line no-console
    console.log(...args)
  },
  error: (error, message) => {
    reportErrorToBugsnag({
      ...(typeof error === 'object' ? error : {}),
      name: `AngelPartytownError ${(error instanceof Error && error?.name) || 'Unknown Error'}}`,
      message: [error instanceof Error && error?.message, message].filter(Boolean).join(' '),
      stack: (error instanceof Error && error?.stack) || '',
    })
  },
}

// eslint-disable-next-line @typescript-eslint/ban-types
type AnalyticsEventParams<Payload extends object = {}> = {
  payload: Payload
}

function dynamicallyAppendingScripts(innerHTML: string, { id, async = true }: { id?: string; async?: boolean } = {}) {
  const script = document.createElement('script')
  if (id) {
    script.id = id
  }
  script.type = 'text/partytown'
  script.async = async
  script.innerHTML = innerHTML
  document.head.appendChild(script)

  window.dispatchEvent(new CustomEvent('ptupdate'))
}

function checkIfPartytownScriptInitialized() {
  const partytownScriptInitialized =
    !![...document.getElementsByTagName('script')].find(
      (a) =>
        (a.type.includes('text/javascript') || !a.type) &&
        a.src &&
        a.src.match(/\/~partytown(\/debug)?\/partytown-atomics.js/),
    ) || !![...document.getElementsByTagName('iframe')].find((a) => a.src.includes('/~partytown/'))
  logger.debug('checkIfPartytownScriptInitialized', partytownScriptInitialized)
  return partytownScriptInitialized
}

function checkIfPartytownScriptLoaded() {
  const partytownScriptLoaded =
    checkIfPartytownScriptInitialized() &&
    !![...document.getElementsByTagName('script')].find((a) => a.type.includes('text/partytown-x'))
  logger.debug('checkIfPartytownScriptLoaded', partytownScriptLoaded)
  return partytownScriptLoaded
}

function checkIfSegmentScriptInitialized() {
  const segmentScriptInitialized = !![...document.getElementsByTagName('script')].find(
    (a) => a.type.includes('text/partytown') && a.id === 'angel-web-theatrical-prod-segment',
  )
  logger.debug('checkIfSegmentScriptInitialized', segmentScriptInitialized)
  return segmentScriptInitialized
}

function checkIfSegmentScriptLoaded() {
  const segmentScriptLoaded =
    checkIfSegmentScriptInitialized() &&
    !![...document.getElementsByTagName('script')].find(
      (a) => a.type.includes('text/partytown-x') && a.id === 'angel-partytown-plugin-partytown-script',
    )
  logger.debug('checkIfSegmentScriptLoaded', segmentScriptLoaded)
  return segmentScriptLoaded
}

function checkIfCoreScriptsLoaded() {
  const coreScriptsLoaded =
    typeof window === 'undefined' ? false : checkIfCoreSegmentScriptLoaded() && checkIfCoreFacebookPixelScriptLoaded()
  logger.debug('checkIfCoreScriptsLoaded', coreScriptsLoaded)
  return coreScriptsLoaded
}

function checkIfCoreSegmentScriptLoaded() {
  const coreSegmentScriptLoaded =
    typeof window === 'undefined'
      ? false
      : // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        !!window?.analytics?.initialized
  logger.debug('checkIfCoreSegmentScriptLoaded', coreSegmentScriptLoaded)
  return coreSegmentScriptLoaded
}

function checkIfCoreFacebookPixelScriptLoaded() {
  const coreFacebookPixelScriptLoaded =
    typeof window === 'undefined'
      ? false
      : // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        (!!window.fbq && !!window._fbq) ||
        !![...document.getElementsByTagName('script')].find(
          (a) =>
            a.type.includes('text/javascript') &&
            a.src &&
            a.src.match(/http:\/\/connect.facebook.net\/(([-_0-9a-zA-Z])+)\/fbevents.js/),
        )
  logger.debug('checkIfCoreFacebookPixelScriptLoaded', coreFacebookPixelScriptLoaded)
  return coreFacebookPixelScriptLoaded
}

function runQueue(queue: (() => void)[]) {
  while (queue.length > 0) {
    const holdingQueueFn = queue.shift()
    if (holdingQueueFn) {
      try {
        holdingQueueFn()
      } catch (error) {
        logger.error(error, 'AngelPartytown holdingQueue error')
      }
    }
  }
}

// eslint-disable-next-line sonarjs/cognitive-complexity
export function angelPartytownPlugin({
  writeKey,
  enabledDefault,
  enableRegex,
}: {
  writeKey?: string
  enabledDefault?: boolean
  enableRegex?: RegExp
}) {
  if (!writeKey) {
    logger.debug('angelPartytownPlugin missing writeKey NEXT_PUBLIC_THEATRICAL_SEGMENT_KEY')
    return { name: 'angel-partytown' }
  }
  let pluginEnabled = !!enabledDefault || false
  const mainHoldingQueue: (() => void)[] = []
  const eventHoldingQueue: (() => void)[] = []

  function checkIfLoaded() {
    if (typeof window === 'undefined') return false
    if (typeof document === 'undefined') return false
    if (!pluginEnabled && enableRegex && enableRegex.test(window.location.href)) {
      pluginEnabled = true
    }
    if (!pluginEnabled) return false
    if (!checkIfCoreScriptsLoaded()) return false
    logger.debug('mainHoldingQueue', mainHoldingQueue.length)
    runQueue(mainHoldingQueue)
    if (!checkIfPartytownScriptLoaded()) return false
    if (!checkIfSegmentScriptLoaded()) return false

    logger.debug('AngelPartytown plugin loaded')

    logger.debug('eventHoldingQueue', eventHoldingQueue.length)
    runQueue(eventHoldingQueue)

    return true
  }

  if (typeof window !== 'undefined') {
    const timer = setInterval(() => {
      if (checkIfLoaded()) {
        clearInterval(timer)
      } else {
        logger.debug('AngelPartytown plugin waiting for load')
      }
    }, 1000)
  }

  function checkIfLoadedAndRunORQueue(
    fn: () => void,
    { push = true, unshift = false, checkIfLoadedFn = checkIfLoaded, queue = eventHoldingQueue } = {},
  ) {
    if (checkIfLoadedFn()) {
      fn()
    } else {
      if (push && !unshift) {
        queue.push(fn)
      }
      if (!push && unshift) {
        queue.unshift(fn)
      }
    }
  }

  return {
    name: 'angel-partytown',
    initialize: ({ config }: { config: unknown }) => {
      if (typeof window === 'undefined') return
      // load provider script to page
      logger.debug('AngelPartytown plugin initialize', config)
      const loaded = checkIfLoaded()
      logger.debug('loaded', loaded)
      if (loaded) return

      checkIfLoadedAndRunORQueue(
        () => {
          setTimeout(() => {
            if (!checkIfSegmentScriptInitialized()) {
              logger.debug('segment snippet loading')
              dynamicallyAppendingScripts(angelWebTheatricalProdSegmentSnippet(writeKey, {}), {
                id: 'angel-web-theatrical-prod-segment',
                async: false,
              })
            }

            if (!checkIfPartytownScriptInitialized()) {
              logger.debug('Partytown snippet loading')
              const script = document.createElement('script')
              script.type = 'text/javascript'
              const snippetText = partytownSnippet({
                debug: true,
                logCalls: false,
                logGetters: false,
                logSetters: false,
                logImageRequests: false,
                logMainAccess: false,
                logScriptExecution: false,
                logSendBeaconRequests: false,
                logStackTraces: false,
                forward: [],
                loadScriptsOnMainThread:
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  [/run-on-main-thread/],
                resolveUrl: resolveUrl as unknown as PartytownProps['resolveUrl'],
                get: /* js */ `function partytownGet(opts) {
                  // console.debug("partytownGet", opts, arguments);
                  if (opts.constructor === "Window" && opts.name === "analyticsWriteKey") {
                    opts.instance.partytownSegmentWindow = opts.instance.partytownSegmentWindow || {};
                    return opts.instance.partytownSegmentWindow[opts.name];
                  }
                  return opts.continue;
                }` as unknown as GetHook,
                set: /* js */ `function partytownSet(opts) {
                  // console.debug("partytownSet", opts, arguments);
                  if (opts.constructor === "Window" && opts.name === "analyticsWriteKey") {
                    return opts.instance.partytownSegmentWindow['name'][opts.name];
                  }
                  return opts.continue;
                }` as unknown as SetHook,
              })
              script.innerHTML = snippetText
              document.head.appendChild(script)

              dynamicallyAppendingScripts('', {
                id: 'angel-partytown-plugin-partytown-script',
              })
            }

            logger.debug('AngelPartytown plugin initialized')
          }, 1000)
        },
        {
          unshift: true,
          push: false,
          checkIfLoadedFn: () => pluginEnabled && !checkIfCoreScriptsLoaded(),
          queue: mainHoldingQueue,
        },
      )
    },
    page: ({ payload }: AnalyticsEventParams<{ properties: { url?: string } }>) => {
      checkIfLoadedAndRunORQueue(function handlePage() {
        try {
          logger.debug('AngelPartytown plugin page', payload)
          dynamicallyAppendingScripts(
            serializeJS`analytics.page(${payload.properties.url || '/'}, ${payload.properties || {}});`,
          )
        } catch (error) {
          logger.error(error, 'AngelPartytown page error')
        }
      })
    },
    track: ({ payload: { event, ...payload } }: AnalyticsEventParams<{ event: string; properties: object }>) => {
      checkIfLoadedAndRunORQueue(function handleTrack() {
        try {
          logger.debug('AngelPartytown plugin track', event, payload)
          dynamicallyAppendingScripts(serializeJS/* js */ `analytics.track(${event}, ${payload.properties});`)
        } catch (error) {
          logger.error(error, 'AngelPartytown track error')
        }
      })
    },
    identify: ({ payload }: AnalyticsEventParams<{ userId?: string; traits?: object }>) => {
      checkIfLoadedAndRunORQueue(function handleIdentify() {
        try {
          logger.debug('AngelPartytown plugin identify', payload)
          dynamicallyAppendingScripts(
            serializeJS/* js */ `
              const userId = ${payload?.userId || null};

              // ? These are the invalid emails that ellis island will send us from social logins
              const invalidEmailRegexs = [RegExp('@facebook\\.com$'), RegExp('@invalid\\.angel\\.com$')];
              const user = analytics && analytics?.user && typeof analytics.user === 'function' && analytics.user() || undefined;
              const userTraits = user && user?.traits || undefined;
              const previousTraits = (userTraits || {});
              const previousUserEmail = previousTraits && previousTraits.userEmail;
              const previousOrderEmail = previousTraits && previousTraits.orderEmail;
              const validPreviousOrderEmail = previousOrderEmail && !invalidEmailRegexs.find((regex) => regex.test(previousOrderEmail));
              const payloadTraits = ${payload?.traits || {}};
              const payloadUserEmail = payloadTraits.email;
              const payloadOrderEmail = payloadTraits.orderEmail;
              const validPayloadOrderEmail = payloadOrderEmail && !invalidEmailRegexs.find((regex) => regex.test(payloadOrderEmail));
              
              // ? This here to send the order email when it is available
              const orderEmail = validPayloadOrderEmail ? payloadOrderEmail
                : validPreviousOrderEmail && payloadUserEmail === previousUserEmail ? previousOrderEmail
                : payloadUserEmail;
                
              const traits = Object.assign(
                payloadTraits,
                {
                  userEmail: payloadUserEmail,
                  orderEmail,
                  email: orderEmail,
                }
              );

              analytics.identify(userId, traits);
            `,
          )
        } catch (error) {
          logger.error(error, 'AngelPartytown identify error')
        }
      })
    },
    loaded: () => {
      logger.debug('AngelPartytown plugin loaded')
      return true
    },
  }
}
