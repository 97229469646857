import { useCallback, useMemo } from 'react'
import { useRouter } from 'next/router'
import { TheatricalReleaseRegion } from '@/types/codegen-federation'
import { useLocale } from '@/utils/LocaleUtil'

interface UseCountrySelectorListProps {
  regions: TheatricalReleaseRegion[]
  inputValue: string
}

export interface CountryType {
  country: string
  countryCode: string
  region: string
  isTicketPageEnabled: boolean
  isPifPageEnabled: boolean
}

const useCountrySelectorList = ({ regions, inputValue }: UseCountrySelectorListProps) => {
  const { query } = useRouter()
  const { locale } = useLocale()

  const filteredRegions = useMemo(() => {
    return regions?.filter((region) => region?.ticketsStatus !== 'ENDED')
  }, [regions])
  const getCountryName = useCallback((countryCode: string | null, locale: string) => {
    try {
      if (!countryCode) return null
      const regionNames = new Intl.DisplayNames([locale ?? 'en'], { type: 'region' })
      return regionNames.of(countryCode)
    } catch (err) {
      return null
    }
  }, [])

  const orderedCountries = useMemo(() => {
    const countries = filteredRegions?.map((region) => {
      return {
        country: getCountryName(region?.countryCode, locale as string),
        countryCode: region?.countryCode?.toLowerCase(),
      }
    })

    const sortedCountries = countries?.sort((a, b) => {
      const countryA = a?.country || ''
      const countryB = b?.country || ''
      return countryA.localeCompare(countryB)
    })

    const usRegion = sortedCountries.find((r) => r?.countryCode === 'us')
    const filteredRegion = sortedCountries.filter((r) => r?.countryCode !== 'us')
    if (usRegion) return [usRegion, ...filteredRegion] as CountryType[]
    return filteredRegion as CountryType[]
  }, [filteredRegions, getCountryName, locale])

  const countries = useMemo(() => {
    if (!inputValue) return orderedCountries
    return orderedCountries.filter((c) => c?.country?.toLowerCase().startsWith(inputValue.toLowerCase()))
  }, [orderedCountries, inputValue])

  const isRegionSelected = useCallback(
    (country: string) => {
      const urlRegion = query?.region as string
      if (urlRegion === country) return true
      return !urlRegion && country === 'US'
    },
    [query?.region],
  )

  return { countries, isRegionSelected, getCountryName }
}

export default useCountrySelectorList
