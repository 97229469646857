import classNames from 'classnames'

export const BUTTON_VARIANTS = [
  'black',
  'gray',
  'white',
  'oxide',
  'oxide-bright',
  'oxide-core',
  'oxide-300',
  'oxide-primary',
  'copper',
  'copper-600',
  'lightgray',
  'lightcoppergray',
  'offblack',
  'lightened-50',
  'gray-400',
  'gray-600',
  'gray-700',
  'gray-900',
  'plain',
  'hos-orange',
  'core-gray-800',
  'core-gray-900',
  'gatg-pink',
  'whatsapp',
] as const

export type ButtonVariant = typeof BUTTON_VARIANTS[number]

type VariantClasses = {
  [key: string]: string
}

const normalState: VariantClasses = {
  black: 'bg-core-gray-950 border-gray-950 text-white',
  'black-disabled': 'border-gray-500 bg-gray-500 text-gray-800',
  'black-outline': 'border-gray-600 bg-transparent text-gray-950',
  'black-outline-disabled': 'bg-gray-200 text-gray-400',

  gray: 'bg-gray-300 border-gray-300 text-gray-950',
  'gray-disabled': 'border-gray-500 bg-gray-500 text-gray-800',
  'gray-outline': 'border-gray-300 bg-transparent text-gray-950',
  'gray-outline-disabled': 'bg-gray-200 text-gray-400',

  white: 'bg-white border-white text-gray-950',
  'white-disabled': 'border-gray-500 bg-gray-500 text-gray-800',
  'white-outline': 'border-white text-white hover:bg-shaded-50 active:bg-shaded-200',
  'white-outline-disabled': 'bg-gray-200 text-gray-400',

  oxide: 'border-oxide bg-oxide text-white',
  'oxide-disabled': 'border-oxide-extra-light bg-oxide-extra-light text-gray-400',
  'oxide-outline': 'border-oxide bg-transparent text-oxide',
  'oxide-outline-disabled': 'bg-gray-200 text-gray-400',

  'oxide-bright': 'border-oxide-bright bg-oxide-bright text-gray-950',
  'oxide-bright-disabled': 'border-oxide-bright/80 bg-oxide-bright/80 text-gray-400',
  'oxide-bright-outline': 'border-oxide-bright bg-transparent text-oxide-bright',
  'oxide-bright-outline-disabled': 'bg-gray-200 text-gray-400',

  'oxide-core': 'border-core-oxide bg-core-oxide text-gray-950',
  'oxide-core-disabled': 'border-core-oxide/80 bg-core-oxide/80 text-gray-400',
  'oxide-core-outline': 'border-core-oxide bg-transparent text-core-oxide',
  'oxide-core-outline-disabled': 'bg-gray-200 text-gray-400',

  'oxide-300': 'border-oxide-300 bg-oxide-300 text-gray-950',
  'oxide-300-disabled': 'border-oxide-300/20  bg-oxide-300/20 text-core-gray-400',
  'oxide-300-outline': 'border-oxide-300 bg-transparent text-core-oxide',
  'oxide-300-outline-disabled': 'bg-gray-200 text-gray-400',

  'oxide-primary': 'border-oxide-primary bg-oxide-primary text-white',
  'oxide-primary-disabled': 'border-oxide-primary/80 bg-oxide-primary/80 text-gray-400',
  'oxide-primary-outline': 'border-oxide-primary bg-transparent text-oxide-primary',
  'oxide-primary-outline-disabled': 'bg-gray-200 text-gray-400',

  copper: 'border-copper bg-copper text-white',
  'copper-disabled': 'border-copper-extra-light bg-copper-extra-light text-gray-400',
  'copper-outline': 'border-copper bg-transparent text-copper',
  'copper-outline-disabled': 'bg-gray-200 text-gray-400',

  'copper-600': 'border-copper-600 bg-copper-600 text-gray-100',
  'copper-600-disabled': 'border-copper-200 bg-copper-200 text-gray-400',
  'copper-600-outline': 'border-copper-600 bg-transparent text-copper-600',
  'copper-600-outline-disabled': 'bg-gray-200 text-gray-400',

  lightgray: 'bg-gray-200 border-gray-200 text-gray-950',
  'lightgray-disabled': 'bg-gray-200 border-gray-200 text-gray-500',
  'lightgray-outline': 'border-gray-200 bg-transparent text-gray-950',
  'lightgray-outline-disabled': 'bg-gray-200 text-gray-400',

  lightcoppergray: 'bg-gray-900 text-copper-lighter border-0',
  'lightcoppergray-disabled': 'border-copper-lighter bg-gray-500 text-gray-800',
  'lightcoppergray-outline': 'border-[1px] text-copper-lighter',
  'lightcoppergray-outline-disabled': 'bg-gray-200 text-gray-400',

  offblack: 'bg-[#3E3E3E] border-[#3E3E3E] text-white',
  'offblack-disabled': 'border-gray-500 bg-gray-500 text-gray-800',
  'offblack-outline': 'border-[#3E3E3E] bg-transparent text-[#3E3E3E]',
  'offblack-outline-disabled': 'bg-gray-200 text-gray-400',

  'lightened-50': 'bg-lightened-50 border-lightened-50 text-white',
  'lightened-50-disabled': 'border-gray-500 bg-gray-500 text-gray-800',
  'lightened-50-outline': 'border-lightened-50 text-white',
  'lightened-50-outline-disabled': 'bg-gray-200 text-gray-400',

  'gray-400': 'bg-gray-400 text-gray-950',
  'gray-400-disabled': 'bg-gray-200 text-gray-400',
  'gray-400-outline': 'border border-[1px] border-gray-400 text-white bg-transparent',
  'gray-400-outline-disabled': 'bg-gray-200 text-gray-400',

  'gray-600': 'bg-gray-600 text-gray-950',
  'gray-600-disabled': 'bg-gray-200 text-gray-400',
  'gray-600-outline': 'border border-[1px] border-gray-600 text-white bg-transparent',
  'gray-600-outline-disabled': 'border-gray-200 text-gray-400',

  'gray-700': 'bg-gray-700 text-gray-700',
  'gray-700-disabled': 'bg-gray-200 text-gray-700',
  'gray-700-outline': 'border border-[1px] border-gray-700 text-gray-700 bg-transparent',
  'gray-700-outline-disabled': 'border-gray-200 text-gray-400',

  'gray-900': 'bg-gray-900 border-gray-900 text-white',
  'gray-900-outline': 'border-gray-900 bg-transparent text-gray-800',
  'gray-900-disabled': 'border-gray-500 bg-gray-500 text-gray-800',
  'gray-900-outline-disabled': 'bg-gray-200 text-gray-400',

  'hos-orange': 'border-hos-orange bg-hos-orange text-white',
  'hos-orange-disabled': 'border-hos-orange-extra-light bg-hos-orange-extra-light text-gray-400',
  'hos-orange-outline': 'border-hos-orange bg-transparent text-hos-orange',
  'hos-orange-outline-disabled': 'border-hos-orange-extra-light bg-transparent text-gray-400',

  'core-gray-800': 'bg-core-gray-800 border-core-gray-800 text-white',
  'core-gray-800-disabled': 'border-core-gray-500 bg-core-gray-500 text-core-gray-800',
  'core-gray-800-outline': 'border-core-gray-800 bg-transparent text-core-gray-950',
  'core-gray-800-outline-disabled': 'bg-core-gray-200 text-core-gray-400',

  'core-gray-900': 'bg-core-gray-900 border-core-gray-900 text-white',
  'core-gray-900-disabled': 'border-core-gray-500 bg-core-gray-500 text-core-gray-900',
  'core-gray-900-outline': 'border-core-gray-900 bg-transparent text-core-gray-950',
  'core-gray-900-outline-disabled': 'bg-core-gray-200 text-core-gray-400',

  'gatg-pink': 'bg-gatg-pink border-gatg-pink text-white',
  'gatg-pink-disabled': 'bg-gatg-pink border-gatg-pink text-white',
  'gatg-pink-outline': 'border-gatg-pink bg-transparent text-white',
  'gatg-pink-outline-disabled': 'bg-gatg-pink text-white',

  whatsapp: 'bg-[#30D170] border-[#30D170] text-white',
  'whatsapp-disabled': 'bg-[#C8F1D3] border-[#C8F1D3] text-white',
}

const hoverState: VariantClasses = {
  black: 'hover:bg-gray-900 hover:border-gray-900',
  'black-outline': 'hover:bg-gray-900 hover:text-white hover:border-gray-900',

  gray: 'hover:bg-gray-400 hover:border-gray-400',
  'gray-outline': 'hover:bg-gray-400 hover:text-gray-950 hover:border-gray-400',

  white: 'hover:bg-gray-200 hover:border-gray-200',
  'white-outline': 'hover:bg-white hover:text-gray-600 hover:border-gray-50',

  oxide: 'hover:bg-oxide-dark hover:border-oxide-dark',
  'oxide-outline': 'hover:bg-oxide-dark hover:text-white hover:border-oxide-dark',

  'oxide-bright': 'hover:bg-oxide-bright/50 hover:border-oxide-bright/50',
  'oxide-bright-outline': 'hover:bg-oxide-bright/50 hover:text-white hover:border-oxide-bright/50',

  'oxide-core': 'hover:bg-core-oxide/50 hover:border-core-oxide/50',
  'oxide-core-outline': 'hover:bg-core-oxide/50 hover:text-white hover:border-core-oxide/50',

  'oxide-300': 'hover:bg-oxide-300/50 hover:border-oxide-300/50',
  'oxide-300-outline': 'hover:bg-oxide-300/50 hover:text-white hover:border-oxide-300/50',

  copper: 'hover:bg-copper-dark hover:border-copper-dark',
  'copper-outline': 'hover:bg-copper-dark hover:text-white hover:border-copper-dark',

  'copper-600': 'hover:bg-copper-700 hover:border-copper-700',
  'copper-600-outline': 'hover:bg-copper-700 hover:text-gray-100 hover:border-copper-700',

  lightgray: 'hover:bg-gray-400 hover:border-gray-400',
  'lightgray-outline': 'hover:border-gray-400',

  lightcoppergray: 'hover:bg-gray-800',
  'lightcoppergray-outline': 'hover:text-copper-light hover:border-copper-light',

  offblack: 'hover:opacity-80',
  'offblack-outline': 'hover:opacity-80',

  'lightened-50': 'hover:bg-lightened-100 hover:border-lightened-100',
  'lightened-50-outline': 'hover:bg-lightened-100 hover:text-white hover:border-lightened-100',

  'gray-400': 'hover:bg-gray-500 hover:border-gray-700',
  'gray-400-outline': 'hover:border-gray-500 hover:text-gray-100',

  'gray-600': 'hover:bg-gray-500 hover:border-gray-600',
  'gray-600-outline': 'hover:border-gray-500 hover:text-gray-100',

  'gray-900': 'hover:bg-gray-800 hover:border-gray-800',
  'gray-900-outline': 'hover:bg-gray-100 hover:border-gray-700',

  'hos-orange': 'hover:bg-hos-orange-dark hover:border-hos-orange-dark',
  'hos-orange-outline': 'hover:bg-hos-orange-dark hover:text-white hover:border-hos-orange-dark',

  'core-gray-800': 'hover:bg-core-gray-900 hover:border-core-gray-900',
  'core-gray-800-outline': 'hover:bg-core-gray-900 hover:text-white hover:border-core-gray-900',

  'core-gray-900': 'hover:bg-core-gray-950 hover:border-core-gray-950',
  'core-gray-900-outline': 'hover:bg-core-gray-950 hover:text-white hover:border-core-gray-950',

  'gatg-pink': 'hover:bg-[#F5F5F4] hover:border-[#F5F5F4] hover:text-black',
  'gatg-pink-outline': 'hover:border-[#F5F5F4] hover:text-black',

  whatsapp: 'hover:bg-[#35E67B] hover:border-[#35E67B]',
  'whatsapp-disabled': 'hover:bg-[#C8F1D3] hover:border-[#C8F1D3] text-white',
}

const activeState: VariantClasses = {
  black: 'active:bg-gray-800 active:border-gray-800',
  'black-outline': 'active:bg-gray-800 active:border-gray-800 active:text-white',

  gray: 'active:bg-gray-500 active:border-gray-500',
  'gray-outline': 'active:bg-gray-500 active:border-gray-500 active:text-gray-950',

  white: 'active:bg-gray-300 active:border-gray-300',
  'white-outline': 'active:bg-gray-200 active:border-gray-200 active:text-gray-950',

  oxide: 'active:bg-oxide-extra-dark active:border-oxide-extra-dark',
  'oxide-outline': 'active:bg-oxide-extra-dark active:border-oxide-extra-dark active:text-white',

  'oxide-bright': 'active:bg-oxide-bright/35 active:border-oxide-bright/35',
  'oxide-bright-outline': 'active:bg-oxide-bright/35 active:border-oxide-bright/35 active:text-white',

  'oxide-core': 'active:bg-core-oxide/35 active:border-core-oxide/35',
  'oxide-core-outline': 'active:bg-core-oxide/35 active:border-core-oxide/35 active:text-white',

  'oxide-300': 'active:bg-oxide-300 active:border-core-oxide/35',
  'oxide-300-outline': 'active:bg-oxide-300 active:border-core-oxide/35 active:text-white',

  copper: 'active:bg-copper-extra-dark active:border-copper-extra-dark',
  'copper-outline': 'active:bg-copper-extra-dark active:border-copper-extra-dark active:text-white',

  'copper-600': 'active:bg-copper-800 active:border-copper-800',
  'copper-600-outline': 'active:bg-copper-800 active:border-copper-800 active:text-gray-100',

  lightgray: 'active:bg-gray-200 active:border-gray-200',
  'lightgray-outline': 'active:bg-gray-200 active:border-gray-200',

  lightcoppergray: 'active:bg-gray-700',
  'lightcoppergray-outline': 'active:text-copper active:border-copper',

  offblack: 'active:opacity-70',
  'offblack-outline': 'active:opacity-70',

  'lightened-50': 'active:bg-lightened-200 active:border-lightened-200',
  'lightened-50-outline': 'active:bg-lightened-200 active:border-lightened-200 active:text-white',

  'gray-400': 'active:bg-gray-600 active:border-gray-800',
  'gray-400-outline': 'active:border-gray-600 active:text-gray-200',

  'gray-600': 'active:bg-gray-400 active:border-gray-500',
  'gray-600-outline': 'active:border-gray-400 active:text-gray-200',

  'gray-900': 'active:bg-gray-700 active:border-gray-700',
  'gray-900-outline': 'active:bg-gray-700 active:border-gray-700 active:text-white',

  'hos-orange': 'active:bg-hos-orange-extra-dark active:border-hos-orange-extra-dark',
  'hos-orange-outline': 'active:bg-hos-orange-extra-dark active:border-hos-orange-extra-dark active:text-white',

  'core-gray-800': 'active:bg-core-gray-950 active:border-core-gray-950',
  'core-gray-800-outline': 'active:bg-core-gray-950 active:border-core-gray-950 active:text-white',

  'core-gray-900': 'active:bg-core-gray-950 active:border-core-gray-950',
  'core-gray-900-outline': 'active:bg-core-gray-950 active:border-core-gray-950 active:text-white',

  'gatg-pink': 'active:bg-gray-700 active:border-gray-700 active:text-white',
  'gatg-pink-outline': 'active:bg-gray-700 active:border-gray-700 active:text-white',

  whatsapp: 'active:bg-whatsApp active:bg-whatsApp',
  'whatsapp-disabled': 'hover:bg-[#C8F1D3] hover:border-[#C8F1D3] text-white',
}

interface VariantClassRequest {
  variant: ButtonVariant
  outline?: boolean
  disabled?: boolean
}

export function getVariantClasses({ variant, outline, disabled }: VariantClassRequest) {
  let finalVariant = variant

  if (outline) {
    finalVariant += '-outline'
  }

  if (disabled) {
    finalVariant += '-disabled'
  }

  return classNames(normalState[finalVariant], activeState[finalVariant], hoverState[finalVariant], {
    'cursor-not-allowed': disabled,
  })
}
