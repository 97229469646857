export function getCookieConsentProvider(): 'osano' | 'cookie_yes' | 'pie_eye' {
  return 'osano'
}

export function isCookieConsentDialogVisible(): boolean {
  const provider = getCookieConsentProvider()
  if (provider === 'osano') return isOsanoConsentDialogVisible()
  if (provider === 'cookie_yes') return isCookieYesConsentDialogVisible()
  if (provider === 'pie_eye') return isPieEyeConsentDialogVisible()
  return false
}

function isOsanoConsentDialogVisible(): boolean {
  return (
    document &&
    document.getElementsByClassName('osano-cm-dialog')?.[0] &&
    !document.getElementsByClassName('osano-cm-dialog')?.[0]?.classList.contains('osano-cm-dialog--hidden')
  )
}

function isCookieYesConsentDialogVisible(): boolean {
  return (
    document &&
    document.getElementsByClassName('cky-consent-container')?.[0] &&
    !document.getElementsByClassName('cky-consent-container')?.[0]?.classList.contains('cky-hide')
  )
}

// TODO verify that this function is working. It may or may not be working.
function isPieEyeConsentDialogVisible(): boolean {
  const container = document && document.getElementsByClassName('pieeye-consent-container')?.[0]
  const iFrame = container?.children[0]
  const displayValue = window.getComputedStyle(iFrame).display
  const isVisible = displayValue !== 'none'
  return isVisible
}
