import React, { FC, Suspense, useMemo, useCallback, useRef, useEffect, MouseEventHandler } from 'react'
import { Listbox } from '@headlessui/react'
import classNames from 'classnames'
import { When } from 'react-if'
import { BellIcon } from '@/atoms/Icons/BellIcon'
import { BellIconNew } from '@/atoms/Icons/BellIconNew'
import { useLocale } from '@/utils/LocaleUtil'
import { useTranslate } from '@/utils/translate/translate-client'
import { NotificationsModal } from './NotificationsModal'

interface NotificationsMenuProps {
  className?: string
  isOpen: boolean
  isDarkMode?: boolean
  fetchOnlyNew?: boolean
  onClose: () => void
  notificationsCount?: number | null
  onOpen: () => void
}

export const NotificationsMenu: FC<NotificationsMenuProps> = ({
  className,
  isOpen,
  fetchOnlyNew,
  isDarkMode,
  onClose,
  notificationsCount,
  onOpen,
}) => {
  const { locale } = useLocale()
  const ref = useRef<HTMLDivElement | null>(null)
  const { t } = useTranslate('common')

  const backgroundClasses = classNames(
    'absolute top-10 -right-12 sm:-right-0 min-w-[96vw] sm:min-w-[380px] max-h-[85vh] py-5 mt-4 rounded-lg',
    isDarkMode ? 'bg-core-gray-950' : 'bg-white',
  )
  const notificationIconColor = useMemo(() => {
    return isDarkMode ? 'white' : 'gray-950'
  }, [isDarkMode])

  const handleOnChange = useCallback(() => {
    onClose()
  }, [onClose])

  const hasNewNotifications = useMemo(() => {
    return Boolean(notificationsCount && notificationsCount > 0)
  }, [notificationsCount])

  const handleBellClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation()
      event.preventDefault()
      if (onOpen) {
        if (isOpen) {
          onClose()
        } else {
          onOpen()
        }
      }
    },
    [onOpen, onClose, isOpen],
  )

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        onClose()
      }
    }

    document.addEventListener('click', handleClickOutside)
    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [onClose])

  const handleClickAwayFromMenu: MouseEventHandler = (e) => {
    e.preventDefault()
    onClose()
  }

  return (
    <Listbox
      className={classNames(
        'relative mr-2 drop-shadow-dark-4 flex items-center',
        isDarkMode ? 'drop-shadow-dark-5' : 'drop-shadow-light-1',
        className,
      )}
      as="div"
      onChange={handleOnChange}
      value={locale}
    >
      <When condition={isOpen}>
        <div
          className="absolute left-0 top-0 z-[9998] h-screen max-h-full w-screen max-w-full bg-transparent"
          onClick={handleClickAwayFromMenu}
        />
      </When>
      <Listbox.Button
        className="!outline-none ring-0 !ring-transparent !ring-offset-0 !ring-offset-transparent"
        onClick={handleBellClick}
        aria-label={
          hasNewNotifications
            ? t('unreadNotifications', 'Unread Notifications')
            : t('notificationBell', 'Notification Bell')
        }
        aria-expanded={isOpen}
        aria-controls="notification-menu"
        role="button"
      >
        {hasNewNotifications ? (
          <BellIconNew color={notificationIconColor} />
        ) : (
          <BellIcon color={notificationIconColor} />
        )}
      </Listbox.Button>
      {isOpen && (
        <Suspense fallback={<div id="notifications-menu" />}>
          <div ref={ref} id="notifications-menu">
            <NotificationsModal
              notificationsCount={notificationsCount}
              isOpen={isOpen}
              onClose={onClose}
              backgroundClasses={backgroundClasses}
              fetchOnlyNew={fetchOnlyNew}
            />
          </div>
        </Suspense>
      )}
    </Listbox>
  )
}
